export default {
    platforms: [
        {key: 'win', name: 'Windows', typeBuilds: ['gui', 'console']},
        {key: 'linux', name: 'Linux', typeBuilds: ['console']},
        {key: 'mac', name: 'MacOS', typeBuilds: ['gui']}
    ],
    typeBuilds: [
        {key: 'gui', name: 'Gráfico', platforms: ['win', 'mac']},
        {key: 'console', name: 'Console', platforms: ['win', 'linux']},
    ],
};
