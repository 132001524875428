export default {
    metaTitle: 'Capacidades de Acceso Remoto: Control, Monitoreo, Estadísticas, Inventario, Gestión de Servidores y Computadoras',
    metaDesc: 'Un servicio profesional para administradores de sistemas y usuarios regulares para el control remoto de computadoras. Una alternativa superior a los programas de acceso remoto extranjeros. Desarrollo activo e implementación de funciones solicitadas por los usuarios. Gestión de procesos, eliminación de programas, ejecución remota de comandos, inventario de estaciones, visualización de dispositivos, gestión de energía.',
    metaKeys: 'Programa gratuito para la gestión de procesos, eliminación de programas, ejecución remota de comandos, inventario de estaciones, visualización de dispositivos, gestión de energía',
    title: 'Capacidades del Sistema',
    startUse: 'Comenzar a Usar',
    remoteControl: 'Control Remoto',
    remoteControlT: 'Control remoto estándar de escritorio. Similar a TeamViewer, AnyDesk, Ammyy Admin.<br/><br/>Puedes conectarte desde una computadora o teléfono móvil a través de un navegador.',
    invent: 'Inventario de Estaciones',
    inventT: '¿Necesitas llevar un inventario y no quieres conectarte a cada máquina?<br/><br/>Solo verifica las características de todos los dispositivos en el panel de control.',
    process: 'Procesos',
    processT: 'Puedes ver y filtrar todos los procesos en ejecución en la estación en cualquier momento y finalizarlos manualmente si es necesario.',
    programs: 'Programas y Dispositivos',
    programsT: 'Verifica todas las aplicaciones instaladas y elimínalas del dispositivo si lo deseas.<br/><br/>¿Quieres saber exactamente qué equipos están instalados? No hay problema.',
    remoteCommand: 'Ejecución Remota de Comandos',
    remoteCommandT: 'Hay situaciones en las que solo necesitas ejecutar un comando para resolver un problema o averiguar algo, y no quieres conectarte. Puedes enviarlo con solo presionar un botón y obtener el resultado de la ejecución.<br/><br/>La gestión de energía funciona de la misma manera. Reiniciar, bloquear o poner la estación en modo de espera en un par de clics.',
    feature: 'Lista de Funciones Futuras',
    idea: 'Enviar una Idea',
    ideaT: '¡El sistema está abierto a tus ideas y comentarios! Escríbelos en nuestro grupo de Telegram <a href="{{tg}}" target="_blank" rel="noreferrer">aquí</a> o envíame un mensaje directo.<br/>Tu participación en cualquier forma ayudará al proyecto a avanzar. ¡Gracias de antemano!',
};
