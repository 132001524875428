export default {
    metaTitle: 'Project Roadmap',
    metaDesc: 'Tasks that have already been implemented in the project and those that are awaiting their turn',
    metaKeys: 'Free program for process management, program removal, remote command execution, station inventory, device viewing, power management',
    title: 'Roadmap',
    titleD: 'List of Implemented and Future Features',

    showDone: 'Show Completed',
    hideDone: 'Hide Completed',
    showMore: 'Show Details',
    inQuery: 'In Queue',
    hide: 'Hide',
    more: 'More',
    needMoney: 'Need Funding',
    "task0": "Station List, Add New",
    "task1": "General Station Information",
    "task2": "Processes. Management and Viewing",
    "task3": "Programs. Management and Viewing",
    "task4": "Devices. Viewing",
    "task5": "Command Execution",
    "task6": "Power Management",
    "task7": "Command History",
    "task8": "Visual Control for Windows",
    "task9": "Build for Mac",
    "task9Desc":`
        <p>
        Since I have been working on Windows for many years and sold my last Mac five years ago, 
        I tried to build it on a virtual machine, it started up fine, but I couldn't build the project 
        because the response to actions takes about 3-5 seconds, and it's the same for both vmware and virtualbox. 
        I tried all possible settings for a couple of days, but the result is the same. However, it's only possible 
        to develop for this system on the system itself, and it can't be done on Windows or Linux.
        </p>
        <p>
        I came to the conclusion that I need to buy a device with at least medium performance on this system. 
        If you can, please support the project, I would be very grateful. Once the necessary amount is collected, 
        I will complete the current task and start working on this one.
        <a href="{{lang}}/donate">Support can be provided here</a>
        </p>`,
    "task10": "User Account",
    "task11": "Translation into Foreign Languages",
    "task12": "Two-Factor Authentication",
    "task13": "Remote Command Line",
    "task13Desc": `<p>Similar to an SSH client, but in a browser.</p>`,
    "task14": "MSI Installer",
    "task15": "Process Monitoring on a Graph",
    "task16": "API Documentation",
    "task17": "Opening the Source Code of the Client",
    "task18": "Disk Space Management",
    "task19": "Log Viewing",
    "task20": "Temporary Access",
    "task21": "Mobile Application",
    "task22": "Port Forwarding",
    "task23": "User-Generated Program Generation",
    "task23Desc": `<p>A program launch file will be created, and when the user opens it on their device, the user who sent the file will be added to the allowed list.</p>`,
    "task24": "Administration Functions in the Client",
    "task24Desc": `<p>The ability to access the control panel of this station or any other without using a browser.</p>`,
    "task25": "Permission Differentiation for Viewing and Control"
};
