export default {
    metaTitle: 'Roadmap do Projeto',
    metaDesc: 'Tarefas que já foram implementadas no projeto e as que estão aguardando sua vez',
    metaKeys: 'Programa gratuito para gerenciamento de processos, remoção de programas, execução remota de comandos, inventário de estações, visualização de dispositivos, gerenciamento de energia',
    title: 'Roadmap',
    titleD: 'Lista de Recursos Implementados e Futuros',

    showDone: 'Mostrar Concluídos',
    hideDone: 'Esconder Concluídos',
    showMore: 'Mostrar Detalhes',
    inQuery: 'Na Fila',
    hide: 'Esconder',
    more: 'Mais',
    needMoney: 'Necessita de Financiamento',
    "task0": "Lista de Estações, Adicionar Novo",
    "task1": "Informações Gerais da Estação",
    "task2": "Processos. Gerenciamento e Visualização",
    "task3": "Programas. Gerenciamento e Visualização",
    "task4": "Dispositivos. Visualização",
    "task5": "Execução de Comandos",
    "task6": "Gerenciamento de Energia",
    "task7": "Histórico de Comandos",
    "task8": "Controle Visual para Windows",
    "task9": "Build para Mac",
    "task9Desc":`
        <p>
        Como tenho trabalhado no Windows há muitos anos e vendi meu último Mac há cinco anos,
        tentei fazer uma compilação em uma máquina virtual, que iniciou sem problemas, mas não consegui
        compilar o projeto porque a resposta às ações leva cerca de 3-5 segundos, e é o mesmo tanto para o
        vmware quanto para o virtualbox. Tentei todas as configurações possíveis por alguns dias, mas o resultado é o mesmo.
        No entanto, só é possível desenvolver para este sistema no próprio sistema e não é possível fazê-lo no Windows ou Linux.
        </p>
        <p>
        Cheguei à conclusão de que preciso comprar um dispositivo com pelo menos desempenho médio neste sistema.
        Se puder, por favor, apoie o projeto, ficarei muito grato. Assim que o valor necessário for arrecadado,
        concluirei a tarefa atual e começarei a trabalhar nisso.
        <a href="{{lang}}/donate">O apoio pode ser feito aqui</a>
        </p>`,
    "task10": "Conta de Usuário",
    "task11": "Tradução para Línguas Estrangeiras",
    "task12": "Autenticação de Dois Fatores",
    "task13": "Linha de Comando Remota",
    "task13Desc": `<p>Similar a um cliente SSH, mas no navegador.</p>`,
    "task14": "Instalador no formato MSI",
    "task15": "Monitoramento de Processos em Gráfico",
    "task16": "Documentação da API",
    "task17": "Abertura do Código Fonte do Cliente",
    "task18": "Gerenciamento de Espaço em Disco",
    "task19": "Visualização de Logs",
    "task20": "Acesso Temporário",
    "task21": "Aplicativo Móvel",
    "task22": "Encaminhamento de Portas",
    "task23": "Geração de Programas pelo Usuário",
    "task23Desc": `<p>Será criado um arquivo de inicialização do programa e quando o usuário abrir esse arquivo em seu dispositivo, o usuário que enviou o arquivo será adicionado à lista permitida.</p>`,
    "task24": "Recursos de Administração no Cliente",
    "task24Desc": `<p>Possibilidade de acessar o painel de controle desta estação ou qualquer outra sem utilizar um navegador.</p>`,
    "task25": "Separação de Permissões para Visualização e Gerenciamento"
};
