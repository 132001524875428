import './styles/main.scss'
import {observer, Provider} from "mobx-react";
import React, {useEffect, useMemo} from "react";
import Variables from "./utils/variables";
import RootStore from './stores/rootStore';
import Helper from "./utils/helper";
import "./styles/overrides/material.scss";
import Routes from "./routes";
import APIService from "./utils/api.service";
import LANG from "./lang/language";

Variables.init();

function App() {

    useMemo(() => {
        App.init();
    }, []);
    let rootStore = RootStore.getInstance();

    useEffect(() => {
        let keyup = (e) => rootStore.onKeyUp(e);
        let keydown = (e) => rootStore.onKeyDown(e);
        //let popstate = (e) => rootStore.onPop(e);
        window.addEventListener('keyup', keyup);
        window.addEventListener('keydown', keydown);
        return function cleanup() {
            window.removeEventListener('keyup', keyup);
            window.removeEventListener('keydown', keydown);
        }
    }, []);


    return <Provider rootStore={rootStore}>
        <Routes/>
    </Provider>
}

App.init = () => {
    let lang = LANG.getInstance();
    let rootStore = RootStore.getInstance();
    APIService.getInstance() // init socket and connect
    Helper.initLocale();
    return {};
}

export default observer(App);
