export default {
    metaTitle: '원격 액세스 프로그램 다운로드. Windows, Linux, MacOS.',
    metaDesc: 'PC 및 서버를 완전히 제어하기 위한 최신 버전을 다운로드하세요.',
    metaKeys: '다운로드 cusco, cusco win, cusco linux, cusco macos',
    title: '플랫폼 선택',
    download: '다운로드',
    version: '버전',
    versions: '버전',
    versionsT: `프로그램에는 두 가지 버전이 있습니다:
 <ul>
 <li>그래픽 인터페이스를 사용한 버전-크기는 크지만 사용하기 편리합니다.</li>
 <li>터미널 인터페이스를 사용한 버전-크기는 작지만 콘솔에서 작동하며 키보드로 제어합니다.
 시스템의 이전 버전을 사용하거나 그래픽 인터페이스가 작동하지 않는 경우 선호되는 버전입니다.
 </li>
 </ul>`,

    install: '설치',
    installTWin: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>프로그램을 다운로드</a>하고 실행하세요.<br/>
 2. <a href="{{panel}}" target='_blank' rel='noreferrer'>컨트롤 패널</a>에 등록하세요.<br/>
 3. 기기에서 프로그램에서 제공하는 ID와 비밀번호를 지정하여 스테이션을 추가하세요.<br/>
 4. 제어를 시작하세요!
 <br/><br/>`,
    installTLinux: `
 1. 다음 명령을 사용하여 프로그램을 다운로드하세요:<br/>
 <div class="{{sCode}}">
 cd ~<br/>
 wget {{installLink}}<br/>
 </div>
 2. 프로그램을 실행하세요:<br/>
 <div class="{{sCode}}">
 sudo chmod +x {{fileName}}<br/>
 sudo ./{{fileName}}<br/>
 </div>

 3. <a href="{{panel}}" target='_blank' rel='noreferrer'>컨트롤 패널</a>에 등록하세요.<br/>
 4. 기기에서 프로그램에서 제공하는 ID와 비밀번호를 지정하여 스테이션을 추가하세요.<br/>
 5. 제어를 시작하세요!

 <br/><br/>
 참고: 설치 중 컴퓨터가 켜질 때 서비스가 생성됩니다.<br/>
 콘솔에서 "cusco" 명령을 사용하여 프로그램을 열 수 있습니다.<br/>
 모든 명령의 목록을 얻으려면 "cusco --help"를 사용하세요.`,
    inProgress: `진행 중`,
    installTMac: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>프로그램을 다운로드</a>하고 실행하세요.<br/>
 2. <a href="{{panel}}" target='_blank' rel='noreferrer'>컨트롤 패널</a>에 등록하세요.<br/>
 3. 기기에서 프로그램에서 제공하는 ID와 비밀번호를 지정하여 스테이션을 추가하세요.<br/>
 4. 제어를 시작하세요!
 <br/><br/>`,
    mainCommands: '주요 명령',
    mainCommandsT: `<ul>
 <li>-i 또는 --install-빠른 설치</li>
 <li>-u 또는 --uninstall-빠른 제거</li> 
 <li>-h 또는 --help-도움말</li> 
 <li>--update-최신 버전으로 업데이트</li> 
 </ul>
 그래픽 버전에서는 명령이 실행되지만 콘솔에 출력되지 않습니다 (빌드 특성 때문에).
`,
    feedbackTitle: `피드백이 매우 중요합니다!`,
    feedbackBug: `
 버그를 발견하거나 프로그램이 작동하지 않는 경우 채팅에서 알려주세요
 <a href="{{bugs}}" target='_blank' rel='noreferrer'>Telegram에서</a>
 "버그" 섹션에 작성하세요. 가능한 빨리 문제를 해결하겠습니다.
 `,
    feedbackIdea: `
 프로그램이 요구 사항을 충족시키지 않거나 흥미로운 생각이 있는 경우,
 <a href="{{ideas}}" target='_blank'
  rel='noreferrer'>그룹</a>의 "아이디어" 섹션에서 알려주세요.
  `,
};
