export default {
    metaTitle: '赞助项目',
    metaDesc: '有机会参与远程控制和计算机监控项目的开发',
    metaKeys: '赞助项目，项目支持',
    title: '项目支持',
    text: `
 <p>
 在过去的几个月中，我一直独自开发、维护和资助这个项目。然而，我逐渐遇到一些需要财务支持的问题。
 </p>
 <p>
 如果你有机会，请支持这个服务。我将非常感激！无论捐赠的金额是多少，都意味着你认为我的项目很有价值，并相信它的发展，就像我一样。这种支持将极大地增强我的动力。
 </p>
 <p>
 <div class="{{subtitle1}}">
 如何汇款
 </div>
 由于我坚持加密无政府主义的价值观和观点，我只有加密货币钱包。以下是如何通过银行卡为它们提供资金的说明：
 <div class="{{code}}">
USDT <span class="{{green}}">bep20（币安）</span>：0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
BTC：bc1qyz2gken4mxgfquqfmvrevyttycdxtp349ve7kz <br/><br/>
ETH：0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
DOGE：D8aQgYPjkpN92AJiRokhHaSWw9LBG9g6xT
</div>
<div class="{{subtitle2}}">说明</div>
<ul class="{{instruction}}">
<li> 如果你有一个带有USDT bep20硬币的电子钱包，可以直接汇款。</li>
<li> 通过兑换商进行兑换，可以在这里选择一个，例如：<a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html" target=_blank rel=noreferrer>bestchange.com</a>:
<ul>
<li> 转到兑换商监控页面</li>
<li> 在“给出”的栏目中，选择一个合适的付款方式，例如“Visa/Mastercard”。我建议选择在该栏目中为你的国家选择利率最低的付款方式（大约3-5%），通常是本地货币中的一家知名银行，而不是直接以美元进行的转账。
<li> 在“接收”的栏目中，应显示为Tether bep20</li>
<li> 选择具有最佳汇率或条件的兑换商</li>
<li> 在兑换商的网站上，输入你想要充值的金额（以美元计算），复制充值地址0x1716a9bAaE728780D98fb26faAe17D79FB62F090，填写剩余字段，并根据提供的详细信息转账</li>
<li> 交易将在20分钟内由兑换商和网络处理</li>
</ul>
</li>
</ul>
如果遇到任何问题，请在<a href="{{tgMy}}" target=_blank rel=noreferrer>Telegram</a>上写信给我，我们将一起解决。
</p>
<p>
<div class="{{subtitle1}}">
 资金的使用方式
 </div>
 购买必要的设备，支付服务器租赁费用，支付开发辅助服务费用，测试，设计，饼干。
 <br/>
 <br/>
 如果金额超过几个月的工资，我将能够雇佣一个助手，大大加快新版本的发布速度。
</p>
<div class="{{subtitle1}}">
 非常感谢您的支持！
 </div>
 即使你只是花时间阅读这段文字 :)
`
};
