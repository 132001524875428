export default {
    idea: 'Submit an Idea',
    ideaT: 'The system is open to your ideas and feedback! Write about them in our group on <a href="{{tg}}" target="_blank" rel="noreferrer">Telegram</a> or send them to me privately. <br/> Your participation in any form will help the project move forward. Thank you in advance!',

    metaTitle: 'Feedback',
    metaDesc: 'Report an issue with the "Cusco Remote Control" desktop remote control service',
    metaKeys: '',
    title: 'Feedback',
    text: `
Telegram group for discussion: <a href="{{tg}}" target='_blank' rel='noreferrer'>cusco_rc_group</a><br/>
A dedicated topic has been created for each subject:
<ul>
<li><a href="{{tgIdea}}" target='_blank' rel='noreferrer'>Ideas</a> - the features you would like to see in the project</li>
<br/><li><a href="{{tgBugs}}" target='_blank' rel='noreferrer'>Bugs</a> - if you encounter an error or anticipate one. For security-related bugs, it's better to send them to me privately.</li>
<br/><li><a href="{{tgMain}}" target='_blank' rel='noreferrer'>General</a> - discussions on general topics, polls</li>
</ul>
`
};
