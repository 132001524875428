export default {
    metaTitle: '원격 액세스 기능: 제어, 모니터링, 통계, 재고, 서버 및 컴퓨터 관리',
    metaDesc: '시스템 관리자 및 일반 사용자를 위한 원격 컴퓨터 제어를 위한 전문 서비스. 외국의 원격 액세스 프로그램을 능가하는 대안. 사용자 요청 기능의 적극적인 개발과 구현. 프로세스 관리, 프로그램 제거, 원격 명령 실행, 장치 인벤토리, 디바이스 표시, 전력 관리.',
    metaKeys: '프로세스 관리, 프로그램 제거, 원격 명령 실행, 장치 인벤토리, 디바이스 표시, 전력 관리를 위한 무료 프로그램',
    title: '시스템 기능',
    startUse: '사용 시작하기',
    remoteControl: '원격 제어',
    remoteControlT: '팀뷰어, AnyDesk, Ammyy Admin과 유사한 표준 원격 데스크톱 제어입니다.<br/><br/>브라우저를 통해 컴퓨터나 모바일 전화에서 연결할 수 있습니다.',
    invent: '장치 인벤토리',
    inventT: '회계 처리에는 재고가 필요하지만 각 기기에 연결하고 싶지 않으신가요?<br/><br/>제어 패널에서 모든 기기의 특성을 확인하기만 하면 됩니다.',
    process: '프로세스',
    processT: '언제든지 스테이션에서 실행 중인 모든 프로세스를 볼 수 있으며 필요한 경우 수동으로 종료할 수 있습니다.',
    programs: '프로그램 및 장치',
    programsT: '설치된 모든 애플리케이션을 확인하고 원하는 경우 장치에서 제거할 수 있습니다.<br/><br/>설치된 장치를 정확히 알고 싶나요? 문제 없습니다.',
    remoteCommand: '원격 명령 실행',
    remoteCommandT: '문제를 해결하거나 무언가를 알아내기 위해 단 하나의 명령만 실행해야 할 때가 있습니다. 연결하고 싶지 않을 수도 있습니다. 단 한 번의 버튼 클릭으로 보낼 수 있으며 실행 결과를 받을 수 있습니다.<br/><br/>전력 관리도 마찬가지로 간단합니다. 몇 번의 클릭으로 재시작, 잠금, 스테이션을 대기 상태로 설정할 수 있습니다.',
    feature: '미래 기능 목록',
    idea: '아이디어 제출',
    ideaT: '시스템은 여러분의 아이디어와 피드백을 환영합니다! Telegram 그룹 <a href="{{tg}}" target="_blank" rel="noreferrer">여기</a>에 작성하거나 저에게 직접 메시지를 보내주세요.<br/>어떤 형태의 참여도 프로젝트를 발전시키는 데 도움이 됩니다. 미리 감사드립니다!',
};
