export default {
    metaTitle: 'Gestión remota de ordenadores y servidores. Monitorización. Ejecución de comandos remotos.',
    metaDesc: 'El mejor programa para acceder de forma remota a ordenadores. Control remoto de PC o servidor.',
    metaKeys: 'Acceso remoto gratuito, alternativa a Teamviewer, alternativa a AnyDesk, programa de acceso remoto',
    title: 'Conéctate a las estaciones ahora',
    titleD: 'Toma el control total de tus dispositivos',
    download: 'Descargar',
    possibilities: 'Posibilidades',
    possibilitiesF: 'Posibilidades futuras',
    inv: 'Inventario de estaciones',
    vis: 'Control visual <br/>como Teamviewer/AnyDesk',
    manage: 'Gestión y visualización:',
    proc: 'Procesos en ejecución',
    prog: 'Programas',
    dev: 'Dispositivos',
    commands: 'Comandos',
    power: 'Gestión de energía',
    comDo: 'Ejecución de comandos',
    launch: 'Lanzamiento sin instalación',
    security: 'Seguridad',
    onlyYou: 'Solo tú puedes enviar comandos a tus estaciones',
    itIsAKey: 'Todo gracias a una clave única almacenada contigo',
    nobodyCan: 'Ni el servicio ni nadie más pueden controlar tus estaciones sin tu conocimiento',
    youTooF: 'Tú también puedes influir en el futuro del proyecto',
    youIdeas: 'Sugiere nuevas ideas, vota, participa en la vida del proyecto',
    helpYou: 'El sistema implementa tus deseos e introduce nuevas funciones',
    howToUse: '¿Cómo usar?',
    howToUseText: '1. <a href="/{{lang}}/downloads">Descarga</a> e instala el programa en tus dispositivos<br/>' +
        '2. Regístrate en el <a href="{{reg}}" target=\'_blank\' rel=\'noreferrer\'>panel de control</a><br/>' +
        '3. Añade una estación ingresando el ID y contraseña proporcionados en el programa instalado<br/>' +
        '4. ¡Toma el control!',
    applyTelegram: 'Únete al proyecto en Telegram',
    newsG: 'Grupo de noticias',
    chat: 'Chat general',
};