export default {
    metaTitle: 'Gestion à distance des ordinateurs et des serveurs. Surveillance. Exécution de commandes à distance.',
    metaDesc: 'Le meilleur programme pour l\'accès à distance aux ordinateurs. Contrôle à distance des PC ou des serveurs.',
    metaKeys: 'Accès à distance gratuit, alternative à TeamViewer, alternative à AnyDesk, programme d\'accès à distance',
    title: 'Connectez-vous aux stations maintenant',
    titleD: 'Prenez le contrôle total de vos appareils',

    showDone: 'Afficher les tâches terminées',
    hideDone: 'Masquer les tâches terminées',
    showMore: 'Afficher les détails',
    inQuery: 'En attente',
    hide: 'Masquer',
    more: 'Plus',
    needMoney: 'Financement nécessaire',
    "task0": "Liste des stations, ajout d\'une nouvelle",
    "task1": "Informations générales sur la station",
    "task2": "Processus. Gestion et affichage",
    "task3": "Programmes. Gestion et affichage",
    "task4": "Appareils. Affichage",
    "task5": "Exécution de commandes à distance",
    "task6": "Gestion de l\'alimentation",
    "task7": "Historique des commandes",
    "task8": "Gestion visuelle des fenêtres",
    "task9": "Version pour Mac",
    "task9Desc":
        '<p>' +
        'Comme je travaille sur Windows depuis de nombreuses années et que j\'ai vendu mon dernier Mac il y a cinq ans, ' +
        'j\'ai essayé de le construire sur une machine virtuelle. Cela a bien fonctionné, mais je n\'ai pas pu construire le projet ' +
        'car le temps de réponse pour les actions était d\'environ 3-5 secondes, que ce soit en utilisant vmware ou virtualbox. ' +
        'J\'ai essayé toutes les variations de paramètres possibles pendant quelques jours, mais le résultat était le même. ' +
        'De plus, il est seulement possible de développer pour ce système d\'exploitation sur le même système d\'exploitation ; il est impossible de le faire sur Windows ou Linux.' +
        '</p>' +
        '<p>' +
        'J\'en suis arrivé à la conclusion que j\'ai besoin d\'acheter un appareil avec au moins des performances moyennes pour ce système. ' +
        'Si vous le pouvez, veuillez soutenir le projet ; je vous en serais très reconnaissant. Une fois que le montant nécessaire sera collecté, ' +
        'je terminerai la tâche actuelle et travaillerai sur celle-ci.' +
        ' <a href="{{lang}}/donate">Vous pouvez soutenir ici</a>' +
        '</p>',
    "task10": "Tableau de bord utilisateur",
    "task11": "Traduction dans des langues étrangères",
    "task12": "Authentification à deux facteurs",
    "task13": "Ligne de commande à distance",
    "task13Desc": `<p>Similaire à un client SSH mais dans le navigateur.</p>`,
    "task14": "Installateur au format MSI",
    "task15": "Suivi des processus sur un graphique",
    "task16": "Documentation de l\'API",
    "task17": "Ouverture du code source du client",
    "task18": "Gestion de l\'espace disque",
    "task19": "Visualisation des journaux",
    "task20": "Accès temporaire",
    "task21": "Application mobile",
    "task22": "Redirection de port",
    "task23": "Génération de programmes par les utilisateurs",
    "task23Desc": `<p>Un fichier de lancement sera créé et lorsque le destinataire l'ouvrira sur son appareil, l'utilisateur qui a envoyé le fichier sera ajouté à la liste autorisée.</p>`,
    "task24": "Fonctions d'administration dans le client",
    "task24Desc": `<p>La possibilité d'accéder au panneau de contrôle de cette station ou de toute autre sans utiliser un navigateur.</p>`,
    "task25": "Différenciation des autorisations d'accès et de contrôle"
};
