export default {
    metaTitle: 'إدارة الكمبيوتر والخادم عن بُعد. المراقبة. تنفيذ أوامر عن بُعد.',
    metaDesc: 'أفضل برنامج للوصول عن بُعد إلى الكمبيوترات. التحكم في جهاز الكمبيوتر أو الخادم عن بُعد.',
    metaKeys: 'وصول عن بُعد مجاني، بديل لـ TeamViewer، بديل لـ AnyDesk، برنامج الوصول عن بُعد',
    title: 'اتصل بالمحطات الآن',
    titleD: 'تحكم كامل في أجهزتك',

    showDone: 'إظهار المكتملة',
    hideDone: 'إخفاء المكتملة',
    showMore: 'إظهار التفاصيل',
    inQuery: 'قيد الانتظار',
    hide: 'إخفاء',
    more: 'المزيد',
    needMoney: 'الحاجة إلى تمويل',
    "task0": "قائمة المحطات، إضافة جديدة",
    "task1": "معلومات عامة حول المحطة",
    "task2": "العمليات. الإدارة والعرض",
    "task3": "البرامج. الإدارة والعرض",
    "task4": "الأجهزة. العرض",
    "task5": "تنفيذ الأوامر عن بُعد",
    "task6": "إدارة الطاقة",
    "task7": "سجل الأوامر",
    "task8": "إدارة النوافذ المرئية",
    "task9": "بناء لنظام التشغيل macOS",
    "task9Desc":
        '<p>' +
        'نظرًا لأنني عملت على نظام التشغيل ويندوز لسنوات عديدة وبيعت جهازي الماك الأخير قبل خمس سنوات، ' +
        'حاولت بناؤه على جهاز افتراضي. بدأ الأمر بشكل مثالي، لكنني لم أتمكن من بناء المشروع ' +
        'لأن وقت الاستجابة للإجراءات كان حوالي 3-5 ثوانٍ، بغض النظر عما إذا كان ذلك في VMware أم VirtualBox. ' +
        'جربت جميع التركيبات الممكنة لعدة أيام، لكن النتيجة كانت متسقة. ' +
        'ويمكن تطوير هذا النظام فقط على نظام التشغيل نفسه؛ لا يمكن القيام به على نظام التشغيل ويندوز أو لينكس.' +
        '</p>' +
        '<p>' +
        'توصلت إلى استنتاج أنني بحاجة إلى شراء جهاز ذو أداء متوسط ​​على هذا النظام. ' +
        'إذا كنت تستطيع ذلك، يرجى دعم المشروع. سأكون ممتنًا جدًا. بمجرد جمع المبلغ اللازم، ' +
        'سأكمل المهمة الحالية وأعمل على هذه المهمة. ' +
        ' <a href="{{lang}}/donate">يمكنك دعم المشروع هنا</a>' +
        '</p>',
    "task10": "لوحة المستخدم",
    "task11": "ترجمة إلى لغات أجنبية",
    "task12": "المصادقة ذات العاملينين",
    "task13": "سطر الأوامر عن بُعد",
    "task13Desc": `<p>مماثل لعميل SSH ولكن في المتصفح.</p>`,
    "task14": "مثبت بتنسيق MSI",
    "task15": "تتبع العمليات على الرسم البياني",
    "task16": "وثائق واجهة برمجة التطبيقات",
    "task17": "فتح مصدر العميل",
    "task18": "إدارة مساحة القرص",
    "task19": "عرض السجل",
    "task20": "وصول مؤقت",
    "task21": "تطبيق المحمول",
    "task22": "إعادة توجيه المنفذ",
    "task23": "إنشاء برنامج يولده المستخدم",
    "task23Desc": `<p>سيتم إنشاء ملف تشغيل، وعند فتحه من قبل المستلم على جهازه، سيتم إضافة المستخدم الذي أرسل الملف إلى قائمة السماح.</p>`,
    "task24": "وظائف الإدارة في العميل",
    "task24Desc": `<p>القدرة على الوصول إلى لوحة التحكم في هذه المحطة أو أي محطة أخرى دون استخدام المتصفح.</p>`,
    "task25": "تمييز أذونات الوصول والتحكم"
};
