export default {
    metaTitle: 'Remote Access-Funktionen: Steuerung, Überwachung, Statistiken, Bestandsaufnahme, Server- und Computer-Management',
    metaDesc: 'Ein professioneller Service für Systemadministratoren und normale Benutzer zur Remote-Steuerung von Computern. Eine Alternative, die ausländische Remote-Zugriffsprogramme übertrifft. Aktive Entwicklung und Implementierung von benutzeranforderter Funktionalität. Prozessverwaltung, Programm deinstallation, Remote-Befehlsausführung, Bestandsaufnahme von Stationen, Geräteanzeige, Energiemanagement.',
    metaKeys: 'Kostenloses Programm für Prozessverwaltung, Programm deinstallation, Remote-Befehlsausführung, Bestandsaufnahme von Stationen, Geräteanzeige, Energiemanagement',
    title: 'Systemfunktionen',
    startUse: 'Nutzung starten',
    remoteControl: 'Remote-Steuerung',
    remoteControlT: 'Standard-Fernsteuerung des Desktops. Ähnlich wie TeamViewer, AnyDesk, Ammyy Admin.<br/><br/>Sie können sich von einem Computer oder einem Mobiltelefon über einen Browser verbinden.',
    invent: 'Bestandsaufnahme von Stationen',
    inventT: 'Für die Buchhaltung ist eine Bestandsaufnahme erforderlich und Sie möchten nicht zu jeder Maschine eine Verbindung herstellen?<br/><br/>Überprüfen Sie einfach die Merkmale aller Geräte im Kontrollfeld.',
    process: 'Prozesse',
    processT: 'Sie können jederzeit alle auf der Station ausgeführten Prozesse anzeigen und filtern und sie bei Bedarf manuell beenden.',
    programs: 'Programme und Geräte',
    programsT: 'Überprüfen Sie alle installierten Anwendungen und entfernen Sie sie bei Bedarf vom Gerät.<br/><br/>Möchten Sie genau wissen, welche Geräte installiert sind? Kein Problem.',
    remoteCommand: 'Remote-Befehlsausführung',
    remoteCommandT: 'Es gibt Situationen, in denen Sie nur einen Befehl ausführen müssen, um ein Problem zu lösen oder etwas herauszufinden, und Sie möchten keine Verbindung herstellen. Sie können ihn mit nur einem Klick senden und das Ausführungsergebnis erhalten.<br/><br/>Das Energiemanagement funktioniert genauso einfach. Neustart, Sperren oder den Ruhezustand der Station mit ein paar Klicks aktivieren.',
    feature: 'Liste zukünftiger Funktionen',
    idea: 'Idee einreichen',
    ideaT: 'Das System steht Ihren Ideen und Feedback offen gegenüber! Schreiben Sie darüber in unserer Telegramm-Gruppe <a href="{{tg}}" target="_blank" rel="noreferrer">hier</a> oder senden Sie mir eine Direktnachricht.<br/>Ihre Beteiligung in jeder Form wird dem Projekt helfen, voranzukommen. Vielen Dank im Voraus!',
};
