import cn from "classnames";
import styles from './myButton.module.scss';
import React from "react";
import {NavLink} from "react-router-dom";

export default function MyButton({
                                     onClick,
                                     href,
                                     hrefFull,
                                     text,
                                     variant = 'primary',
                                     className,
                                     disabled,
                                     ...props
                                 }: any) {

    const onClickLocal = e => {
        if (onClick) {
            if (onClick())
                e.preventDefault()
        }/*else if(href)
            MyHistory.push(href)*/
    }
    if (disabled) {
        onClick = undefined
        href = undefined
    }

    if (hrefFull) {
        return <a href={hrefFull} className={cn(styles.main, styles[variant], 'custom', {
            [styles.disabled]: disabled
        }) + ' ' + className} {...props} onClick={onClickLocal}>
            {text}
        </a>;
    } else {
        return <NavLink to={href} className={cn(styles.main, styles[variant], 'custom', {
            [styles.disabled]: disabled
        }) + ' ' + className} {...props} onClick={onClickLocal}>
            {text}
        </NavLink>;
    }

}