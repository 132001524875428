export default {
    metaTitle: 'リモートアクセスの機能：制御、監視、統計、インベントリ、サーバーおよびコンピュータの管理',
    metaDesc: 'システム管理者や一般ユーザー向けのリモートコンピュータ制御のためのプロフェッショナルなサービス。外国のリモートアクセスプログラムを凌駕する選択肢。ユーザー要求の機能の積極的な開発と実装。プロセス管理、プログラム削除、リモートコマンド実行、ステーションインベントリ、デバイス表示、電源管理。',
    metaKeys: 'プロセス管理、プログラム削除、リモートコマンド実行、ステーションインベントリ、デバイス表示、電源管理の無料プログラム',
    title: 'システムの機能',
    startUse: '利用を開始する',
    remoteControl: 'リモート制御',
    remoteControlT: '標準的なリモートデスクトップ制御。TeamViewer、AnyDesk、Ammyy Adminと似ています。<br/><br/>ブラウザからコンピュータやモバイル電話に接続できます。',
    invent: 'ステーションのインベントリ',
    inventT: '会計にはインベントリが必要ですが、各マシンに接続するのは面倒ですか？<br/><br/>コントロールパネルですべてのデバイスの特性をチェックするだけです。',
    process: 'プロセス',
    processT: 'いつでもステーションで実行されているすべてのプロセスを表示し、必要に応じて手動で終了できます。',
    programs: 'プログラムとデバイス',
    programsT: 'インストールされているすべてのアプリケーションを確認し、必要に応じてデバイスから削除します。<br/><br/>インストールされている機器を正確に知りたいですか？ 問題ありません。',
    remoteCommand: 'リモートコマンドの実行',
    remoteCommandT: '問題を解決したり何かを調べるために1つのコマンドを実行するだけの場合がありますが、接続したくない場合もあります。ボタンを1回だけ押して送信し、実行結果を取得できます。<br/><br/>電源管理も同様に簡単です。数回のクリックで再起動、ロック、スリープモードにすることができます。',
    feature: '将来の機能のリスト',
    idea: 'アイデアの提出',
    ideaT: 'システムはあなたのアイデアやフィードバックに開かれています！ Telegramグループでそれについて書いてください <a href="{{tg}}" target="_blank" rel="noreferrer">ここ</a> または直接メッセージを送ってください。<br/>あらゆる形態の参加がプロジェクトを前進させます。お先にどうもありがとうございます！',
};
