export default {
    idea: 'Предложить идею',
    ideaT: 'Система открыта к вашим идеям и замечаниям!' +
        ' Напишите о них в нашей группе в <a href="{{tg}}"' +
        ' target="_blank" rel="noreferrer">телеграмме</a> или мне в личку. <br/>' +
        'Ваше участие в любом виде поможет проекту двигаться дальше. Заранее спасибо!',

    metaTitle: 'Обратная связь',
    metaDesc: 'Сообщить о проблеме в сервисе удаленного управления рабочим столом "Cusco Remote Control"',
    metaKeys: '',
    title: 'Обратная связь',
    text: `
Телеграм группа для обсуждения <a href="{{tg}}" target='_blank' rel='noreferrer'>cusco_rc_group</a><br/>
Для каждой темы создан соответствующий топик:
<ul>
<li><a href="{{tgIdea}}" target='_blank' rel='noreferrer'>Идеи</a>- те функции, которые вы хотите видеть в проекте</li>
<br/><li><a href="{{tgBugs}}" target='_blank' rel='noreferrer'>Баги</a>- в случае если вы столкнулись с ошибкой или предвидите её. Баги затрагивающие
безопасность - лучше скидывайте мне в личку
</li>
<br/><li><a href="{{tgMain}}" target='_blank' rel='noreferrer'>Основной</a>- разговоры на общие темы, голосования</li>
</ul>
`
};
