export default {
  platforms : [
    {key: 'win', name: 'Windows', typeBuilds: ['gui', 'console']},
    {key: 'linux', name: 'Linux', typeBuilds: ['console']},
    {key: 'mac', name: 'MacOS', typeBuilds: ['gui']}
  ],
  typeBuilds : [
    {key: 'gui', name: 'Графическая', platforms: ['win', 'mac']},
    {key: 'console', name: 'Консольная', platforms: ['win', 'linux']},
  ],
};
