export default {
    metaTitle: '远程计算机和服务器管理。监控。远程命令执行。',
    metaDesc: '最佳远程访问计算机的程序。远程 PC 或服务器控制。',
    metaKeys: '免费远程访问，Teamviewer 替代品，AnyDesk 替代品，远程访问程序',
    title: '立即连接到站点',
    titleD: '完全控制您的设备',
    download: '下载',
    possibilities: '可能性',
    possibilitiesF: '未来可能性',
    inv: '站点清单',
    vis: '可视化控制<br/>像 Teamviewer/AnyDesk',
    manage: '管理和查看：',
    proc: '运行进程',
    prog: '程序',
    dev: '设备',
    commands: '命令',
    power: '电源管理',
    comDo: '命令执行',
    launch: '无需安装即可启动',
    security: '安全性',
    onlyYou: '只有您可以向您的站点发送命令',
    itIsAKey: '这一切都归功于您保存的唯一密钥',
    nobodyCan: '无论是服务还是其他人，在未经您同意的情况下都无法控制您的站点',
    youTooF: '您可以影响项目的未来',
    youIdeas: '提出新的想法，投票，参与项目的生活',
    helpYou: '该系统满足您的愿望并引入新功能',
    howToUse: '如何使用？',
    howToUseText: '1. <a href="/{{lang}}/downloads">下载</a>并在您的设备上安装该程序<br/>' +
        '2. 在 <a href="{{reg}}" target=\'_blank\' rel=\'noreferrer\'>控制面板</a> 中注册<br/>' +
        '3. 添加站点，输入安装程序中提供的 ID 和密码<br/>' +
        '4. 接管控制！',
    applyTelegram: '在 Telegram 上加入项目',
    newsG: '新闻组',
    chat: '常规聊天',
};
