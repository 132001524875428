export default {
    metaTitle: 'Télécharger le programme d\'accès à distance. Windows, Linux, MacOS.',
    metaDesc: 'Téléchargez la dernière version pour avoir un contrôle total de vos PC et serveurs.',
    metaKeys: 'télécharger cusco, cusco win, cusco linux, cusco macos',
    title: 'Choisissez votre plateforme',
    download: 'Télécharger',
    version: 'Version',
    versions: 'Versions',
    versionsT: `Il existe deux versions du programme :
 <ul>
 <li>Avec une interface graphique - plus grande en taille mais plus pratique à utiliser</li>
 <li>Avec une interface en mode terminal - plus petite en taille, fonctionne dans la console et utilise le clavier pour le contrôle.
 Version préférée si vous avez une ancienne version du système ou si l'interface graphique ne fonctionne pas.
 </li>
 </ul>`,

    install: 'Installation',
    installTWin: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>Téléchargez</a> et exécutez le programme.<br/>
 2. Inscrivez-vous dans le <a href="{{panel}}" target='_blank' rel='noreferrer'>panneau de contrôle</a>.<br/>
 3. Ajoutez une station en spécifiant l'ID et le mot de passe fournis dans le programme sur l'appareil.<br/>
 4. Commencez le contrôle !
 <br/><br/>`,
    installTLinux: `
 1. Téléchargez le programme en utilisant la commande :<br/>
 <div class="{{sCode}}">
 cd ~<br/>
 wget {{installLink}}<br/>
 </div>
 2. Exécutez le programme :<br/>
 <div class="{{sCode}}">
 sudo chmod +x {{fileName}}<br/>
 sudo ./{{fileName}}<br/>
 </div>

 3. Inscrivez-vous dans le <a href="{{panel}}" target='_blank' rel='noreferrer'>panneau de contrôle</a>.<br/>
 4. Ajoutez une station en spécifiant l'ID et le mot de passe fournis dans le programme sur l'appareil.<br/>
 5. Commencez le contrôle !

 <br/><br/>
 Remarque : Lors de l'installation, un service est créé qui démarre lorsque l'ordinateur est allumé.<br/>
 Vous pouvez ouvrir le programme dans la console en utilisant la commande "cusco".<br/>
 Pour obtenir une liste de toutes les commandes, utilisez "cusco --help".`,
    inProgress: `En cours de réalisation`,
    installTMac: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>Téléchargez</a> et exécutez le programme.<br/>
 2. Inscrivez-vous dans le <a href="{{panel}}" target='_blank' rel='noreferrer'>panneau de contrôle</a>.<br/>
 3. Ajoutez une station en spécifiant l'ID et le mot de passe fournis dans le programme sur l'appareil.<br/>
 4. Commencez le contrôle !
 <br/><br/>`,
    mainCommands: 'Commandes principales',
    mainCommandsT: `<ul>
 <li>-i ou --install - installation rapide</li>
 <li>-u ou --uninstall - désinstallation rapide</li> 
 <li>-h ou --help - aide</li> 
 <li>--update - mise à jour vers la dernière version</li> 
 </ul>
 Dans la version graphique, la commande sera exécutée, mais il n'y aura aucune sortie dans la console (en raison des particularités de la construction).
`,
    feedbackTitle: `Votre retour d'information est très important !`,
    feedbackBug: `
 Si vous avez remarqué un bug ou si le programme ne fonctionne pas pour vous, veuillez écrire dans le chat
 <a href="{{bugs}}" target='_blank' rel='noreferrer'>sur Telegram</a>
 dans la section "Bugs". Je vais essayer de résoudre le problème le plus rapidement possible.
 `,
    feedbackIdea: `
 Si le programme ne répond pas à vos besoins ou si vous avez des idées intéressantes,
 veuillez me le faire savoir dans le
 <a href="{{ideas}}" target='_blank'
  rel='noreferrer'>groupe</a> dans la section "Idées".
  `,
};
