export default {
    metaTitle: 'تنزيل برنامج الوصول عن بعد. Windows، Linux، MacOS.',
    metaDesc: 'قم بتنزيل أحدث إصدار للتحكم الكامل في أجهزة الكمبيوتر والخوادم.',
    metaKeys: 'تنزيل كوسكو، كوسكو ويندوز، كوسكو لينكس، كوسكو ماكوس',
    title: 'اختر منصتك',
    download: 'تنزيل',
    version: 'الإصدار',
    versions: 'الإصدارات',
    versionsT: `هناك نسختان من البرنامج:
 <ul>
 <li>بواجهة رسومية - أكبر حجمًا ولكن أكثر ملاءمة للاستخدام</li>
 <li>بواجهة طرفية - أصغر حجمًا، يعمل في وضع الكونسول ويستخدم لوحة المفاتيح للتحكم.
 النسخة المفضلة إذا كان لديك نسخة قديمة من النظام أو لا يعمل واجهة الرسومية.
 </li>
 </ul>`,

    install: 'التثبيت',
    installTWin: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>قم بتنزيل</a> وتشغيل البرنامج.<br/>
 2. سجل في <a href="{{panel}}" target='_blank' rel='noreferrer'>لوحة التحكم</a>.<br/>
 3. أضف محطة عن طريق تحديد معرف الجهاز وكلمة المرور المقدمة في البرنامج على الجهاز.<br/>
 4. ابدأ التحكم!
 <br/><br/>`,
    installTLinux: `
 1. قم بتنزيل البرنامج باستخدام الأمر:<br/>
 <div class="{{sCode}}">
 cd ~<br/>
 wget {{installLink}}<br/>
 </div>
 2. قم بتشغيل البرنامج:<br/>
 <div class="{{sCode}}">
 sudo chmod +x {{fileName}}<br/>
 sudo ./{{fileName}}<br/>
 </div>

 3. سجل في <a href="{{panel}}" target='_blank' rel='noreferrer'>لوحة التحكم</a>.<br/>
 4. أضف محطة عن طريق تحديد معرف الجهاز وكلمة المرور المقدمة في البرنامج على الجهاز.<br/>
 5. ابدأ التحكم!

 <br/><br/>
 ملاحظة: أثناء التثبيت، يتم إنشاء خدمة تبدأ عند تشغيل الكمبيوتر.<br/>
 يمكنك فتح البرنامج في وضع الكونسول باستخدام الأمر "cusco".<br/>
 للحصول على قائمة بجميع الأوامر، استخدم "cusco --help".`,
    inProgress: `قيد التنفيذ`,
    installTMac: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>قم بتنزيل</a> وتشغيل البرنامج.<br/>
 2. سجل في <a href="{{panel}}" target='_blank' rel='noreferrer'>لوحة التحكم</a>.<br/>
 3. أضف محطة عن طريق تحديد معرف الجهاز وكلمة المرور المقدمة في البرنامج على الجهاز.<br/>
 4. ابدأ التحكم!
 <br/><br/>`,
    mainCommands: 'الأوامر الرئيسية',
    mainCommandsT: `<ul>
 <li>-i أو --install - تثبيت سريع</li>
 <li>-u أو --uninstall - إلغاء التثبيت السريع</li> 
 <li>-h أو --help - مساعدة</li> 
 <li>--update - تحديث إلى أحدث إصدار</li> 
 </ul>
 في النسخة الرسومية، سيتم تنفيذ الأمر، ولكن لن يكون هناك إخراج في واجهة الكونسول (نظرًا لخصائص البناء).
`,
    feedbackTitle: `تعليقاتك مهمة جدًا!`,
    feedbackBug: `
 إذا لاحظت خطأ ما أو لم يعمل البرنامج بالنسبة لك، يرجى كتابته في الدردشة
 <a href="{{bugs}}" target='_blank' rel='noreferrer'>على Telegram</a>
 في قسم "الأخطاء". سأحاول حل المشكلة في أسرع وقت ممكن.
 `,
    feedbackIdea: `
 إذا لم يلبي البرنامج أي من احتياجاتك أو إذا كان لديك أي أفكار مثيرة للاهتمام،
 يرجى إعلامي في
 <a href="{{ideas}}" target='_blank'
  rel='noreferrer'>المجموعة</a> في قسم "الأفكار".
  `,
};
