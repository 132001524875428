export default {
    metaTitle: '서비스 비용',
    metaDesc: '원격 제어 및 모니터링 시스템의 비용은 얼마입니까?',
    metaKeys: '기업에서의 원격 제어, 컴퓨터 관리 비용, 직원 모니터링, 무료 원격 제어',
    title: '비용',
    titleD: '한 달에 스테이션당 20센트부터 시작',
    text: `
 <p>
 이 서비스를 사용하면 사용자가 자체 가격을 설정할 수 있습니다. 5개의 스테이션은 무료로 제공되며, 가격은 20센트에서 10달러까지 설정할 수 있습니다.
 </p>
 <p>
 이 옵션은 이 프로젝트의 아이디어에 대한 평가 역할을 하기도 합니다. 지정된 금액이 높을수록 이 프로젝트는 더욱 좋아집니다. 더 강력한 서버, 적은 버그, 더 사용자 친화적인 인터페이스 및 빠른 상호 작용이 가능해집니다. 가장 중요한 것은 스테이션 관리를 더 쉽게하기 위해 새로운 기능이 도입된다는 것입니다.
 </p>
`
};
