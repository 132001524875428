export default {
    metaTitle: '服务费用',
    metaDesc: '远程控制和监控系统的费用是多少？',
    metaKeys: '企业远程控制，计算机管理价格，员工监控，免费远程控制',
    title: '费用',
    titleD: '每个工作站每月起始价格为20美分',
    text: `
 <p>
 该服务允许用户自行设定价格：从20美分到10美元不等，并且提供5个免费工作站。
 </p>
 <p>
 这个选项也可以作为对该项目背后理念的评估。指定的金额越高，这个项目就会越好。它将拥有更强大的服务器、更少的错误、更用户友好的界面和更快的交互。最重要的是，将引入新功能，使工作站管理变得更加简单。
 </p>
`
};
