export default {
    metaTitle: 'رعاية المشروع',
    metaDesc: 'فرصة للمشاركة في تطوير مشروع التحكم عن بعد ومراقبة الكمبيوتر',
    metaKeys: 'رعاية المشروع ، دعم المشروع',
    title: 'دعم المشروع',
    text: `
 <p>
 لقد قمت بتطوير وصيانة وتمويل هذا المشروع بمفردي لعدة أشهر. ومع ذلك ، أواجه تدريجياً مشاكل معينة تتطلب الدعم المالي.
 </p>
 <p>
 إذا كانت لديك الفرصة ، يرجى دعم الخدمة. سأكون ممتناً جدًا! بغض النظر عن المبلغ المتبرع به ، فهذا سيعني أنك تعتبر مشروعي ذا قيمة وتؤمن بتطويره تمامًا مثلما أفعل. سيقوي هذا الدعم تحفيزي بشكل كبير.
 </p>
 <p>
 <div class="{{subtitle1}}">
 كيفية إرسال الأموال
 </div>
 نظرًا لأنني أتمسك بقيم وآراء الأناركية العملة الرقمية ، لدي محافظ العملات الرقمية فقط. فيما يلي تعليمات حول كيفية تمويلها من بطاقة بنكية:
 <div class="{{code}}">
USDT <span class="{{green}}">bep20 (Binance)</span>: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
BTC: bc1qyz2gken4mxgfquqfmvrevyttycdxtp349ve7kz <br/><br/>
ETH: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
DOGE: D8aQgYPjkpN92AJiRokhHaSWw9LBG9g6xT
</div>
<div class="{{subtitle2}}">تعليمات</div>
<ul class="{{instruction}}">
<li> مباشرة - إذا كان لديك محفظة إلكترونية تحتوي على عملات USDT bep20.</li>
<li> عن طريق صرافة ، اختر واحدة هنا ، على سبيل المثال ، <a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html" target=_blank rel=noreferrer>bestchange.com</a>:
<ul>
<li> انتقل إلى صفحة مراقبة الصرافة</li>
<li> في العمود "Gives" ، حدد طريقة الدفع المناسبة ، مثل "Visa/Mastercard". أوصي باختيار طريقة الدفع ذات أقل سعر فائدة في بلدك (حوالي 3-5٪) ، عادة ما يكون أحد البنوك الشهيرة في العملة المحلية ، وليس التحويل المباشر بالدولار.
<li> في العمود "Receives" ، يجب أن يكون مذكور Tether bep20</li>
<li> اختر صرافًا بأفضل سعر أو شروط</li>
<li> في موقع الصرافة ، قم بإدخال المبلغ الذي ترغب في تمويله بالدولارات ، انسخ عنوان الشحن 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 ، قم بملء الحقول المتبقية ، وقم بتحويل المبلغ وفقًا للتفاصيل المقدمة</li>
<li> يتم معالجة العملية من قبل الصرافة والشبكة في غضون 20 دقيقة</li>
</ul>
</li>
</ul>
إذا واجهت أي مشكلة ، يرجى الكتابة إلي في <a href="{{tgMy}}" target=_blank rel=noreferrer>Telegram</a> وسنحلها معًا.
</p>
<p>
<div class="{{subtitle1}}">
 كيف سيتم استخدام الأموال
 </div>
 شراء المعدات الضرورية ، دفع إيجار الخادم ، دفع رسوم المساعدة في التطوير ، الاختبارات ، التصميم ، الكعك.
 <br/>
 <br/>
 إذا تجاوز المبلغ راتب عدة أشهر ، سأكون قادرًا على توظيف مساعد وتسريع إصدار الإصدارات الجديدة بشكل كبير.
</p>
<div class="{{subtitle1}}">
 شكراً مقدماً على دعمك!
 </div>
 حتى إذا قمت بمجرد قراءة هذا النص :)
`
};
