export default {
    metaTitle: 'قدرات الوصول عن بُعد: التحكم والمراقبة والإحصاءات والجرد وإدارة الخوادم والحواسيب',
    metaDesc: 'خدمة احترافية لمسؤولي النظام والمستخدمين العاديين للتحكم عن بُعد في الحواسيب. بديل متفوق لبرامج الوصول البعيد الأجنبية. تطوير نشط وتنفيذ ميزات تطلبها المستخدمين. إدارة العمليات وإزالة البرامج وتنفيذ الأوامر عن بُعد وجرد الأجهزة وعرضها وإدارة الطاقة.',
    metaKeys: 'برنامج مجاني لإدارة العمليات وإزالة البرامج وتنفيذ الأوامر عن بُعد وجرد الأجهزة وعرضها وإدارة الطاقة',
    title: 'قدرات النظام',
    startUse: 'ابدأ الاستخدام',
    remoteControl: 'التحكم عن بُعد',
    remoteControlT: 'التحكم العادي في سطح المكتب عن بُعد. مشابه لـ TeamViewer و AnyDesk و Ammyy Admin.<br/><br/>يمكنك الاتصال من جهاز كمبيوتر أو هاتف محمول عبر متصفح.',
    invent: 'جرد الأجهزة',
    inventT: 'تحتاج إلى جرد الأجهزة ولا ترغب في الاتصال بكل جهاز؟<br/><br/>تحقق فقط من مواصفات جميع الأجهزة في لوحة التحكم.',
    process: 'العمليات',
    processT: 'يمكنك عرض وتصفية جميع العمليات الجارية على الجهاز في أي وقت وإنهائها يدويًا إذا لزم الأمر.',
    programs: 'البرامج والأجهزة',
    programsT: 'تحقق من جميع التطبيقات المثبتة وأزلها من الجهاز إذا كنت ترغب في ذلك.<br/><br/>هل ترغب في معرفة بالضبط ما الأجهزة المثبتة؟ لا مشكلة.',
    remoteCommand: 'تنفيذ الأوامر عن بُعد',
    remoteCommandT: 'هناك حالات تحتاج فيها إلى تنفيذ أمر واحد فقط لحل مشكلة أو معرفة شيء ما، ولا ترغب في الاتصال. يمكنك إرساله بالنقر على زر واحد فقط والحصول على نتيجة التنفيذ.<br/><br/>يعمل إدارة الطاقة بسهولة تامة أيضًا. إعادة التشغيل أو قفل أو وضع الجهاز في وضع السكون ببضع نقرات فقط.',
    feature: 'قائمة الميزات المستقبلية',
    idea: 'تقديم فكرة',
    ideaT: 'النظام مفتوح لأفكارك وملاحظاتك! اكتب عنها في مجموعة Telegram الخاصة بنا <a href="{{tg}}" target="_blank" rel="noreferrer">هنا</a> أو أرسل لي رسالة مباشرة.<br/>مشاركتك بأي شكل سيساعد المشروع على المضي قدمًا. شكرًا لك مقدمًا!',
};
