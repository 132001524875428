export default {
    metaTitle: 'Sponsor the Project',
    metaDesc: 'Opportunity to participate in the development of the remote control and computer monitoring project',
    metaKeys: 'Sponsor the project, project support',
    title: 'Project Support',
    text: `
 <p>
 For many months, I have been developing, maintaining, and funding this project alone. However, I am gradually encountering certain problems that require financial support.
 </p>
 <p>
 If you have the opportunity, please support the service. I would be very grateful! Regardless of the donated amount, it will mean that you consider my project valuable and believe in its development, just like I do. This support will significantly strengthen my motivation.
 </p>
 <p>
 <div class="{{subtitle1}}">
 How to Send Money
 </div>
 Since I adhere to the values and views of crypto-anarchism, I only have cryptocurrency wallets. Below is an instruction on how to fund them from a bank card:
 <div class="{{code}}">
USDT <span class="{{green}}">bep20 (Binance)</span>: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
BTC: bc1qyz2gken4mxgfquqfmvrevyttycdxtp349ve7kz <br/><br/>
ETH: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
DOGE: D8aQgYPjkpN92AJiRokhHaSWw9LBG9g6xT
</div>
<div class="{{subtitle2}}">Instruction</div>
<ul class="{{instruction}}">
<li> Directly - if you have an e-wallet with USDT bep20 coins.</li>
<li> Through an exchanger, choose one here, for example, <a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html" target=_blank rel=noreferrer>bestchange.com</a>:
<ul>
<li> Go to the exchanger monitoring page</li>
<li> dsd\ 244 
<li> In the "Receives" column, it should say Tether bep20</li>
<li> Choose an exchanger with the best rate or conditions</li>
<li> On the exchanger's website, enter the amount you want to fund in dollars, copy the replenishment address 0x1716a9bAaE728780D98fb26faAe17D79FB62F090, fill in the remaining fields, and transfer the amount according to the provided details</li>
<li> The transaction is processed by the exchanger and the network within 20 minutes</li>
</ul>
</li>
</ul>
If you encounter any issues, please write to me on <a href=" {{tgMy}}" target=_blank rel=noreferrer>Telegram</a>, and we will figure it out together.
</p>
<p>
<div class="{{subtitle1}}">
 How the Funds Will Be Used
 </div>
 Purchasing necessary equipment, paying for server rental, paying for development assistance services, testing, design, cookies.
 <br/>
 <br/>
 If the amount exceeds a couple of months' salary, I will be able to hire an assistant and significantly accelerate the release of new versions.
</p>
<div class="{{subtitle1}}">
 Thank you in advance for your support!
 </div>
 Even if you simply took the time to read this text :)
`
};
