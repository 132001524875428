export default {
    metaTitle: 'リモートコンピューターとサーバーの管理。監視。リモートコマンドの実行。',
    metaDesc: 'コンピューターへのリモートアクセスのための最高のプログラム。リモートPCやサーバーの制御。',
    metaKeys: '無料リモートアクセス、TeamViewerの代替、AnyDeskの代替、リモートアクセスプログラム',
    title: '今すぐステーションに接続',
    titleD: 'デバイスを完全に制御する',
    download: 'ダウンロード',
    possibilities: '可能性',
    possibilitiesF: '将来の可能性',
    inv: 'ステーションの在庫',
    vis: 'Teamviewer/AnyDeskのようなビジュアルコントロール<br/>',
    manage: '管理と表示:',
    proc: '実行中のプロセス',
    prog: 'プログラム',
    dev: 'デバイス',
    commands: 'コマンド',
    power: '電源管理',
    comDo: 'コマンドの実行',
    launch: 'インストールなしで起動',
    security: 'セキュリティ',
    onlyYou: 'あなただけがステーションにコマンドを送信できます',
    itIsAKey: 'あなたと一緒に保存されている一意のキーのおかげです',
    nobodyCan: 'サービスも他の誰も、あなたの許可なしにあなたのステーションを制御することはできません',
    youTooF: 'あなたはプロジェクトの将来に影響を与えることができます',
    youIdeas: '新しいアイデアを提案し、投票し、プロジェクトの生活に参加する',
    helpYou: 'システムはあなたの希望を実現し、新機能を導入します',
    howToUse: '使い方',
    howToUseText: '1. <a href="/{{lang}}/downloads">プログラム</a>をダウンロードしてデバイスにインストールします<br/>' +
        '2. <a href="{{reg}}" target=\'_blank\' rel=\'noreferrer\'>コントロールパネル</a>に登録します<br/>' +
        '3. インストールされたプログラムで提供されるIDとパスワードを入力してステーションを追加します<br/>' +
        '4. コントロールを取得します！',
    applyTelegram: 'Telegramでプロジェクトに参加する',
    newsG: 'ニュースグループ',
    chat: '一般的なチャット',
};
