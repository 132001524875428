export default {
    metaTitle: 'Cost of Services',
    metaDesc: 'How much does the remote control and monitoring system cost?',
    metaKeys: 'Remote control in the enterprise, computer management price, employee monitoring, free remote control',
    title: 'Cost',
    titleD: 'starting from 20 cents per station per month',
    text: `
 <p>
 The service allows users to set their own price: from 20 cents to 10 dollars, with 5 stations provided for free.
 </p>
 <p>
 This option also serves as your evaluation of the idea behind this project. The higher the specified amount, the better this project will become. It will have more powerful servers, fewer bugs, a more user-friendly interface, and faster interactions. Most importantly, new features will be introduced to make station management easier for you.
 </p>
`
};
