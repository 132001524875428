export default {
    idea: 'Invia un\'idea',
    ideaT: 'Il sistema è aperto alle tue idee e ai tuoi feedback! Scrivi a riguardo nel nostro gruppo su <a href="{{tg}}" target="_blank" rel="noreferrer">Telegram</a> o inviameli privatamente. <br/>La tua partecipazione in qualsiasi forma contribuirà a far progredire il progetto. Grazie in anticipo!',

    metaTitle: 'Feedback',
    metaDesc: 'Segnala un problema con il servizio di controllo remoto desktop "Cusco Remote Control"',
    metaKeys: '',
    title: 'Feedback',
    text: `
Gruppo Telegram per le discussioni: <a href="{{tg}}" target='_blank' rel='noreferrer'>cusco_rc_group</a><br/>
È stato creato un thread dedicato per ogni argomento:
<ul>
<li><a href="{{tgIdea}}" target='_blank' rel='noreferrer'>Idee</a> - le funzionalità che vorresti vedere nel progetto</li>
<br/><li><a href="{{tgBugs}}" target='_blank' rel='noreferrer'>Bug</a> - se incontri un errore o ne prevedi uno. Per i bug legati alla sicurezza, è meglio inviarli privatamente.</li>
<br/><li><a href="{{tgMain}}" target='_blank' rel='noreferrer'>Generale</a> - discussioni su argomenti generali, sondaggi</li>
</ul>
`
};
