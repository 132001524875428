export default {
    metaTitle: 'Patrocina el Proyecto',
    metaDesc: 'Oportunidad de participar en el desarrollo del proyecto de control remoto y monitoreo de computadoras',
    metaKeys: 'Patrocina el proyecto, apoyo al proyecto',
    title: 'Apoyo al Proyecto',
    text: `
 <p>
 Durante muchos meses, he estado desarrollando, manteniendo y financiando este proyecto por mi cuenta. Sin embargo, poco a poco me estoy encontrando con ciertos problemas que requieren apoyo financiero.
 </p>
 <p>
 Si tienes la oportunidad, por favor, apoya el servicio. ¡Estaré muy agradecido! Independientemente de la cantidad donada, significará que consideras valioso mi proyecto y crees en su desarrollo, al igual que yo. Este apoyo fortalecerá significativamente mi motivación.
 </p>
 <p>
 <div class="{{subtitle1}}">
 Cómo enviar dinero
 </div>
 Dado que me adhiero a los valores y puntos de vista del criptoanarquismo, solo tengo billeteras de criptomonedas. A continuación, se muestra una instrucción sobre cómo financiarlas desde una tarjeta bancaria:
 <div class="{{code}}">
USDT <span class="{{green}}">bep20 (Binance)</span>: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
BTC: bc1qyz2gken4mxgfquqfmvrevyttycdxtp349ve7kz <br/><br/>
ETH: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
DOGE: D8aQgYPjkpN92AJiRokhHaSWw9LBG9g6xT
</div>
<div class="{{subtitle2}}">Instrucción</div>
<ul class="{{instruction}}">
<li> Directamente, si tienes una billetera electrónica con monedas USDT bep20.</li>
<li> A través de un intercambiador, elige uno aquí, por ejemplo, <a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html" target=_blank rel=noreferrer>bestchange.com</a>:
<ul>
<li> Ve a la página de monitoreo del intercambiador</li>
<li> En la columna "Da", selecciona un método de pago adecuado, como "Visa/Mastercard".
Recomiendo elegir en esta columna el método de pago con la tasa de interés más baja para tu país (aproximadamente 3-5%), generalmente uno de los bancos populares en la moneda local,
y no una transferencia directa en USD.
<li> En la columna "Recibe", debe decir Tether bep20</li>
<li> Elige un intercambiador con la mejor tasa o condiciones</li>
<li> En el sitio web del intercambiador, ingresa la cantidad que deseas financiar en dólares, copia la dirección de recarga 0x1716a9bAaE728780D98fb26faAe17D79FB62F090, completa los campos restantes y realiza la transferencia según los detalles proporcionados</li>
<li> La transacción es procesada por el intercambiador y la red en un plazo de 20 minutos</li>
</ul>
</li>
</ul>
Si tienes algún problema, escríbeme en <a href="{{tgMy}}" target=_blank rel=noreferrer>Telegram</a>, y lo resolveremos juntos.
</p>
<p>
<div class="{{subtitle1}}">
 Cómo se utilizarán los fondos
 </div>
 Compra de equipos necesarios, pago de alquiler de servidores, pago de servicios de asistencia al desarrollo, pruebas, diseño, galletas.
 <br/>
 <br/>
 Si la cantidad supera un par de meses de salario, podré contratar a un asistente y acelerar significativamente el lanzamiento de nuevas versiones.
</p>
<div class="{{subtitle1}}">
 ¡Gracias de antemano por tu apoyo!
 </div>
 Incluso si simplemente tomaste el tiempo para leer este texto :)
`
};
