export default {
    idea: 'Enviar una idea',
    ideaT: '¡El sistema está abierto a tus ideas y comentarios! Escríbelos en nuestro grupo de <a href="{{tg}}" target="_blank" rel="noreferrer">Telegram</a> o envíamelos en privado. <br/>Tu participación de cualquier forma ayudará a que el proyecto avance. ¡Gracias de antemano!',

    metaTitle: 'Comentarios',
    metaDesc: 'Informe de un problema con el servicio de control remoto de escritorio "Cusco Remote Control"',
    metaKeys: '',
    title: 'Comentarios',
    text: `
Grupo de Telegram para discusión: <a href="{{tg}}" target='_blank' rel='noreferrer'>cusco_rc_group</a><br/>
Se ha creado un hilo dedicado para cada tema:
<ul>
<li><a href="{{tgIdea}}" target='_blank' rel='noreferrer'>Ideas</a> - las características que te gustaría ver en el proyecto</li>
<br/><li><a href="{{tgBugs}}" target='_blank' rel='noreferrer'>Errores</a> - si encuentras un error o anticipas uno. Para errores relacionados con la seguridad, es mejor enviarlos en privado.</li>
<br/><li><a href="{{tgMain}}" target='_blank' rel='noreferrer'>General</a> - discusiones sobre temas generales, encuestas</li>
</ul>
`
};
