import './styles/main.scss'
import {observer} from "mobx-react";
import React, {lazy, Suspense} from "react";
import {BrowserRouter, Route, Router, Switch} from 'react-router-dom';
import MyHistory from './utils/myHistory';
import "./styles/overrides/material.scss";
import Layout from "./components/layout/layout";
import LANG, {LANGUAGES} from "./lang/language";
import {Redirect} from "react-router";

function Routes() {

    let lang = LANG.getInstance().lang;
    if(lang == 'en')
        lang = ''
    return <Router history={MyHistory}>
        <Suspense fallback={
            window.location.pathname.includes('/login') ?
                <div>Загрузка...</div> :
                <Layout>
                    <div className='w-100 h-100 f-c' style={{padding: '80px 0'}}>Загрузка...</div>
                </Layout>
        }>
            <BrowserRouter basename={`/${lang}`}>
                <Switch>

                    <MyRoute exact path="/" component={lazy(() => import('./pages/main/main'))}/>

                    <MyRoute exact path="/downloads"
                             component={lazy(() => import('./pages/downloads/downloads'))}/>

                    <MyRoute exact path="/features"
                             component={lazy(() => import('./pages/features/features'))}/>

                    <MyRoute exact path="/roadmap"
                             component={lazy(() => import('./pages/roadmap/roadmap'))}/>

                    <MyRoute exact path="/price"
                             component={lazy(() => import('./pages/price/price'))}/>

                    <MyRoute exact path="/donate"
                             component={lazy(() => import('./pages/donate/donate'))}/>

                    <MyRoute exact path="/feedback"
                             component={lazy(() => import('./pages/feedback/feedback'))}/>


                    <MyRoute path="/404" component={lazy(() => import('./pages/404'))}/>
                    <MyRoute path="/403" component={lazy(() => import('./pages/403'))}/>

                    {/*<Redirect from='*' to='/en'/>*/}

                    <Route path="/" component={lazy(() => import('./pages/404'))}/>

                    <Redirect from='*' to='/'/>
                </Switch>
            </BrowserRouter>
        </Suspense>
    </Router>
}

function MyRoute({component: Component, redirectTo, keyId, exact}: any) {
    /*const {lang} = rest.computedMatch.params;
    if (lang) {
        LANG.getInstance().selectLang(lang)
    } else {
        redirectTo = `/${LANG.getInstance().lang}${path}`
    }*/
    /* if (!redirectTo) {
         let pathFull = rest.location.pathname;
         let hasRight = true;

         if (!hasRight)
             redirectTo = '/403?from=' + pathFull;
     }*/
    return <Route
        exact={exact}
        render={(props) => {
            /* if (redirectTo) {
                 return <Redirect to={redirectTo}/>
             }*/
            return <Component key={keyId ? props.match.params[keyId] : undefined}  {...props} />
        }
        }
    />;
}

export default observer(Routes);
