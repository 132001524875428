import React, {useMemo, useState} from "react";
import styles from './headerMobile.module.scss';
import cn from 'classnames';
import {observer} from "mobx-react";
import RootStore from "../../stores/rootStore";
import {NavLink} from "react-router-dom";
import NavigationStore from "../../stores/navigationStore";
import MyImg from "../myImg/myImg";
import MyButton from "../myButton/myButton";
import Variables from "../../utils/variables";
import MyIcon from "../myIcon/myIcon";
import {getLanguageURL, reachGoal} from "../../utils/helper";
import LANG, {LANGUAGES} from "../../lang/language";
import MySelect from "../mySelect/mySelect";

function HeaderMobile(props) {
    let rootStore = RootStore.getInstance();
    const navigationStore = useMemo(() => new NavigationStore(), []);

    let menu: any = useMemo(() => navigationStore.getMenu(), []);
    let [open, setOpen] = useState(false)

    return <>
        <div className={cn('anim', styles.main, {
            [styles.open]: open,
            [styles.scrolled]: props.scrolled
        })}>
            <div className={cn('grid', styles.top)}>
                <div className={cn('col grid')}>
                    <NavLink className={cn('col-fixed grid custom', styles.logo)} to={'/'}>
                        <div className={cn('col-fixed grid f-c', styles.img)}>
                            <MyImg src='logo_60.png'/>
                        </div>
                        <div className={cn('col-fixed grid f-c-l', styles.text)}>
                            Cusco Remote Control
                        </div>
                    </NavLink>
                </div>
                <div className={cn('col-fixed grid f-c ns', styles.menuButton)}>
                    <MyIcon iconM={open ? 'close' : 'menu'} onClick={_ => setOpen(!open)}/>
                </div>
            </div>
            {
                open && <div className={cn('col', styles.menu)}>
                    <div className={cn('col-fixed grid f-c-r')}>
                        <MySelect options={LANGUAGES} selected={LANG.getInstance().lang} className={styles.lang} icon={'translate'}
                                  getName={(el) => `${el.name} (${el.nameEn})`}
                                  onSelect={value => {
                                      LANG.getInstance().selectLang(value)
                                  }}
                                  getHref={(el) => {
                                      let url = new URL(window.location.href);
                                      let languageURL = getLanguageURL();
                                      let newLanguage  = el.value;
                                      if (newLanguage === 'en') {
                                          url.pathname = url.pathname.replace('/' + languageURL, '');
                                      } else {
                                          if(languageURL == '')
                                              newLanguage = newLanguage + '/'
                                          url.pathname = url.pathname.replace('/' + languageURL, '/' + newLanguage);
                                      }
                                      return `${url}`
                                  }}/>
                    </div>
                    {
                        menu.map(el => <div key={el.name} className={cn('grid', styles.menuItem)}>
                            <NavLink id={el.name} to={el.link} exact={el.exact} activeClassName={styles.selected}
                                     className={cn('col-fixed grid custom', styles.link)}
                                     isActive={(match, location) => {
                                         return navigationStore.isActiveLink(el, location);
                                     }}>
                                <div className={cn('col-fixed f-c', styles.text)}>
                                    {el.name}
                                </div>

                            </NavLink>
                        </div>)
                    }
                    <div className={cn('col-fixed f-c-r', styles.buttons)}>
                        <MyButton text={LANG.get(['menu', 'panel'])} hrefFull={Variables.urlPanel}
                                  onClick={() => reachGoal('control_panel')}/>
                    </div>
                </div>
            }
        </div>
    </>
}

export default observer(HeaderMobile);
