export default {
    metaTitle: '远程访问功能：控制、监控、统计、库存、服务器和计算机管理',
    metaDesc: '用于系统管理员和普通用户进行远程计算机控制的专业服务。超越外国远程访问程序的替代方案。积极开发和实施用户请求的功能。进程管理、程序移除、远程命令执行、工作站库存、设备查看、电源管理。',
    metaKeys: '免费的进程管理程序、程序移除、远程命令执行、工作站库存、设备查看、电源管理程序',
    title: '系统功能',
    startUse: '开始使用',
    remoteControl: '远程控制',
    remoteControlT: '标准远程桌面控制。类似于TeamViewer、AnyDesk、Ammyy Admin。<br/><br/>您可以通过浏览器从计算机或手机连接。',
    invent: '工作站库存',
    inventT: '需要一个库存，而您不想连接到每台机器上？<br/><br/>只需在控制面板中检查所有设备的特性即可。',
    process: '进程',
    processT: '您可以随时查看和过滤工作站上运行的所有进程，并在需要时手动终止它们。',
    programs: '程序和设备',
    programsT: '检查所有已安装的应用程序，并根据需要从设备中移除。<br/><br/>想准确知道安装了哪些设备？没问题。',
    remoteCommand: '远程命令执行',
    remoteCommandT: '有些情况下，您只需要执行一个命令来解决问题或找出某些信息，而不想连接。您可以按下一个按钮发送命令并获得执行结果。<br/><br/>电源管理同样简单。只需点击几下即可重新启动、锁定或使工作站进入睡眠状态。',
    feature: '未来功能列表',
    idea: '提交一个想法',
    ideaT: '系统欢迎您的想法和反馈！在我们的Telegram群组中写下您的想法 <a href="{{tg}}" target="_blank" rel="noreferrer">这里</a> 或直接发送给我私信。<br/>无论以哪种形式参与，都将有助于推动项目的进展。提前感谢您的参与！',
};
