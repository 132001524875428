export default {
    metaTitle: 'Kosten der Dienstleistungen',
    metaDesc: 'Wie viel kostet das Fernsteuerungs- und Überwachungssystem?',
    metaKeys: 'Fernsteuerung im Unternehmen, Preis für Computerverwaltung, Mitarbeiterüberwachung, kostenlose Fernsteuerung',
    title: 'Kosten',
    titleD: 'ab 20 Cent pro Station pro Monat',
    text: `
 <p>
 Der Service ermöglicht es den Benutzern, ihren eigenen Preis festzulegen: von 20 Cent bis 10 US-Dollar, wobei 5 Stationen kostenlos zur Verfügung gestellt werden.
 </p>
 <p>
 Diese Option dient auch als Bewertung der Idee hinter diesem Projekt. Je höher der angegebene Betrag, desto besser wird dieses Projekt. Es wird leistungsstärkere Server, weniger Fehler, eine benutzerfreundlichere Benutzeroberfläche und schnellere Interaktionen haben. Am wichtigsten ist, dass neue Funktionen eingeführt werden, um die Verwaltung der Stationen für Sie zu erleichtern.
 </p>
`
};
