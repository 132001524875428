import React from "react";
import styles from './footer.module.scss';

function Footer() {
    return <div className={styles.main}>

    </div>
}

export default Footer
