export default {
    idea: 'Idee einreichen',
    ideaT: 'Das System steht Ihren Ideen und Rückmeldungen offen gegenüber! Schreiben Sie darüber in unserer Gruppe auf <a href="{{tg}}" target="_blank" rel="noreferrer">Telegram</a> oder senden Sie sie mir privat. <br/>Ihre Beteiligung in jeder Form wird dazu beitragen, dass das Projekt vorankommt. Vielen Dank im Voraus!',

    metaTitle: 'Feedback',
    metaDesc: 'Melden Sie ein Problem mit dem Desktop-Fernsteuerungsdienst "Cusco Remote Control"',
    metaKeys: '',
    title: 'Feedback',
    text: `
Telegram-Gruppe für Diskussionen: <a href="{{tg}}" target='_blank' rel='noreferrer'>cusco_rc_group</a><br/>
Für jedes Thema wurde ein eigener Thread erstellt:
<ul>
<li><a href="{{tgIdea}}" target='_blank' rel='noreferrer'>Ideen</a> - die Funktionen, die Sie im Projekt sehen möchten</li>
<br/><li><a href="{{tgBugs}}" target='_blank' rel='noreferrer'>Fehler</a> - wenn Sie einen Fehler feststellen oder einen solchen vermuten. Für sicherheitsrelevante Fehler ist es besser, sie mir privat zu senden.</li>
<br/><li><a href="{{tgMain}}" target='_blank' rel='noreferrer'>Allgemein</a> - Diskussionen zu allgemeinen Themen, Umfragen</li>
</ul>
`
};
