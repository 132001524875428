export default {
    metaTitle: 'Спонсировать проект',
    metaDesc: 'Возможность поучаствовать в развитии проекта удаленного управления и мониторинга компьютеров',
    metaKeys: 'Спонсировать проект, поддержка проекта',
    title: 'Поддержка проекта',
    text: `
 <p>
 
В течении многих месяцев я разрабатываю, поддерживаю и финансирую проект
один, однако постепенно сталкиваюсь с
определенными проблемами, требующими финансовой поддержки.

</p>
<p>
 
Если у вас есть возможность - окажите помощь сервису, мне будет очень приятно! 
 Независимо от пожертвованной суммы - это будет значить что вы считаете мой проект нужным 
 и так же как и я верите в его развитие. Эта поддержка существенно усилит мою мотивацию.

</p>
<p>
<div class="{{subtitle1}}">
 
Как перевести деньги

</div>
 
Так как я придерживаюсь ценностей и взглядов криптоанархизма,
в наличии у меня только криптокошельки (инструкцию как пополнить их с
банковской карты написал ниже)

<div class="{{code}}">
USDT <span class="{{green}}">bep20 (Binance)</span> : 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
BTC : bc1qyz2gken4mxgfquqfmvrevyttycdxtp349ve7kz <br/><br/>
ETH : 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
DOGE : D8aQgYPjkpN92AJiRokhHaSWw9LBG9g6xT
</div>

<div class="{{subtitle2}}"> Инструкция</div>
<ul class="{{instruction}}">
<li> Напрямую - если у вас есть электронный кошелек с USDT bep20 монетами.</li>
<li> Через обменник, выбрать его например здесь <a href=https://www.bestchange.ru/sberbank-to-tether-bep20.html target=_blank rel=noreferrer>bestchange.ru</a>:
<ul>
<li> Перейти на страницу мониторинга обменников</li>
<li> В колонке отдаете - выберите способ оплаты подходящий вам, к примеру сбербанк</li>
<li> В колонке получаете - должно стоять Tether bep20</li>
<li> Выбираете обменник с лучшим курсом или условиями, например 
<a href=https://ychanger.net/obmen-sberbank-na-usdt-bep20.html target=_blank rel=noreferrer>YChanger</a></li>
<li> На сайте обменника указываете введенную вами сумму для пополнения в долларах,
копируете адрес для пополнения 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 , заполняете остальные поля и переводите сумму 
 по указанным реквизитам</li>
<li> В течении 20 минут транзакция обрабатывается обменником и сетью</li>
</ul>
</li>
</ul>

Если возникнут проблемы - пишите мне в <a href="{{tgMy}}" target=_blank rel=noreferrer>телеграмм</a>, будем разбираться.

</p>
<p>
<div class="{{subtitle1}}">
 
На что пойдут средства

</div>
 
Покупка необходимой техники, оплата аренды сервера, оплата сервисов помогающих в разработке, 
тестирование, дизайн, печеньки.

<br/>
<br/>
 
Если вдруг сумма окажется больше зарплаты за пару месяцев - смогу нанять помощника и существенно
ускорить выход новых версий.

</p>


<div class="{{subtitle1}}">
Заранее большое спасибо за поддержку! 
</div>
Даже если вы просто уделили время и прочитали этот текст :)<br/>
`
};
