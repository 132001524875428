export default {
    metaTitle: 'Unterstützen Sie das Projekt',
    metaDesc: 'Möglichkeit zur Beteiligung an der Entwicklung des Projekts zur Fernsteuerung und Computerüberwachung',
    metaKeys: 'Unterstützen Sie das Projekt, Projektunterstützung',
    title: 'Projektunterstützung',
    text: `
 <p>
 Seit vielen Monaten entwickle, pflege und finanziere ich dieses Projekt allein. Allerdings stoße ich allmählich auf bestimmte Probleme, die finanzielle Unterstützung erfordern.
 </p>
 <p>
 Wenn Sie die Möglichkeit haben, unterstützen Sie bitte den Service. Ich wäre sehr dankbar! Unabhängig von der gespendeten Summe bedeutet dies, dass Sie mein Projekt wertvoll finden und an dessen Entwicklung glauben, genauso wie ich. Diese Unterstützung wird meine Motivation erheblich stärken.
 </p>
 <p>
 <div class="{{subtitle1}}">
 Wie man Geld sendet
 </div>
 Da ich den Werten und Ansichten des Krypto-Anarchismus folge, besitze ich nur Kryptowährungs-Wallets. Unten finden Sie eine Anleitung, wie Sie sie von einer Bankkarte aus finanzieren können:
 <div class="{{code}}">
USDT <span class="{{green}}">bep20 (Binance)</span>: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
BTC: bc1qyz2gken4mxgfquqfmvrevyttycdxtp349ve7kz <br/><br/>
ETH: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
DOGE: D8aQgYPjkpN92AJiRokhHaSWw9LBG9g6xT
</div>
<div class="{{subtitle2}}">Anleitung</div>
<ul class="{{instruction}}">
<li> Direkt - wenn Sie eine E-Wallet mit USDT bep20 Coins haben.</li>
<li> Über einen Umtauschdienst. Wählen Sie hier einen aus, zum Beispiel <a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html" target=_blank rel=noreferrer>bestchange.com</a>:
<ul>
<li> Gehen Sie zur Überwachungsseite des Umtauschdienstes</li>
<li> Wählen Sie in der Spalte "Gibt" eine geeignete Zahlungsmethode wie "Visa/Mastercard". Ich empfehle Ihnen, in dieser Spalte die Zahlungsmethode mit dem niedrigsten Zinssatz für Ihr Land zu wählen (etwa 3-5 %), in der Regel eine der beliebten Banken in der Landeswährung, und nicht eine direkte Überweisung in USD.
<li> In der Spalte "Empfängt" sollte Tether bep20 stehen</li>
<li> Wählen Sie einen Umtauschdienst mit dem besten Kurs oder den besten Bedingungen</li>
<li> Geben Sie auf der Website des Umtauschdienstes den Betrag ein, den Sie finanzieren möchten, in US-Dollar, kopieren Sie die Auffülladresse 0x1716a9bAaE728780D98fb26faAe17D79FB62F090, füllen Sie die übrigen Felder aus und überweisen Sie den Betrag gemäß den angegebenen Details</li>
<li> Die Transaktion wird innerhalb von 20 Minuten vom Umtauschdienst und dem Netzwerk bearbeitet</li>
</ul>
</li>
</ul>
Wenn Sie auf Probleme stoßen, schreiben Sie mir bitte auf <a href="{{tgMy}}" target=_blank rel=noreferrer>Telegram</a>, und wir werden es gemeinsam lösen.
</p>
<p>
<div class="{{subtitle1}}">
 Wie die Mittel verwendet werden
 </div>
 Kauf notwendiger Ausrüstung, Zahlung der Servermiete, Zahlung von Entwicklungshilfe, Tests, Design, Cookies.
 <br/>
 <br/>
 Wenn der Betrag ein paar Monatsgehälter übersteigt, werde ich in der Lage sein, einen Assistenten einzustellen und die Veröffentlichung neuer Versionen erheblich zu beschleunigen.
</p>
<div class="{{subtitle1}}">
 Vielen Dank im Voraus für Ihre Unterstützung!
 </div>
 Selbst wenn Sie sich einfach die Zeit genommen haben, diesen Text zu lesen :)
`
};
