import {action, makeObservable, observable} from "mobx";
import {StorageService} from "../utils/storage.service";
import {getLanguageURL} from "../utils/helper";

export const LANGUAGES = [
    {name: "English", value: "en", selectedName: "EN", nameEn: "English"},
    {name: "Español", value: "es", selectedName: "ES", nameEn: "Spanish"},
    {name: "Русский", value: "ru", selectedName: "RU", nameEn: "Russian"},
    {name: "中文", value: "zh", selectedName: "ZH", nameEn: "Chinese"},
    {name: "Français", value: "fr", selectedName: "FR", nameEn: "French"},
    {name: "Deutsch", value: "de", selectedName: "DE", nameEn: "German"},
    {name: "日本語", value: "ja", selectedName: "JA", nameEn: "Japanese"},
    {name: "Português", value: "pt", selectedName: "PT", nameEn: "Portuguese"},
    {name: "العربية", value: "ar", selectedName: "AR", nameEn: "Arabic"},
    {name: "हिन्दी", value: "hi", selectedName: "HI", nameEn: "Hindi"},
    {name: "한국어", value: "ko", selectedName: "KO", nameEn: "Korean"},
    {name: "Italiano", value: "it", selectedName: "IT", nameEn: "Italian"},
];

class LANG {
    static classInstance: LANG = null;

    @observable lang: string;

    @observable values: any = {};

    constructor() {
        makeObservable(this);
        this.initLang()
    }

    static getInstance() {
        if (this.classInstance === null) {
            this.classInstance = new this();
        }
        return this.classInstance;
    }

    onMount() {

    }

    onUmount() {

    }

    initLang() {
        let savedLang = StorageService.getItem('lang')

        let languageURL = getLanguageURL()

        if (languageURL) {
            this.selectLang(languageURL, false)
        } else if (savedLang) {
            this.selectLang(savedLang)
        } else {
            let userLang = navigator.language.split('-')[0];
            this.selectLang(userLang)
        }
    }

    @action
    selectLang(value, save = true) {
        if (!LANGUAGES.find(el => el.value == value)) {
            value = 'en'
        }
        this.lang = value
        if (save)
            StorageService.setItem('lang', value)
        this.values = {}
    }

    @action
    static langURL() {
        let instance = this.getInstance()
        return instance.lang == 'en' ? '' : instance.lang
    }

    @action
    static getSpan(paths, replace?) {
        return <span dangerouslySetInnerHTML={{
            __html: this.get(paths, replace)
        }}></span>
    }

    @action
    static get(paths, replace?) {
        let instance = this.getInstance()
        let first = paths[0];
        if (!instance.values.hasOwnProperty(first)) {
            instance.values[first] = require(`./${instance.lang}/${first}`).default
        }
        let result = instance.values[first]
        for (let i = 1; i < paths.length; i++) {
            let name = paths[i]
            result = result[name]
            if (i == paths.length - 1) {
                if (result.constructor.name == 'String' && replace) {
                    let keys = Object.keys(replace)
                    for (const key of keys) {
                        let value = replace[key]
                        result = result.replaceAll(`{{${key}}}`, value)
                    }
                }
            }
        }
        return result
    }
}

export default LANG;