export default {
    metaTitle: 'プロジェクトのロードマップ',
    metaDesc: 'プロジェクトで既に実装されたタスクとまだ順番を待っているタスク',
    metaKeys: 'プロセス管理、プログラム削除、リモートコマンドの実行、ステーションの在庫、デバイスの表示、電源管理のための無料プログラム',
    title: 'ロードマップ',
    titleD: '実装済みと将来の機能のリスト',

    showDone: '完了済みを表示',
    hideDone: '完了済みを非表示',
    showMore: '詳細を表示',
    inQuery: 'キュー中',
    hide: '非表示',
    more: '詳細',
    needMoney: '資金が必要',
    "task0": "ステーションリスト、新規追加",
    "task1": "ステーションの一般情報",
    "task2": "プロセス。管理と表示",
    "task3": "プログラム。管理と表示",
    "task4": "デバイス。表示",
    "task5": "リモートコマンドの実行",
    "task6": "電源管理",
    "task7": "コマンド履歴",
    "task8": "Windowsのビジュアルコントロール",
    "task9": "Mac用ビルド",
    "task9Desc":`
        <p>
        私は長年Windowsで働いており、最後のMacを5年前に売却しましたので、
        仮想マシンでビルドを試みましたが、正常に起動しましたが、プロジェクトをビルドすることができませんでした
        アクションへの反応時間は約3〜5秒かかり、vmwareとvirtualboxの両方に対して同じです。
        2日間、すべての可能な設定を試しましたが、結果は同じです。ただし、このシステムでのみ開発することができ、
        WindowsやLinuxではできません。
        </p>
        <p>
        私は、このシステム上で少なくとも中程度のパフォーマンスを持つデバイスを購入する必要があると結論づけました。
        サポートしていただける場合は、プロジェクトに感謝いたします。必要な金額が集まり次第、
        現在のタスクを完了し、このタスクに取り組む予定です。
        <a href="{{lang}}/donate">こちらでサポートを提供できます</a>
        </p>`,
    "task10": "ユーザーアカウント",
    "task11": "外国語への翻訳",
    "task12": "二要素認証",
    "task13": "リモートコマンドライン",
    "task13Desc": `<p>ブラウザ上でのSSHクライアントと同様のものです。</p>`,
    "task14": "MSIインストーラ",
    "task15": "グラフ上でのプロセス監視",
    "task16": "APIドキュメント",
    "task17": "クライアントのソースコードの公開",
    "task18": "ディスクスペース管理",
    "task19": "ログ表示",
    "task20": "一時的なアクセス",
    "task21": "モバイルアプリ",
    "task22": "ポートフォワーディング",
    "task23": "ユーザー生成プログラムの生成",
    "task23Desc": `<p>プログラムの起動ファイルが作成され、ユーザーがそれをデバイスで開くと、ファイルを送信したユーザーが許可リストに追加されます。</p>`,
    "task24": "クライアントでの管理機能",
    "task24Desc": `<p>ブラウザを使用せずに、このステーションまたは他のステーションのコントロールパネルにアクセスできる機能。</p>`,
    "task25": "表示と制御の権限の分離"
};
