export default {
    metaTitle: 'Scarica il programma di accesso remoto. Windows, Linux, MacOS.',
    metaDesc: 'Scarica l\'ultima versione per avere il pieno controllo dei PC e dei server.',
    metaKeys: 'scarica cusco, cusco win, cusco linux, cusco macos',
    title: 'Scegli la tua piattaforma',
    download: 'Scarica',
    version: 'Versione',
    versions: 'Versioni',
    versionsT: `Ci sono due versioni del programma:
 <ul>
 <li>Con un'interfaccia grafica - più grande in dimensioni ma più comoda da usare</li>
 <li>Con un'interfaccia a riga di comando - più piccola in dimensioni, funziona nella console e utilizza la tastiera per il controllo.
 Versione preferita se si dispone di una vecchia versione del sistema o se l'interfaccia grafica non funziona.
 </li>
 </ul>`,

    install: 'Installazione',
    installTWin: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>Scarica</a> e avvia il programma.<br/>
 2. Registrati nel <a href="{{panel}}" target='_blank' rel='noreferrer'>pannello di controllo</a>.<br/>
 3. Aggiungi una stazione specificando l'ID e la password forniti nel programma sul dispositivo.<br/>
 4. Inizia il controllo!
 <br/><br/>`,
    installTLinux: `
 1. Scarica il programma usando il comando:<br/>
 <div class="{{sCode}}">
 cd ~<br/>
 wget {{installLink}}<br/>
 </div>
 2. Esegui il programma:<br/>
 <div class="{{sCode}}">
 sudo chmod +x {{fileName}}<br/>
 sudo ./{{fileName}}<br/>
 </div>

 3. Registrati nel <a href="{{panel}}" target='_blank' rel='noreferrer'>pannello di controllo</a>.<br/>
 4. Aggiungi una stazione specificando l'ID e la password forniti nel programma sul dispositivo.<br/>
 5. Inizia il controllo!

 <br/><br/>
 Nota: Durante l'installazione viene creato un servizio che si avvia quando il computer viene acceso.<br/>
 Puoi aprire il programma nella console utilizzando il comando "cusco".<br/>
 Per ottenere una lista di tutti i comandi, utilizza "cusco --help".`,
    inProgress: `In corso`,
    installTMac: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>Scarica</a> e avvia il programma.<br/>
 2. Registrati nel <a href="{{panel}}" target='_blank' rel='noreferrer'>pannello di controllo</a>.<br/>
 3. Aggiungi una stazione specificando l'ID e la password forniti nel programma sul dispositivo.<br/>
 4. Inizia il controllo!
 <br/><br/>`,
    mainCommands: 'Comandi principali',
    mainCommandsT: `<ul>
 <li>-i o --install - installazione rapida</li>
 <li>-u o --uninstall - disinstallazione rapida</li> 
 <li>-h o --help - aiuto</li> 
 <li>--update - aggiornamento all'ultima versione</li> 
 </ul>
 Nella versione grafica, il comando verrà eseguito, ma non verrà visualizzato alcun output nella console (a causa delle peculiarità della build).
`,
    feedbackTitle: `Il tuo feedback è molto importante!`,
    feedbackBug: `
 Se hai riscontrato un bug o il programma non funziona correttamente, ti preghiamo di scriverlo nella chat
 <a href="{{bugs}}" target='_blank' rel='noreferrer'>su Telegram</a>
 nella sezione "Bug". Cercherò di risolvere il problema il prima possibile.
 `,
    feedbackIdea: `
 Se il programma non soddisfa una delle tue esigenze o se hai qualche idea interessante,
 ti preghiamo di farcelo sapere nel
 <a href="{{ideas}}" target='_blank'
  rel='noreferrer'>gruppo</a> nella sezione "Idee".
  `,
};
