export default {
    metaTitle: 'Coût des services',
    metaDesc: 'Combien coûte le système de contrôle et de surveillance à distance ?',
    metaKeys: 'Contrôle à distance en entreprise, prix de gestion des ordinateurs, surveillance des employés, contrôle à distance gratuit',
    title: 'Coût',
    titleD: 'à partir de 20 centimes par station par mois',
    text: `
 <p>
 Le service permet aux utilisateurs de fixer leur propre prix : de 20 centimes à 10 dollars, avec 5 stations gratuites fournies.
 </p>
 <p>
 Cette option sert également d'évaluation de l'idée derrière ce projet. Plus le montant spécifié est élevé, meilleur sera ce projet. Il disposera de serveurs plus puissants, moins de bugs, une interface utilisateur plus conviviale et des interactions plus rapides. Surtout, de nouvelles fonctionnalités seront introduites pour faciliter la gestion des stations.
 </p>
`
};
