export default {
    metaTitle: 'रिमोट एक्सेस प्रोग्राम डाउनलोड करें। Windows, Linux, MacOS।',
    metaDesc: 'पीसी और सर्वरों पर पूर्ण नियंत्रण के लिए नवीनतम संस्करण डाउनलोड करें।',
    metaKeys: 'कस्को डाउनलोड करें, कस्को विन्डोज, कस्को लिनक्स, कस्को मैकोएस',
    title: 'अपना प्लेटफॉर्म चुनें',
    download: 'डाउनलोड',
    version: 'संस्करण',
    versions: 'संस्करण',
    versionsT: `प्रोग्राम के दो संस्करण हैं:
 <ul>
 <li>ग्राफिकल इंटरफेस के साथ - साइज बड़ा होता है लेकिन उपयोग में आसानी होती है</li>
 <li>टर्मिनल इंटरफेस के साथ - साइज छोटा होता है, कंसोल में काम करता है और कंट्रोल के लिए कीबोर्ड का उपयोग करता है।
 पुराने संस्करण के सिस्टम होने की अवस्था में या ग्राफिकल इंटरफेस काम नहीं करता होने की स्थिति में पसंदीदा संस्करण।
 </li>
 </ul>`,

    install: 'स्थापना',
    installTWin: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>प्रोग्राम डाउनलोड</a> करें और चलाएं।<br/>
 2. <a href="{{panel}}" target='_blank' rel='noreferrer'>कंट्रोल पैनल</a> में पंजीकरण करें।<br/>
 3. उपकरण पर प्रोग्राम में दिए गए आईडी और पासवर्ड को निर्दिष्ट करके स्टेशन जोड़ें।<br/>
 4. नियंत्रण शुरू करें!
 <br/><br/>`,
    installTLinux: `
 1. निम्नलिखित कमांड का उपयोग करके प्रोग्राम डाउनलोड करें:<br/>
 <div class="{{sCode}}">
 cd ~<br/>
 wget {{installLink}}<br/>
 </div>
 2. प्रोग्राम चलाएं:<br/>
 <div class="{{sCode}}">
 sudo chmod +x {{fileName}}<br/>
 sudo ./{{fileName}}<br/>
 </div>

 3. <a href="{{panel}}" target='_blank' rel='noreferrer'>कंट्रोल पैनल</a> में पंजीकरण करें।<br/>
 4. उपकरण पर प्रोग्राम में दिए गए आईडी और पासवर्ड को निर्दिष्ट करके स्टेशन जोड़ें।<br/>
 5. नियंत्रण शुरू करें!

 <br/><br/>
 ध्यान दें: स्थापना के दौरान एक सेवा बनाई जाती है जो कंप्यूटर चालू होने पर स्वचालित रूप से स्टार्ट होती है।<br/>
 आप "cusco" कमांड का उपयोग करके कंसोल में प्रोग्राम खोल सकते हैं।<br/>
 सभी कमांडों की सूची प्राप्त करने के लिए "cusco --help" का उपयोग करें।`,
    inProgress: `कार्यान्वयन में`,
    installTMac: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>प्रोग्राम डाउनलोड</a> करें और चलाएं।<br/>
 2. <a href="{{panel}}" target='_blank' rel='noreferrer'>कंट्रोल पैनल</a> में पंजीकरण करें।<br/>
 3. उपकरण पर प्रोग्राम में दिए गए आईडी और पासवर्ड को निर्दिष्ट करके स्टेशन जोड़ें।<br/>
 4. नियंत्रण शुरू करें!
 <br/><br/>`,
    mainCommands: 'मुख्य कमांड',
    mainCommandsT: `<ul>
 <li>-i या --install - त्वरित स्थापना</li>
 <li>-u या --uninstall - त्वरित अनइंस्टॉल</li> 
 <li>-h या --help - सहायता</li> 
 <li>--update - नवीनतम संस्करण में अपडेट करें</li> 
 </ul>
 ग्राफिकल संस्करण में, कमांड को क्रियान्वित किया जाएगा, लेकिन कंसोल में कोई आउटपुट नहीं होगा (निर्माण की विशेषताओं के कारण)।
`,
    feedbackTitle: `आपकी प्रतिक्रिया बहुत महत्वपूर्ण है!`,
    feedbackBug: `
 यदि आपने कोई बग देखा है या प्रोग्राम आपके लिए काम नहीं कर रहा है, कृपया चैट में लिखें
 <a href="{{bugs}}" target='_blank' rel='noreferrer'>Telegram पर</a>
 "बग्स" खंड में। मैं जितनी जल्दी हो सके समस्या का समाधान करने की कोशिश करूंगा।
 `,
    feedbackIdea: `
 यदि प्रोग्राम आपकी कोई भी आवश्यकताओं को पूरा नहीं करता है या यदि आपके पास कोई रोचक विचार है,
 कृपया मुझे जानेंगे
 <a href="{{ideas}}" target='_blank'
  rel='noreferrer'>समूह</a> में "विचार" खंड में।
  `,
};
