export default {
    metaTitle: 'Fernzugriffs- und Serververwaltung. Überwachung. Fernbefehlsausführung.',
    metaDesc: 'Das beste Programm für den Fernzugriff auf Computer. Fernsteuerung von PCs oder Servern.',
    metaKeys: 'Kostenloser Fernzugriff, Alternative zu TeamViewer, Alternative zu AnyDesk, Fernzugriffsprogramm',
    title: 'Verbinden Sie sich jetzt mit den Stationen',
    titleD: 'Vollständige Kontrolle über Ihre Geräte',

    showDone: 'Abgeschlossene anzeigen',
    hideDone: 'Abgeschlossene ausblenden',
    showMore: 'Details anzeigen',
    inQuery: 'In Warteschlange',
    hide: 'Ausblenden',
    more: 'Mehr',
    needMoney: 'Finanzierung benötigt',
    "task0": "Liste der Stationen, Hinzufügen einer neuen",
    "task1": "Allgemeine Informationen über die Station",
    "task2": "Prozesse. Verwaltung und Anzeige",
    "task3": "Programme. Verwaltung und Anzeige",
    "task4": "Geräte. Anzeige",
    "task5": "Fernbefehlsausführung",
    "task6": "Energieverwaltung",
    "task7": "Befehlshistorie",
    "task8": "Verwaltung der visuellen Fenster",
    "task9": "Build für Mac",
    "task9Desc":
        '<p>' +
        'Da ich seit vielen Jahren mit Windows arbeite und meinen letzten Mac vor fünf Jahren verkauft habe, ' +
        'habe ich versucht, es in einer virtuellen Maschine zu erstellen. Es hat perfekt funktioniert, aber ich konnte das Projekt ' +
        'nicht erstellen, da die Reaktionszeit für Aktionen etwa 3-5 Sekunden betrug, unabhängig davon, ob es sich um VMware oder VirtualBox handelte. ' +
        'Ich habe alle möglichen Einstellungen für ein paar Tage ausprobiert, aber das Ergebnis war dasselbe. ' +
        'Es ist nur möglich, für dieses Betriebssystem auf demselben Betriebssystem zu entwickeln. Es ist unmöglich, dies unter Windows oder Linux zu tun.' +
        '</p>' +
        '<p>' +
        'Ich bin zu dem Schluss gekommen, dass ich ein Gerät mit zumindest mittlerer Leistung für dieses System kaufen muss. ' +
        'Wenn Sie können, unterstützen Sie bitte das Projekt; ich wäre sehr dankbar. Sobald der erforderliche Betrag gesammelt ist, ' +
        'werde ich die aktuelle Aufgabe abschließen und an dieser arbeiten. ' +
        ' <a href="{{lang}}/donate">Hier können Sie unterstützen</a>' +
        '</p>',
    "task10": "Benutzer-Dashboard",
    "task11": "Übersetzung in Fremdsprachen",
    "task12": "Zwei-Faktor-Authentifizierung",
    "task13": "Remote-Befehlszeile",
    "task13Desc": `<p>Ähnlich wie ein SSH-Client, aber im Browser.</p>`,
    "task14": "Installer im MSI-Format",
    "task15": "Prozessverfolgung in einem Diagramm",
    "task16": "API-Dokumentation",
    "task17": "Öffnen des Client-Quellcodes",
    "task18": "Speicherplatzverwaltung",
    "task19": "Log-Anzeige",
    "task20": "Temporärer Zugriff",
    "task21": "Mobile Anwendung",
    "task22": "Portweiterleitung",
    "task23": "Benutzergenerierte Programmgenerierung",
    "task23Desc": `<p>Es wird eine Startdatei erstellt, und wenn der Empfänger sie auf seinem Gerät öffnet, wird der Benutzer, der die Datei gesendet hat, zur erlaubten Liste hinzugefügt.</p>`,
    "task24": "Verwaltungsfunktionen im Client",
    "task24Desc": `<p>Die Möglichkeit, auf das Kontrollfeld dieser Station oder einer anderen zuzugreifen, ohne einen Browser zu verwenden.</p>`,
    "task25": "Unterscheidung von Zugriffs- und Steuerungsberechtigungen"
};
