export default {
    metaTitle: '프로젝트 로드맵',
    metaDesc: '프로젝트에서 이미 구현된 작업과 아직 처리되지 않은 작업',
    metaKeys: '프로세스 관리, 프로그램 삭제, 원격 명령 실행, 스테이션 인벤토리, 디바이스 보기, 전원 관리를 위한 무료 프로그램',
    title: '로드맵',
    titleD: '구현된 및 예정된 기능 목록',

    showDone: '완료된 항목 표시',
    hideDone: '완료된 항목 숨기기',
    showMore: '자세한 내용 표시',
    inQuery: '대기열에 있음',
    hide: '숨기기',
    more: '더 보기',
    needMoney: '자금 필요',
    "task0": "스테이션 목록, 새로 추가",
    "task1": "스테이션 개요 정보",
    "task2": "프로세스 관리 및 보기",
    "task3": "프로그램 관리 및 보기",
    "task4": "디바이스 보기",
    "task5": "원격 명령 실행",
    "task6": "전원 관리",
    "task7": "명령 실행 기록",
    "task8": "Windows용 시각적 제어",
    "task9": "Mac용 빌드",
    "task9Desc":`
        <p>
        저는 여러 해 동안 Windows에서 작업하고 마지막 Mac은 다섯 년 전에 팔았으므로,
        가상 머신에서 빌드를 시도해 보았지만 작업을 빌드하지 못했습니다.
        동작에 대한 응답 시간은 약 3-5초가 걸리며, vmware와 virtualbox 모두에 대해 동일합니다.
        며칠 동안 가능한 모든 설정을 시도해 보았지만 결과는 같습니다. 그러나이 시스템에 대해서만
        이 시스템에서만 개발할 수 있으며 Windows 또는 Linux에서는 할 수 없습니다.
        </p>
        <p>
        이 시스템에서 중간 정도의 성능을 갖춘 장치를 구입해야 한다는 결론에 도달했습니다.
        지원해주시면 감사하겠습니다. 필요한 금액을 모으면 현재 작업을 완료하고 해당 작업에 착수할 것입니다.
        <a href="{{lang}}/donate">여기에서 지원할 수 있습니다</a>
        </p>`,
    "task10": "사용자 계정",
    "task11": "외국어로의 번역",
    "task12": "이중 인증",
    "task13": "원격 명령 줄",
    "task13Desc": `<p>브라우저에서 SSH 클라이언트와 유사한 기능입니다.</p>`,
    "task14": "MSI 설치 파일",
    "task15": "그래프 상에서 프로세스 모니터링",
    "task16": "API 문서",
    "task17": "클라이언트의 소스 코드 공개",
    "task18": "디스크 공간 관리",
    "task19": "로그 보기",
    "task20": "임시 액세스",
    "task21": "모바일 애플리케이션",
    "task22": "포트 포워딩",
    "task23": "사용자 생성 프로그램 생성",
    "task23Desc": `<p>프로그램 실행 파일이 생성되고 사용자가 해당 파일을 기기에서 열 때 파일을 보낸 사용자가 허용 목록에 추가됩니다.</p>`,
    "task24": "클라이언트에서의 관리 기능",
    "task24Desc": `<p>브라우저를 사용하지 않고 이 스테이션이나 다른 스테이션의 제어판에 액세스할 수 있는 기능입니다.</p>`,
    "task25": "보기 및 제어 권한 구분"
};
