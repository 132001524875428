export default {
    metaTitle: 'Fernwartung von Computern und Servern. Überwachung. Ausführung von Remote-Befehlen.',
    metaDesc: 'Das beste Programm für den Remote-Zugriff auf Computer. Fernsteuerung von PCs oder Servern.',
    metaKeys: 'Kostenloser Remote-Zugriff, Teamviewer-Alternative, AnyDesk-Alternative, Remote-Zugriffsprogramm',
    title: 'Jetzt zu den Stationen verbinden',
    titleD: 'Volle Kontrolle über Ihre Geräte übernehmen',
    download: 'Herunterladen',
    possibilities: 'Möglichkeiten',
    possibilitiesF: 'Zukünftige Möglichkeiten',
    inv: 'Stationeninventar',
    vis: 'Visuelle Steuerung <br/>wie Teamviewer/AnyDesk',
    manage: 'Verwaltung und Anzeige:',
    proc: 'Laufende Prozesse',
    prog: 'Programme',
    dev: 'Geräte',
    commands: 'Befehle',
    power: 'Energiemanagement',
    comDo: 'Befehlsausführung',
    launch: 'Starten ohne Installation',
    security: 'Sicherheit',
    onlyYou: 'Nur Sie können Befehle an Ihre Stationen senden',
    itIsAKey: 'Alles dank eines eindeutigen Schlüssels, der bei Ihnen gespeichert ist',
    nobodyCan: 'Weder der Service noch jemand anders kann Ihre Stationen ohne Ihr Wissen kontrollieren',
    youTooF: 'Sie können die Zukunft des Projekts beeinflussen',
    youIdeas: 'Neue Ideen vorschlagen, abstimmen, am Projektleben teilnehmen',
    helpYou: 'Das System erfüllt Ihre Wünsche und führt neue Funktionen ein',
    howToUse: 'Wie wird es verwendet?',
    howToUseText: '1. <a href="/{{lang}}/downloads">Laden Sie</a> das Programm herunter und installieren Sie es auf Ihren Geräten<br/>' +
        '2. Registrieren Sie sich in der <a href="{{reg}}" target=\'_blank\' rel=\'noreferrer\'>Kontrolltafel</a><br/>' +
        '3. Fügen Sie eine Station hinzu, indem Sie die ID und das Passwort eingeben, die im installierten Programm bereitgestellt wurden<br/>' +
        '4. Übernehmen Sie die Kontrolle!',
    applyTelegram: 'Treten Sie dem Projekt bei Telegram bei',
    newsG: 'Nachrichtengruppe',
    chat: 'Allgemeiner Chat',
};
