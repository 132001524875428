export default {
    idea: 'Enviar uma ideia',
    ideaT: 'O sistema está aberto para as suas ideias e feedback! Escreva sobre eles no nosso grupo no <a href="{{tg}}" target="_blank" rel="noreferrer">Telegram</a> ou envie-os para mim em particular. <br/>Sua participação, de qualquer forma, ajudará o projeto a avançar. Obrigado antecipadamente!',

    metaTitle: 'Feedback',
    metaDesc: 'Reporte um problema com o serviço de controle remoto de desktop "Cusco Remote Control"',
    metaKeys: '',
    title: 'Feedback',
    text: `
Grupo do Telegram para discussão: <a href="{{tg}}" target='_blank' rel='noreferrer'>cusco_rc_group</a><br/>
Foi criado um tópico dedicado para cada assunto:
<ul>
<li><a href="{{tgIdea}}" target='_blank' rel='noreferrer'>Ideias</a> - os recursos que você gostaria de ver no projeto</li>
<br/><li><a href="{{tgBugs}}" target='_blank' rel='noreferrer'>Bugs</a> - se você encontrar um erro ou antecipar um. Para bugs relacionados à segurança, é melhor enviá-los para mim em particular.</li>
<br/><li><a href="{{tgMain}}" target='_blank' rel='noreferrer'>Geral</a> - discussões sobre tópicos gerais, pesquisas</li>
</ul>
`
};
