export default {
    metaTitle: 'إدارة الكمبيوتر عن بعد والخوادم. المراقبة. تنفيذ أوامر عن بعد.',
    metaDesc: 'أفضل برنامج للوصول عن بُعد إلى الكمبيوترات. التحكم عن بُعد في الكمبيوتر الشخصي أو الخادم.',
    metaKeys: 'وصول عن بُعد مجاني، بديل Teamviewer، بديل AnyDesk، برنامج الوصول عن بُعد',
    title: 'اتصل بالمحطات الآن',
    titleD: 'استحوذ بالكامل على أجهزتك',
    download: 'تنزيل',
    possibilities: 'إمكانيات',
    possibilitiesF: 'إمكانيات مستقبلية',
    inv: 'جرد المحطة',
    vis: 'التحكم المرئي <br/>مثل Teamviewer/AnyDesk',
    manage: 'الإدارة والعرض:',
    proc: 'العمليات الجارية',
    prog: 'البرامج',
    dev: 'الأجهزة',
    commands: 'الأوامر',
    power: 'إدارة الطاقة',
    comDo: 'تنفيذ الأوامر',
    launch: 'التشغيل بدون تثبيت',
    security: 'الأمان',
    onlyYou: 'فقط أنت يمكنك إرسال أوامر إلى محطاتك',
    itIsAKey: 'كل ذلك بفضل مفتاح فريد مخزن معك',
    nobodyCan: 'لا يمكن للخدمة أو أي شخص آخر التحكم في محطاتك دون معرفتك',
    youTooF: 'يمكنك التأثير على مستقبل المشروع',
    youIdeas: 'اقترح أفكار جديدة، صوت، شارك في حياة المشروع',
    helpYou: 'ينفذ النظام رغباتك ويقدم ميزات جديدة',
    howToUse: 'كيفية الاستخدام؟',
    howToUseText: '1. <a href="/{{lang}}/downloads">قم بتنزيل</a> وتثبيت البرنامج على أجهزتك<br/>' +
        '2. سجل في <a href="{{reg}}" target=\'_blank\' rel=\'noreferrer\'>لوحة التحكم</a><br/>' +
        '3. أضف محطة عن طريق إدخال المعرف وكلمة المرور المقدمة في البرنامج المثبت<br/>' +
        '4. استحوذ على السيطرة!',
    applyTelegram: 'انضم إلى المشروع على تيليجرام',
    newsG: 'مجموعة أخبار',
    chat: 'الدردشة العامة',
};
