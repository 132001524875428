export default {
    metaTitle: 'Custo dos Serviços',
    metaDesc: 'Quanto custa o sistema de controle remoto e monitoramento?',
    metaKeys: 'Controle remoto na empresa, preço para gerenciamento de computadores, monitoramento de funcionários, controle remoto gratuito',
    title: 'Custo',
    titleD: 'a partir de 20 centavos por estação por mês',
    text: `
 <p>
 O serviço permite que os usuários definam seu próprio preço: de 20 centavos a 10 dólares, com 5 estações fornecidas gratuitamente.
 </p>
 <p>
 Essa opção também serve como uma avaliação da ideia por trás deste projeto. Quanto maior o valor especificado, melhor será este projeto. Ele terá servidores mais poderosos, menos bugs, uma interface mais amigável e interações mais rápidas. O mais importante é que novos recursos serão introduzidos para facilitar o gerenciamento das estações.
 </p>
`
};
