export default {
    idea: 'एक विचार प्रस्तुत करें',
    ideaT: 'सिस्टम आपके विचार और प्रतिक्रिया के लिए खुला है! इनके बारे में हमारे <a href="{{tg}}" target="_blank" rel="noreferrer">Telegram</a> समूह में लिखें या उन्हें निजी तौर पर मुझे भेजें। <br/>किसी भी रूप में आपकी भागीदारी परियोजना को आगे बढ़ाने में मदद करेगी। आग्रह करते हैं, धन्यवाद!',

    metaTitle: 'प्रतिक्रिया',
    metaDesc: '"कुस्को रिमोट कंट्रोल" डेस्कटॉप रिमोट कंट्रोल सेवा के साथ समस्या की सूचना दें',
    metaKeys: '',
    title: 'प्रतिक्रिया',
    text: `
चर्चा के लिए Telegram समूह: <a href="{{tg}}" target='_blank' rel='noreferrer'>cusco_rc_group</a><br/>
प्रत्येक विषय के लिए एक विशेष विषय बनाया गया है:
<ul>
<li><a href="{{tgIdea}}" target='_blank' rel='noreferrer'>विचार</a> - परियोजना में आपको देखना चाहिए विशेषताएँ</li>
<br/><li><a href="{{tgBugs}}" target='_blank' rel='noreferrer'>समस्याएँ</a> - यदि आपको कोई त्रुटि मिलती है या आप उम्मीद करते हैं। सुरक्षा संबंधित समस्याओं के लिए, उन्हें मुझे निजी तौर पर भेजना बेहतर होगा।</li>
<br/><li><a href="{{tgMain}}" target='_blank' rel='noreferrer'>सामान्य</a> - सामान्य विषयों पर चर्चाएँ, मतदान</li>
</ul>
`
};
