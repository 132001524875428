import React, {useEffect, useMemo, useRef, useState} from "react";
import Header from "../header/header";
import styles from './layout.module.scss';
import RootStore from "../../stores/rootStore";
import {observer} from "mobx-react";
import {HeadProvider, Link, Meta, Title} from "react-head";
import {isMobile} from "react-device-detect";
import HeaderMobile from "../headerMobile/headerMobile";
import Footer from "../footer/footer";

function Layout(props) {
    const rootStore = RootStore.getInstance();

    useMemo(() => {
        if (isMobile) {
            document.body.classList.add('mob');
            document.body.classList.remove('desk');
        } else {
            document.body.classList.add('desk');
            document.body.classList.remove('mob');
        }
    }, [isMobile]);

    const [scrolled, setScrolled] = useState(false)

    useEffect(() => {
        const target: any = isMobile ? document.getElementsByClassName(styles.content)[0] : window
        const listenScrollEvent = (event) => {
            let scrollTop = isMobile ? target.scrollTop : target.scrollY;
            if (scrollTop < 73) {
                return setScrolled(false)
            } else if (scrollTop > 70) {
                return setScrolled(true)
            }
        }

        target.addEventListener('scroll', listenScrollEvent);

        return () =>
            target.removeEventListener('scroll', listenScrollEvent);
    }, []);

    let canonical = useMemo(() => !window.location.href.includes('cusco-remote-control.com') ?
            window.location.href.replace('cusco-rc.com', 'cusco-remote-control.com') : null,
        [window.location.href])

    return <div className={styles.main + (isMobile ? ' grid dir-col' : '')}>
        {!isMobile ? <Header scrolled={scrolled}/> : <HeaderMobile scrolled={scrolled}/>}

        <div className={styles.content + (isMobile ? ' col' : '')}>
            {props.children}

            <Footer/>
        </div>

        <HeadProvider>
            <Title>{`${rootStore.meta.title} | Cusco Remote Control`}</Title>
            <Meta property="og:description" content={rootStore.meta.desc} key="description"/>
            <Link rel="icon" href="/favicon.ico"/>
            {canonical && <Link rel="canonical" href={canonical}/>}
            <Meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1"/>
            {/* <Meta name='HandheldFriendly' content='true' />
            <Meta name='MobileOptimized' content='width' />
            <Meta name='apple-mobile-web-app-capable' content='yes'/>*/}
        </HeadProvider>

    </div>
}

export default observer(Layout);
