export default {
    metaTitle: 'Download Remote Access Program. Windows, Linux, MacOS.',
    metaDesc: 'Download the latest version for full control of PCs and servers.',
    metaKeys: 'download cusco, cusco win, cusco linux, cusco macos',
    title: 'Choose Your Platform',
    download: 'Download',
    version: 'Version',
    versions: 'Versions',
    versionsT: `There are two versions of the program:
 <ul>
 <li>With a graphical interface -- larger in size but more convenient to operate</li>
 <li>With a terminal interface -- smaller in size, works in the console, and uses the keyboard for control.
 Preferred version if you have an old version of the system or the graphical interface does not work.
 </li>
 </ul>`,

    install: 'Installation',
    installTWin: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>Download</a> and run the program.<br/>
 2. Register in the <a href="{{panel}}" target='_blank' rel='noreferrer'>control panel</a>.<br/>
 3. Add a station by specifying the ID and password provided in the program on the device.<br/>
 4. Start controlling!
 <br/><br/>`,
    installTLinux: `
 1. Download the program using the command:<br/>
 <div class="{{sCode}}">
 cd ~<br/>
 wget {{installLink}}<br/>
 </div>
 2. Run the program:<br/>
 <div class="{{sCode}}">
 sudo chmod +x {{fileName}}<br/>
 sudo ./{{fileName}}<br/>
 </div>

 3. Register in the <a href="{{panel}}" target='_blank' rel='noreferrer'>control panel</a>.<br/>
 4. Add a station by specifying the ID and password provided in the program on the device.<br/>
 5. Start controlling!

 <br/><br/>
 Note: During installation, a service is created that starts when the computer is turned on.<br/>
 You can open the program in the console by using the "cusco" command.<br/>
 To get a list of all commands, use "cusco --help".`,
    inProgress: `In progress`,
    installTMac: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>Download</a> and run the program.<br/>
 2. Register in the <a href="{{panel}}" target='_blank' rel='noreferrer'>control panel</a>.<br/>
 3. Add a station by specifying the ID and password provided in the program on the device.<br/>
 4. Start controlling!
 <br/><br/>`,
    mainCommands: 'Main Commands',
    mainCommandsT: `<ul>
 <li>-i or --install - quick installation</li>
 <li>-u or --uninstall - quick uninstallation</li> 
 <li>-h or --help - help</li> 
 <li>--update - update to the latest version</li> 
 </ul>
 In the graphical version, the command will be executed, but there will be no output in the console (due to build peculiarities).
`,
    feedbackTitle: `Your feedback is very important!`,
    feedbackBug: `
 If you have noticed a bug or the program is not working for you, please write in the chat
 <a href="{{bugs}}" target='_blank' rel='noreferrer'>on Telegram</a>
 in the 'Bugs' section. I will try to resolve the issue as quickly as possible.
 `,
    feedbackIdea: `
 If the program does not meet any of your needs or if you have any interesting thoughts,
 please let me know in the
 <a href="{{ideas}}" target='_blank'
  rel='noreferrer'>group</a> in the 'Ideas' section.
  `,
};
