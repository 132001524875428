export default {
    metaTitle: 'Дорожная карта проекта',
    metaDesc: 'Какие задачи уже реализованы в проекте и какие ожидают своей очереди',
    metaKeys: 'Бесплатная программа управление процессами, удаление программ, удаленное выполнение команд, инвентаризация станций, просмотр устройств, управление питанием',
    title: 'Дорожная карта',
    titleD: 'Список реализованных и будущих возможностей',

    showDone: 'Показать выполненное',
    hideDone: 'Скрыть выполненное',
    showMore: 'Показать подробности',
    inQuery: 'В очереди',
    hide: 'Скрыть',
    more: 'Подробнее',
    needMoney: 'Необходимо финансирование',
    "task0": "Список станций, добавление новой",
    "task1": "Общая информация о станции",
    "task2": "Процессы. Управление и просмотр",
    "task3": "Программы. Управление и просмотр",
    "task4": "Устройства. Просмотр",
    "task5": "Выполнение команды",
    "task6": "Управление питанием",
    "task7": "История команд",
    "task8": "Визуальное управление Windows",
    "task9": "Сборка для Mac",
    "task9Desc":`
        <p>
        Так как я работаю уже много лет на Windows, а свой последний мак продал пять лет назад - 
        попытался сделать сборку на виртуальной машине, она прекрасно запустилась, но собрать проект 
        не получилось, потому что отклик на действия 
        составляет около 3-5 секунд, при чем одинаково как для vmware и virtualbox, за пару дней перепробовал
         все варианты с настройками, но результат тот же. А разрабатывать под эту ось можно только на ней, 
        под Windows или Linux это сделать не получится.
        </p>
        <p>
        Пришел к выводу, что нужно покупать устройство хотя бы со средней производительностью на этой системе, 
        если можете - поддержите проект, буду очень благодарен. Как только наберется необходимая 
        сумма, завершу текущую задачу и займусь этой.
         <a href="{{lang}}/donate">Поддержать можно здесь</a>
        </p>`,
    "task10": "Личный кабинет пользователя",
    "task11": "Перевод на иностранные языки",
    "task12": "Двухфакторная аутентификация",
    "task13": "Удаленная командная строка",
    "task13Desc": `<p>Аналогично ssh клиенту, только в браузере.</p>`,
    "task14": "Установщик в msi формате",
    "task15": "Отслеживание процессов на графике",
    "task16": "Документация к API",
    "task17": "Открытие исходного кода клиента",
    "task18": "Управление местом на диске",
    "task19": "Просмотр логов",
    "task20": "Временный доступ",
    "task21": "Мобильное приложение",
    "task22": "Проброс портов",
    "task23": "Генерация программы пользователем",
    "task23Desc": `<p>Будет создаваться файл запуска программы, при открытии которого на пользовательском устройстве ' +
                            'приславший файл пользователь будет добавлен в разрешенный список.</p>`,
    "task24": "Функции администрирования в клиенте",
    "task24Desc": `<p>Возможность зайти в панель управления этой станций или любой другой без использования браузера.</p>`,
    "task25": "Разграничение прав на просмотр и управление"
};
