export default {
    metaTitle: 'Gestione remota di computer e server. Monitoraggio. Esecuzione remota di comandi.',
    metaDesc: 'Il miglior programma per l\'accesso remoto ai computer. Controllo remoto del PC o del server.',
    metaKeys: 'Accesso remoto gratuito, alternativa a TeamViewer, alternativa a AnyDesk, programma di accesso remoto',
    title: 'Connettiti alle stazioni ora',
    titleD: 'Prendi il pieno controllo dei tuoi dispositivi',
    download: 'Download',
    possibilities: 'Possibilità',
    possibilitiesF: 'Possibilità future',
    inv: 'Inventario stazioni',
    vis: 'Controllo visivo <br/>come TeamViewer/AnyDesk',
    manage: 'Gestione e visualizzazione:',
    proc: 'Processi in esecuzione',
    prog: 'Programmi',
    dev: 'Dispositivi',
    commands: 'Comandi',
    power: 'Gestione alimentazione',
    comDo: 'Esecuzione comandi',
    launch: 'Avvio senza installazione',
    security: 'Sicurezza',
    onlyYou: 'Solo tu puoi inviare comandi alle tue stazioni',
    itIsAKey: 'Tutto grazie a una chiave unica memorizzata con te',
    nobodyCan: 'Né il servizio né nessun altro possono controllare le tue stazioni senza la tua conoscenza',
    youTooF: 'Puoi influenzare il futuro del progetto',
    youIdeas: 'Proponi nuove idee, vota, partecipa alla vita del progetto',
    helpYou: 'Il sistema realizza i tuoi desideri e introduce nuove funzionalità',
    howToUse: 'Come utilizzare?',
    howToUseText: '1. <a href="/{{lang}}/downloads">Scarica</a> e installa il programma sui tuoi dispositivi<br/>' +
        '2. Registrati nel <a href="{{reg}}" target=\'_blank\' rel=\'noreferrer\'>pannello di controllo</a><br/>' +
        '3. Aggiungi una stazione inserendo l\'ID e la password forniti nel programma installato<br/>' +
        '4. Prendi il controllo!',
    applyTelegram: 'Unisciti al progetto su Telegram',
    newsG: 'Gruppo di notizie',
    chat: 'Chat generale',
};
