export default {
    metaTitle: 'Roadmap del Progetto',
    metaDesc: 'Compiti che sono già stati implementati nel progetto e quelli che sono in attesa del loro turno',
    metaKeys: 'Programma gratuito per la gestione dei processi, rimozione dei programmi, esecuzione di comandi remoti, inventario delle stazioni, visualizzazione dei dispositivi, gestione dell\'alimentazione',
    title: 'Roadmap',
    titleD: 'Elenco delle Funzionalità Implementate e Future',

    showDone: 'Mostra Completati',
    hideDone: 'Nascondi Completati',
    showMore: 'Mostra Dettagli',
    inQuery: 'In Coda',
    hide: 'Nascondi',
    more: 'Altro',
    needMoney: 'Necessità di Finanziamento',
    "task0": "Elenco delle Stazioni, Aggiungi Nuova",
    "task1": "Informazioni Generali sulla Stazione",
    "task2": "Processi. Gestione e Visualizzazione",
    "task3": "Programmi. Gestione e Visualizzazione",
    "task4": "Dispositivi. Visualizzazione",
    "task5": "Esecuzione di Comandi Remoti",
    "task6": "Gestione dell'Alimentazione",
    "task7": "Cronologia dei Comandi",
    "task8": "Controllo Visuale per Windows",
    "task9": "Build per Mac",
    "task9Desc":`
        <p>
        Poiché lavoro su Windows da molti anni e ho venduto il mio ultimo Mac cinque anni fa, 
        ho provato a fare la build su una macchina virtuale, che è stata avviata correttamente, 
        ma non sono riuscito a fare la build del progetto perché il tempo di risposta alle azioni richiede 
        circa 3-5 secondi ed è lo stesso sia per vmware che per virtualbox. Ho provato tutte le possibili 
        impostazioni per un paio di giorni, ma il risultato è lo stesso. Tuttavia, è possibile sviluppare per 
        questo sistema solo su quel sistema stesso e non può essere fatto su Windows o Linux.
        </p>
        <p>
        Sono giunto alla conclusione che devo acquistare un dispositivo con almeno una prestazione media su 
        questo sistema. Se puoi, per favore supporta il progetto, ti sarei molto grato. Una volta che verrà 
        raccolta la somma necessaria, completerò il compito attuale e inizierò a lavorare su questo.
        <a href="{{lang}}/donate">Il supporto può essere fornito qui</a>
        </p>`,
    "task10": "Account Utente",
    "task11": "Traduzione in Lingue Straniere",
    "task12": "Autenticazione a Due Fattori",
    "task13": "Linea di Comando Remota",
    "task13Desc": `<p>Simile a un client SSH, ma nel browser.</p>`,
    "task14": "Installatore MSI",
    "task15": "Monitoraggio dei Processi su un Grafico",
    "task16": "Documentazione API",
    "task17": "Apertura del Codice Sorgente del Client",
    "task18": "Gestione dello Spazio su Disco",
    "task19": "Visualizzazione dei Log",
    "task20": "Accesso Temporaneo",
    "task21": "Applicazione Mobile",
    "task22": "Inoltro Porte",
    "task23": "Generazione di Programmi da Parte dell'Utente",
    "task23Desc": `<p>Verrà creato un file di avvio del programma e quando l'utente lo aprirà sul proprio dispositivo, l'utente che ha inviato il file verrà aggiunto alla lista consentita.</p>`,
    "task24": "Funzioni di Amministrazione nel Client",
    "task24Desc": `<p>Possibilità di accedere al pannello di controllo di questa stazione o di qualsiasi altra senza utilizzare un browser.</p>`,
    "task25": "Differenziazione dei Permessi per Visualizzazione e Controllo"
};
