export default {
    metaTitle: 'Sponsorizza il Progetto',
    metaDesc: 'Opportunità di partecipare allo sviluppo del progetto di controllo remoto e monitoraggio del computer',
    metaKeys: 'Sponsorizza il progetto, supporto al progetto',
    title: 'Supporto al Progetto',
    text: `
 <p>
 Da molti mesi sto sviluppando, mantenendo e finanziando questo progetto da solo. Tuttavia, mi sto gradualmente imbattendo in alcuni problemi che richiedono supporto finanziario.
 </p>
 <p>
 Se hai l'opportunità, ti prego di supportare il servizio. Sarei molto grato! Indipendentemente dall'importo donato, significherà che consideri il mio progetto prezioso e credi nel suo sviluppo, proprio come faccio io. Questo supporto rafforzerà significativamente la mia motivazione.
 </p>
 <p>
 <div class="{{subtitle1}}">
 Come Inviare Denaro
 </div>
 Poiché aderisco ai valori e alle visioni del cripto-anarchismo, ho solo portafogli di criptovaluta. Di seguito trovi un'istruzione su come finanziarli da una carta di credito:
 <div class="{{code}}">
USDT <span class="{{green}}">bep20 (Binance)</span>: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
BTC: bc1qyz2gken4mxgfquqfmvrevyttycdxtp349ve7kz <br/><br/>
ETH: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
DOGE: D8aQgYPjkpN92AJiRokhHaSWw9LBG9g6xT
</div>
<div class="{{subtitle2}}">Istruzioni</div>
<ul class="{{instruction}}">
<li> Direttamente - se hai un portafoglio elettronico con le monete USDT bep20.</li>
<li> Attraverso un servizio di scambio, scegline uno qui, ad esempio, <a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html" target=_blank rel=noreferrer>bestchange.com</a>:
<ul>
<li> Vai alla pagina di monitoraggio del servizio di scambio</li>
<li> Nella colonna "Offre", seleziona un metodo di pagamento adatto, come "Visa/Mastercard". Ti consiglio di scegliere in questa colonna il metodo di pagamento con il tasso di interesse più basso per il tuo paese (circa 3-5%), di solito una delle banche popolari nella valuta locale, e non un trasferimento diretto in USD.
<li> Nella colonna "Riceve", dovrebbe essere indicato Tether bep20</li>
<li> Scegli un servizio di scambio con il miglior tasso o le migliori condizioni</li>
<li> Sul sito del servizio di scambio, inserisci l'importo che desideri finanziare in dollari, copia l'indirizzo di ricarica 0x1716a9bAaE728780D98fb26faAe17D79FB62F090, compila i campi rimanenti e trasferisci l'importo secondo i dettagli forniti</li>
<li> La transazione viene elaborata dal servizio di scambio e dalla rete entro 20 minuti</li>
</ul>
</li>
</ul>
Se incontri qualche problema, scrivimi su <a href="{{tgMy}}" target=_blank rel=noreferrer>Telegram</a>, e risolveremo insieme.
</p>
<p>
<div class="{{subtitle1}}">
 Come Saranno Utilizzati i Fondi
 </div>
 Acquisto di attrezzature necessarie, pagamento dell'affitto del server, pagamento dei servizi di assistenza allo sviluppo, test, design, biscotti.
 <br/>
 <br/>
 Se l'importo supera lo stipendio di un paio di mesi, potrò assumere un assistente e accelerare significativamente il rilascio di nuove versioni.
</p>
<div class="{{subtitle1}}">
 Grazie in anticipo per il tuo supporto!
 </div>
 Anche se hai semplicemente dedicato del tempo a leggere questo testo :)
`
};
