export default {
    metaTitle: '프로젝트 후원',
    metaDesc: '원격 제어 및 컴퓨터 모니터링 프로젝트 개발에 참여할 수 있는 기회',
    metaKeys: '프로젝트 후원, 프로젝트 지원',
    title: '프로젝트 지원',
    text: `
 <p>
 몇 달 동안 저는 이 프로젝트를 홀로 개발, 유지보수 및 자금 지원을 하고 있습니다. 그러나 점차적으로 금전적 지원이 필요한 문제들을 마주하고 있습니다.
 </p>
 <p>
 기회가 있다면, 이 서비스를 지원해 주십시오. 정말 감사하겠습니다! 기부 금액과 상관없이 당신이 나의 프로젝트를 가치있게 여기고 그 발전을 믿는다는 것을 의미합니다. 이 지원은 제 동기부여를 크게 강화할 것입니다.
 </p>
 <p>
 <div class="{{subtitle1}}">
 돈을 보내는 방법
 </div>
 저는 암호화 애나키즘의 가치와 견해를 고수하기 때문에, 디지털 화폐 월렛만 가지고 있습니다. 은행 카드로부터 자금을 지원하는 방법에 대한 안내는 아래와 같습니다:
 <div class="{{code}}">
USDT <span class="{{green}}">bep20 (바이낸스)</span>: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
BTC: bc1qyz2gken4mxgfquqfmvrevyttycdxtp349ve7kz <br/><br/>
ETH: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
DOGE: D8aQgYPjkpN92AJiRokhHaSWw9LBG9g6xT
</div>
<div class="{{subtitle2}}">지침</div>
<ul class="{{instruction}}">
<li> 직접 - USDT bep20 코인을 가진 전자 지갑이 있는 경우</li>
<li> 거래소를 통해, 여기에서 하나를 선택하세요, 예를 들어, <a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html" target=_blank rel=noreferrer>bestchange.com</a>:
<ul>
<li> 거래소 모니터링 페이지로 이동</li>
<li> "중단" 열에서 "Visa/Mastercard"와 같은 적합한 결제 방법을 선택하세요. 국가별로 최저 금리의 결제 방법을 선택하는 것을 권장합니다(약 3-5%), 일반적으로 현지 통화의 인기있는 은행 중 하나이며, 직접적인 USD 이체가 아닌 경우입니다.
<li> "받는 쪽" 열에 Tether bep20이라고 나와야 합니다</li>
<li> 최고의 환율이나 조건을 가진 거래소를 선택하세요</li>
<li> 거래소 웹사이트에서 달러로 지원할 금액을 입력하고, 입금 주소 0x1716a9bAaE728780D98fb26faAe17D79FB62F090을 복사하고, 나머지 필드를 작성하고, 제공된 세부 정보에 따라 금액을 이체하세요</li>
<li> 거래는 거래소와 네트워크에서 20분 이내에 처리됩니다</li>
</ul>
</li>
</ul>
문제가 발생하면 <a href="{{tgMy}}" target=_blank rel=noreferrer>Telegram</a>으로 저에게 연락하고 함께 해결해 보겠습니다.
</p>
<p>
<div class="{{subtitle1}}">
 자금 사용 방법
 </div>
 필요한 장비 구매, 서버 임대료 지불, 개발 지원 서비스 비용 지불, 테스트, 디자인, 쿠키.
 <br/>
 <br/>
 급여를 수개월 초과하는 금액인 경우, 조수를 고용하고 새로운 버전의 출시를 크게 가속화할 수 있습니다.
</p>
<div class="{{subtitle1}}">
 미리 지원해 주셔서 감사합니다!
 </div>
 이 텍스트를 읽는 데 시간을 내주신다 해도 감사합니다 :)
`
};
