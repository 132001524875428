export default {
    metaTitle: 'Fonctionnalités d\'accès à distance : Contrôle, Surveillance, Statistiques, Inventaire, Gestion de Serveurs et d\'Ordinateurs',
    metaDesc: 'Un service professionnel pour les administrateurs système et les utilisateurs réguliers pour le contrôle à distance des ordinateurs. Une alternative supérieure aux programmes d\'accès à distance étrangers. Développement actif et mise en œuvre de fonctionnalités demandées par les utilisateurs. Gestion des processus, suppression de programmes, exécution de commandes à distance, inventaire des stations, affichage des périphériques, gestion de l\'alimentation.',
    metaKeys: 'Programme gratuit pour la gestion des processus, la suppression de programmes, l\'exécution de commandes à distance, l\'inventaire des stations, l\'affichage des périphériques, la gestion de l\'alimentation',
    title: 'Fonctionnalités du Système',
    startUse: 'Commencer à Utiliser',
    remoteControl: 'Contrôle à Distance',
    remoteControlT: 'Contrôle standard du bureau à distance. Similaire à TeamViewer, AnyDesk, Ammyy Admin.<br/><br/>Vous pouvez vous connecter depuis un ordinateur ou un téléphone portable via un navigateur.',
    invent: 'Inventaire des Stations',
    inventT: 'Vous avez besoin d\'un inventaire et vous ne voulez pas vous connecter à chaque machine ?<br/><br/>Vérifiez simplement les caractéristiques de tous les périphériques dans le panneau de contrôle.',
    process: 'Processus',
    processT: 'Vous pouvez afficher et filtrer à tout moment tous les processus en cours d\'exécution sur la station et les terminer manuellement si nécessaire.',
    programs: 'Programmes et Périphériques',
    programsT: 'Vérifiez toutes les applications installées et supprimez-les de l\'appareil si vous le souhaitez.<br/><br/>Vous voulez savoir exactement quels équipements sont installés ? Pas de problème.',
    remoteCommand: 'Exécution de Commandes à Distance',
    remoteCommandT: 'Il y a des situations où vous avez juste besoin d\'exécuter une commande pour résoudre un problème ou découvrir quelque chose, et vous ne voulez pas vous connecter. Vous pouvez l\'envoyer en appuyant sur un seul bouton et obtenir le résultat de l\'exécution.<br/><br/>La gestion de l\'alimentation fonctionne tout aussi facilement. Redémarrez, verrouillez ou mettez la station en veille en quelques clics.',
    feature: 'Liste des Fonctionnalités Futures',
    idea: 'Soumettre une Idée',
    ideaT: 'Le système est ouvert à vos idées et commentaires ! Écrivez-les dans notre groupe Telegram <a href="{{tg}}" target="_blank" rel="noreferrer">ici</a> ou envoyez-moi un message direct.<br/>Votre participation sous quelque forme que ce soit aidera le projet à avancer. Merci d\'avance !',
};
