export default {
    metaTitle: 'リモートアクセスプログラムのダウンロード。Windows、Linux、MacOS。',
    metaDesc: 'PCやサーバーを完全に制御するための最新バージョンをダウンロードしてください。',
    metaKeys: 'cuscoダウンロード、cusco win、cusco linux、cusco macos',
    title: 'プラットフォームを選択してください',
    download: 'ダウンロード',
    version: 'バージョン',
    versions: 'バージョン',
    versionsT: `プログラムには2つのバージョンがあります：
 <ul>
 <li>グラフィカルインターフェース付き--サイズは大きいですが、操作がより便利です</li>
 <li>ターミナルインターフェース付き--サイズは小さく、コンソールで動作し、キーボードで制御します。
 古いバージョンのシステムを使用している場合や、グラフィカルインターフェースが動作しない場合には、こちらのバージョンをおすすめします。
 </li>
 </ul>`,

    install: 'インストール',
    installTWin: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>プログラムをダウンロード</a>して実行します。<br/>
 2. <a href="{{panel}}" target='_blank' rel='noreferrer'>コントロールパネル</a>に登録します。<br/>
 3. デバイス上のプログラムで提供されたIDとパスワードを指定してステーションを追加します。<br/>
 4. コントロールを開始します！
 <br/><br/>`,
    installTLinux: `
 1. 次のコマンドを使用してプログラムをダウンロードします：<br/>
 <div class="{{sCode}}">
 cd ~<br/>
 wget {{installLink}}<br/>
 </div>
 2. プログラムを実行します：<br/>
 <div class="{{sCode}}">
 sudo chmod +x {{fileName}}<br/>
 sudo ./{{fileName}}<br/>
 </div>

 3. <a href="{{panel}}" target='_blank' rel='noreferrer'>コントロールパネル</a>に登録します。<br/>
 4. デバイス上のプログラムで提供されたIDとパスワードを指定してステーションを追加します。<br/>
 5. コントロールを開始します！

 <br/><br/>
 注意：インストール中には、コンピューターの電源が入るとサービスが作成されます。<br/>
 コンソールでプログラムを開くには、「cusco」というコマンドを使用できます。<br/>
 すべてのコマンドのリストを取得するには、「cusco --help」を使用してください。`,
    inProgress: `進行中`,
    installTMac: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>プログラムをダウンロード</a>して実行します。<br/>
 2. <a href="{{panel}}" target='_blank' rel='noreferrer'>コントロールパネル</a>に登録します。<br/>
 3. デバイス上のプログラムで提供されたIDとパスワードを指定してステーションを追加します。<br/>
 4. コントロールを開始します！
 <br/><br/>`,
    mainCommands: 'メインコマンド',
    mainCommandsT: `<ul>
 <li>-iまたは--install--クイックインストール</li>
 <li>-uまたは--uninstall--クイックアンインストール</li> 
 <li>-hまたは--help--ヘルプ</li> 
 <li>--update--最新バージョンへのアップデート</li> 
 </ul>
 グラフィカルバージョンでは、コマンドは実行されますが、コンソールには出力されません（ビルドの特殊性によるもの）。
`,
    feedbackTitle: `あなたのフィードバックは非常に重要です！`,
    feedbackBug: `
 バグを発見した場合やプログラムが正常に動作しない場合は、チャットで
 <a href="{{bugs}}" target='_blank' rel='noreferrer'>Telegram</a>
 の「バグ」セクションに書いてください。できるだけ早く問題を解決します。
 `,
    feedbackIdea: `
 プログラムがニーズに合わない場合や興味深いアイデアがある場合は、
 <a href="{{ideas}}" target='_blank'
  rel='noreferrer'>グループ</a>の「アイデア」セクションでお知らせください。
  `,
};
