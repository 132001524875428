export default function MyImg({src, global, href, ...props}: any) {
    let res
    if (global)
        res = <img src={require('../../../public/images/' + src)?.default} alt='' {...props}/>;
    else
        res = <img src={require('../../images/' + src)?.default} alt='' {...props}/>;

    if(href)
        return <a href={href} target="_blank">{res}</a>
    else
        return res
}