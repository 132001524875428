export default {
    metaTitle: 'परियोजना रोडमैप',
    metaDesc: 'परियोजना में अब तक किए गए कार्य और जो अपनी बारी का इंतजार कर रहे हैं',
    metaKeys: 'प्रक्रिया प्रबंधन, कार्यक्रम हटाना, दूरस्थ आदेश क्रियान्वयन, स्टेशन सूचीकरण, उपकरण देखना, शक्ति प्रबंधन के लिए मुफ्त प्रोग्राम',
    title: 'रोडमैप',
    titleD: 'लागू किए गए और भविष्य की संभावनाएं की सूची',

    showDone: 'पूरा दिखाएं',
    hideDone: 'पूरा छिपाएं',
    showMore: 'विवरण दिखाएं',
    inQuery: 'कतार में',
    hide: 'छिपाएं',
    more: 'अधिक',
    needMoney: 'वित्तीय सहायता की जरूरत है',
    "task0": "स्टेशन सूची, नया जोड़ें",
    "task1": "सामान्य स्टेशन जानकारी",
    "task2": "प्रक्रियाएँ। प्रबंधन और देखना",
    "task3": "कार्यक्रम। प्रबंधन और देखना",
    "task4": "उपकरण। देखें",
    "task5": "आदेश क्रियान्वयन",
    "task6": "शक्ति प्रबंधन",
    "task7": "आदेश इतिहास",
    "task8": "विज़ुअल कंट्रोल विंडोज़ के लिए",
    "task9": "मैक के लिए बिल्ड",
    "task9Desc":`
        <p>
        क्योंकि मैं विंडोज़ पर काफ़ी सालों से काम कर रहा हूँ और अपना आखिरी मैक पांच साल पहले बेच दिया था, 
        मैंने वर्चुअल मशीन पर इसे बनाने का प्रयास किया, वह ठीक चली, लेकिन मैं परियोजना को बनाने में सक्षम नहीं रहा 
        क्योंकि कार्रवाईयों के प्रतिक्रिया का समय लगभग 3-5 सेकंड है, और यह vmware और virtualbox दोनों के लिए एक समान है। 
        मैंने दो दिनों के लिए सभी संभावित सेटिंग्स परीक्षण किया है, लेकिन परिणाम वही है। हालांकि, इस सिस्टम के लिए 
        केवल सिस्टम पर ही विकसित किया जा सकता है, और इसे विंडोज़ या लिनक्स पर नहीं किया जा सकता है।
        </p>
        <p>
        मैंने यह निष्कर्ष निकाला है कि मुझे इस सिस्टम पर कम से कम मध्यम प्रदर्शन वाली उपकरण ख़रीदने की ज़रूरत है। 
        यदि आप सकते हैं, कृपया परियोजना का समर्थन करें, मैं बहुत आभारी होऊंगा। जैसे ही आवश्यक राशि इकट्ठा होती है, 
        मैं मौजूदा कार्य को पूरा करूंगा और इस पर काम करने लगूंगा।
        <a href="{{lang}}/donate">यहाँ समर्थन कर सकते हैं</a>
        </p>`,
    "task10": "उपयोगकर्ता खाता",
    "task11": "विदेशी भाषाओं में अनुवाद",
    "task12": "द्विचरक प्रमाणीकरण",
    "task13": "दूरस्थ आदेश संचालन",
    "task13Desc": `<p>एक SSH क्लाइंट की तरह, लेकिन एक ब्राउज़र में।</p>`,
    "task14": "MSI स्थापक",
    "task15": "ग्राफ पर प्रक्रियाओं का ट्रैकिंग",
    "task16": "API दस्तावेज़ीकरण",
    "task17": "क्लाइंट के स्रोत कोड का खुलासा",
    "task18": "डिस्क स्थान प्रबंधन",
    "task19": "लॉग देखें",
    "task20": "तात्कालिक पहुँच",
    "task21": "मोबाइल ऐप्लिकेशन",
    "task22": "पोर्ट फ़ॉरवर्डिंग",
    "task23": "उपयोगकर्ता-निर्मित कार्यक्रम उत्पन्न करना",
    "task23Desc": `<p>प्रोग्राम शुरू करने के लिए एक फ़ाइल शुरू होगी, और जब उपयोगकर्ता इसे अपने उपकरण पर खोलेगा, तो फ़ाइल भेजने वाला उपयोगकर्ता स्वीकृत सूची में जोड़ दिया जाएगा।</p>`,
    "task24": "क्लाइंट में प्रशासनिक कार्यों की सुविधा",
    "task24Desc": `<p>ब्राउज़र का उपयोग किए बिना इस स्टेशन या किसी अन्य कंट्रोल पैनल तक पहुँच की अनुमति।</p>`,
    "task25": "देखने और नियंत्रण करने के लिए अनुमति विभाजन"
};
