export default {
    metaTitle: 'プロジェクトをサポートする',
    metaDesc: 'リモートコントロールとコンピュータモニタリングプロジェクトの開発への参加の機会',
    metaKeys: 'プロジェクトをサポートする、プロジェクトのサポート',
    title: 'プロジェクトのサポート',
    text: `
 <p>
 数ヶ月間、私はこのプロジェクトを一人で開発、維持、資金提供してきました。しかし、徐々に資金のサポートが必要な問題に直面しています。
 </p>
 <p>
 サービスをサポートいただける場合は、どうかお力添えをお願いいたします。非常に感謝いたします！寄付金の額に関係なく、それはあなたが私のプロジェクトを価値あるものと考え、その成長に信じていることを意味します。このサポートは私のモチベーションを大きく高めるものです。
 </p>
 <p>
 <div class="{{subtitle1}}">
 送金方法
 </div>
 クリプトアナキズムの価値観と考え方を守るため、私は暗号通貨のウォレットしか持っていません。以下は銀行カードからそれらを資金提供する方法の説明です：
 <div class="{{code}}">
USDT <span class="{{green}}">bep20 (Binance)</span>：0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
BTC：bc1qyz2gken4mxgfquqfmvrevyttycdxtp349ve7kz <br/><br/>
ETH：0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
DOGE：D8aQgYPjkpN92AJiRokhHaSWw9LBG9g6xT
</div>
<div class="{{subtitle2}}">手順</div>
<ul class="{{instruction}}">
<li> 直接 - USDT bep20 コインを持つ電子ウォレットがある場合。</li>
<li> 両替所を通じて。ここで例として<a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html" target=_blank rel=noreferrer>bestchange.com</a>を選択してください：
<ul>
<li> 両替所の監視ページに移動する</li>
<li> 「Gives」の列で、適切な支払い方法（例：「Visa/Mastercard」）を選択します。各国に最も低い利率（約3-5%）の支払い方法をこの列で選ぶことをお勧めします。通常は、現地通貨の人気のある銀行のいずれかです。USDでの直接送金ではありません。
<li> 「Receives」の列にはTether bep20と表示されるはずです</li>
<li> 最高のレートや条件を持つ両替所を選択します</li>
<li> 両替所のウェブサイトで、ドルで資金提供する金額を入力し、0x1716a9bAaE728780D98fb26faAe17D79FB62F090という送金先アドレスをコピーし、残りの項目を入力して指示に従って金額を送金します</li>
<li> トランザクションは、両替所とネットワークによって20分以内に処理されます</li>
</ul>
</li>
</ul>
問題が発生した場合は、<a href="{{tgMy}}" target=_blank rel=noreferrer>Telegram</a>で連絡して一緒に問題を解決しましょう。
</p>
<p>
<div class="{{subtitle1}}">
 資金の使い道
 </div>
 必要な機器の購入、サーバーレンタル料の支払い、開発支援サービスの支払い、テスト、デザイン、クッキー。
 <br/>
 <br/>
 金額が数ヶ月の給与を超える場合、アシスタントを雇って新バージョンのリリースを大幅に加速することができます。
</p>
<div class="{{subtitle1}}">
 ご支援に感謝いたします！
 </div>
 このテキストを読む時間を割いていただいただけでも、感謝の意を表します :)
`
};
