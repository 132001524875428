export default {
    metaTitle: '下载远程访问程序。Windows，Linux，MacOS。',
    metaDesc: '下载最新版本以完全控制个人计算机和服务器。',
    metaKeys: '下载cusco，cusco win，cusco linux，cusco macos',
    title: '选择您的平台',
    download: '下载',
    version: '版本',
    versions: '版本',
    versionsT: `该程序有两个版本：
 <ul>
 <li>带有图形界面--体积较大但操作更方便</li>
 <li>带有终端界面--体积较小，在控制台中工作，使用键盘进行控制。
 如果您使用的是旧版本的系统或图形界面无法正常工作，则推荐使用此版本。
 </li>
 </ul>`,

    install: '安装',
    installTWin: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>下载</a>并运行程序。<br/>
 2. 在<a href="{{panel}}" target='_blank' rel='noreferrer'>控制面板</a>中注册。<br/>
 3. 通过在设备上的程序中指定提供的ID和密码来添加一个站点。<br/>
 4. 开始控制！
 <br/><br/>`,
    installTLinux: `
 1. 使用以下命令下载程序：<br/>
 <div class="{{sCode}}">
 cd ~<br/>
 wget {{installLink}}<br/>
 </div>
 2. 运行程序：<br/>
 <div class="{{sCode}}">
 sudo chmod +x {{fileName}}<br/>
 sudo ./{{fileName}}<br/>
 </div>

 3. 在<a href="{{panel}}" target='_blank' rel='noreferrer'>控制面板</a>中注册。<br/>
 4. 通过在设备上的程序中指定提供的ID和密码来添加一个站点。<br/>
 5. 开始控制！

 <br/><br/>
 注意：安装过程中会创建一个在计算机启动时启动的服务。<br/>
 您可以使用 "cusco" 命令在控制台中打开程序。<br/>
 若要获取所有命令的列表，请使用 "cusco --help"。
 `,
    inProgress: `进行中`,
    installTMac: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>下载</a>并运行程序。<br/>
 2. 在<a href="{{panel}}" target='_blank' rel='noreferrer'>控制面板</a>中注册。<br/>
 3. 通过在设备上的程序中指定提供的ID和密码来添加一个站点。<br/>
 4. 开始控制！
 <br/><br/>`,
    mainCommands: '主要命令',
    mainCommandsT: `<ul>
 <li>-i 或 --install - 快速安装</li>
 <li>-u 或 --uninstall - 快速卸载</li> 
 <li>-h 或 --help - 帮助</li> 
 <li>--update - 更新到最新版本</li> 
 </ul>
 在图形界面版本中，命令将被执行，但控制台上不会有输出（由于构建的特殊性）。
`,
    feedbackTitle: `您的反馈非常重要！`,
    feedbackBug: `
 如果您发现了错误或程序对您不起作用，请在聊天中写下
 <a href="{{bugs}}" target='_blank' rel='noreferrer'>在Telegram上</a>
 的 "Bugs" 部分。我将尽快解决问题。
 `,
    feedbackIdea: `
 如果程序无法满足您的任何需求，或者您有任何有趣的想法，
 请在
 <a href="{{ideas}}" target='_blank'
  rel='noreferrer'>组</a> 的 "Ideas" 部分让我知道。
  `,
};
