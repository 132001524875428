export default {
    metaTitle: 'Descargar programa de acceso remoto. Windows, Linux, MacOS.',
    metaDesc: 'Descarga la última versión para tener control total de tus PCs y servidores.',
    metaKeys: 'descargar cusco, cusco win, cusco linux, cusco macos',
    title: 'Elige tu plataforma',
    download: 'Descargar',
    version: 'Versión',
    versions: 'Versiones',
    versionsT: `Hay dos versiones del programa:
 <ul>
 <li>Con una interfaz gráfica - más grande en tamaño pero más cómoda de usar</li>
 <li>Con una interfaz de terminal - más pequeña en tamaño, funciona en la consola y utiliza el teclado para el control.
 Versión preferida si tienes una versión antigua del sistema o la interfaz gráfica no funciona.
 </li>
 </ul>`,

    install: 'Instalación',
    installTWin: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>Descarga</a> y ejecuta el programa.<br/>
 2. Regístrate en el <a href="{{panel}}" target='_blank' rel='noreferrer'>panel de control</a>.<br/>
 3. Agrega una estación especificando la ID y la contraseña proporcionadas en el programa en el dispositivo.<br/>
 4. ¡Comienza a controlar!
 <br/><br/>`,
    installTLinux: `
 1. Descarga el programa usando el comando:<br/>
 <div class="{{sCode}}">
 cd ~<br/>
 wget {{installLink}}<br/>
 </div>
 2. Ejecuta el programa:<br/>
 <div class="{{sCode}}">
 sudo chmod +x {{fileName}}<br/>
 sudo ./{{fileName}}<br/>
 </div>

 3. Regístrate en el <a href="{{panel}}" target='_blank' rel='noreferrer'>panel de control</a>.<br/>
 4. Agrega una estación especificando la ID y la contraseña proporcionadas en el programa en el dispositivo.<br/>
 5. ¡Comienza a controlar!

 <br/><br/>
 Nota: Durante la instalación se crea un servicio que se inicia cuando se enciende el ordenador.<br/>
 Puedes abrir el programa en la consola utilizando el comando "cusco".<br/>
 Para obtener una lista de todos los comandos, utiliza "cusco --help".`,
    inProgress: `En progreso`,
    installTMac: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>Descarga</a> y ejecuta el programa.<br/>
 2. Regístrate en el <a href="{{panel}}" target='_blank' rel='noreferrer'>panel de control</a>.<br/>
 3. Agrega una estación especificando la ID y la contraseña proporcionadas en el programa en el dispositivo.<br/>
 4. ¡Comienza a controlar!
 <br/><br/>`,
    mainCommands: 'Comandos principales',
    mainCommandsT: `<ul>
 <li>-i o --install - instalación rápida</li>
 <li>-u o --uninstall - desinstalación rápida</li> 
 <li>-h o --help - ayuda</li> 
 <li>--update - actualizar a la última versión</li> 
 </ul>
 En la versión gráfica, el comando se ejecutará, pero no se mostrará ninguna salida en la consola (debido a las peculiaridades de la compilación).
`,
    feedbackTitle: `¡Tu opinión es muy importante!`,
    feedbackBug: `
 Si has encontrado un error o el programa no funciona para ti, por favor escríbelo en el chat
 <a href="{{bugs}}" target='_blank' rel='noreferrer'>en Telegram</a>
 en la sección de "Errores". Intentaré resolver el problema lo más rápido posible.
 `,
    feedbackIdea: `
 Si el programa no cumple con alguna de tus necesidades o si tienes alguna idea interesante,
 por favor házmelo saber en el
 <a href="{{ideas}}" target='_blank'
  rel='noreferrer'>grupo</a> en la sección de "Ideas".
  `,
};
