export default {
    metaTitle: 'Стоимость услуг',
    metaDesc: 'Сколько стоит использование системы удаленного контроля и мониторинга?',
    metaKeys: 'Удаленное управление на предприятии, цена управления компьютерами, мониторинг сотрудников, бесплатное удаленное управление',
    title: 'Стоимость',
    titleD: '5 станций бесплатно',
    text: `
 <p>
 Сервис предоставляет выбор пользователю самому устанавливать цену: 
 от 20 центов до 10 долларов, 5 станций выделяется на бесплатной основе.
 </p>
 <p>
 Эта возможность так-же служит вашей оценкой идеи этого проекта, чем выше указанная сумма - тем лучше будет становиться данный проект, 
 тем производительней смогут быть сервера, тем меньше будет багов, удобней интерфейс и быстрее взаимодействие,
 а что самое главное - будут появляться новые функции, облегчающие вам управление вашими станциями.
 </p>
`
};
