export default {
    metaTitle: 'Costo dei Servizi',
    metaDesc: 'Quanto costa il sistema di controllo remoto e monitoraggio?',
    metaKeys: 'Controllo remoto in azienda, prezzo gestione computer, monitoraggio dipendenti, controllo remoto gratuito',
    title: 'Costo',
    titleD: 'a partire da 20 centesimi a stazione al mese',
    text: `
 <p>
 Il servizio consente agli utenti di impostare il proprio prezzo: da 20 centesimi a 10 dollari, con 5 stazioni fornite gratuitamente.
 </p>
 <p>
 Questa opzione serve anche come valutazione dell'idea alla base di questo progetto. Più alto è l'importo specificato, migliore sarà questo progetto. Avrà server più potenti, meno bug, un'interfaccia utente più intuitiva e interazioni più veloci. Inoltre, verranno introdotte nuove funzionalità per rendere più facile la gestione delle stazioni.
 </p>
`
};
