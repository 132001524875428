export default {
    metaTitle: 'Administración remota de computadoras y servidores. Monitoreo. Ejecución de comandos remotos.',
    metaDesc: 'El mejor programa para el acceso remoto a computadoras. Control remoto de PC o servidores.',
    metaKeys: 'Acceso remoto gratuito, alternativa a TeamViewer, alternativa a AnyDesk, programa de acceso remoto',
    title: 'Conecta a las estaciones ahora',
    titleD: 'Toma el control total de tus dispositivos',

    showDone: 'Mostrar completados',
    hideDone: 'Ocultar completados',
    showMore: 'Mostrar detalles',
    inQuery: 'En cola',
    hide: 'Ocultar',
    more: 'Más',
    needMoney: 'Financiación necesaria',
    "task0": "Lista de estaciones, agregar una nueva",
    "task1": "Información general sobre la estación",
    "task2": "Procesos. Administración y visualización",
    "task3": "Programas. Administración y visualización",
    "task4": "Dispositivos. Visualización",
    "task5": "Ejecución de comandos remotos",
    "task6": "Gestión de energía",
    "task7": "Historial de comandos",
    "task8": "Gestión de ventanas visuales",
    "task9": "Construcción para Mac",
    "task9Desc":
        '<p>' +
        'Como he estado trabajando en Windows durante muchos años y vendí mi último Mac hace cinco años, ' +
        'intenté construirlo en una máquina virtual. Comenzó perfectamente, pero no pude construir el proyecto ' +
        'porque el tiempo de respuesta para las acciones era de aproximadamente 3-5 segundos, independientemente de si era vmware o virtualbox. ' +
        'Probé todas las posibles variaciones de configuración durante un par de días, pero el resultado fue el mismo. ' +
        'Y solo es posible desarrollar para este sistema operativo en el mismo sistema operativo; es imposible hacerlo en Windows o Linux.' +
        '</p>' +
        '<p>' +
        'Llegué a la conclusión de que necesito comprar un dispositivo con al menos un rendimiento medio en este sistema. ' +
        'Si puedes, por favor apoya el proyecto; estaría muy agradecido. Una vez que se haya recaudado la cantidad necesaria, ' +
        'completaré la tarea actual y trabajaré en esta.' +
        ' <a href="{{lang}}/donate">Puedes apoyar aquí</a>' +
        '</p>',
    "task10": "Panel de usuario",
    "task11": "Traducción a idiomas extranjeros",
    "task12": "Autenticación de dos factores",
    "task13": "Línea de comandos remota",
    "task13Desc": `<p>Similar a un cliente SSH pero en el navegador.</p>`,
    "task14": "Instalador en formato MSI",
    "task15": "Seguimiento de procesos en un gráfico",
    "task16": "Documentación de API",
    "task17": "Apertura del código fuente del cliente",
    "task18": "Gestión del espacio en disco",
    "task19": "Visualización de registros",
    "task20": "Acceso temporal",
    "task21": "Aplicación móvil",
    "task22": "Reenvío de puertos",
    "task23": "Generación de programas generados por el usuario",
    "task23Desc": `<p>Se creará un archivo de inicio y, cuando el destinatario lo abra en su dispositivo, el usuario que envió el archivo se agregará a la lista permitida.</p>`,
    "task24": "Funciones de administración en el cliente",
    "task24Desc": `<p>La capacidad de acceder al panel de control de esta estación o cualquier otra sin usar un navegador.</p>`,
    "task25": "Diferenciación de permisos de acceso y control"
};
