import axios from "axios";
import Variables from "./variables";

export default class APIService {
    static classInstance: APIService = null;
    api; // AxiosInstance

    constructor() {
        this.api = axios.create({
            baseURL: Variables.urlApi,
            responseType: "json",
            withCredentials: true,
        });
        this.api.interceptors.response.use(function (response) {
            return response.data;
        }, function (error) {
            return Promise.reject(error);
        });
    }

    static getInstance() {
        if (APIService.classInstance === null) {
            APIService.classInstance = new this();
        }
        return this.classInstance;
    }

    request(path, query): Promise<any> {
        return new Promise(async (resolve, reject) => {
            this.api.post(path, query).then(answer => {
                resolve(answer)
            }).catch((error) => {
                console.error(error);
                reject(error)
            })
        })
    }

    getData(host, name) {
        return axios.get(host + '/data/' + name).catch((error) => {
            console.error(error);
        }).then((data) => {
            console.log(data)
        });
    }
}
