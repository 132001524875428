export default {
    metaTitle: 'Gestion à distance des ordinateurs et des serveurs. Surveillance. Exécution de commandes à distance.',
    metaDesc: 'Le meilleur programme pour l\'accès à distance aux ordinateurs. Contrôle à distance du PC ou du serveur.',
    metaKeys: 'Accès distant gratuit, alternative à Teamviewer, alternative à AnyDesk, programme d\'accès distant',
    title: 'Connectez-vous aux stations maintenant',
    titleD: 'Prenez le contrôle total de vos appareils',
    download: 'Télécharger',
    possibilities: 'Possibilités',
    possibilitiesF: 'Possibilités futures',
    inv: 'Inventaire des stations',
    vis: 'Contrôle visuel <br/>comme Teamviewer/AnyDesk',
    manage: 'Gestion et visualisation :',
    proc: 'Processus en cours',
    prog: 'Programmes',
    dev: 'Appareils',
    commands: 'Commandes',
    power: 'Gestion de l\'alimentation',
    comDo: 'Exécution de commandes',
    launch: 'Lancement sans installation',
    security: 'Sécurité',
    onlyYou: 'Vous seul pouvez envoyer des commandes à vos stations',
    itIsAKey: 'Tout cela grâce à une clé unique stockée avec vous',
    nobodyCan: 'Ni le service ni personne d\'autre ne peut contrôler vos stations sans votre connaissance',
    youTooF: 'Vous pouvez influencer l\'avenir du projet',
    youIdeas: 'Proposez de nouvelles idées, votez, participez à la vie du projet',
    helpYou: 'Le système met en œuvre vos souhaits et introduit de nouvelles fonctionnalités',
    howToUse: 'Comment l\'utiliser ?',
    howToUseText: '1. <a href="/{{lang}}/downloads">Téléchargez</a> et installez le programme sur vos appareils<br/>' +
        '2. Inscrivez-vous dans le <a href="{{reg}}" target=\'_blank\' rel=\'noreferrer\'>panneau de contrôle</a><br/>' +
        '3. Ajoutez une station en entrant l\'identifiant et le mot de passe fournis dans le programme installé<br/>' +
        '4. Prenez le contrôle !',
    applyTelegram: 'Rejoignez le projet sur Telegram',
    newsG: 'Groupe de nouvelles',
    chat: 'Chat général',
};
