export default {
  platforms : [
    {key: 'win', name: 'Windows', typeBuilds: ['gui', 'console']},
    {key: 'linux', name: 'Linux', typeBuilds: ['console']},
    {key: 'mac', name: 'MacOS', typeBuilds: ['gui']}
  ],
  typeBuilds : [
    {key: 'gui', name: '图形界面', platforms: ['win', 'mac']},
    {key: 'console', name: '控制台', platforms: ['win', 'linux']},
  ],
};
