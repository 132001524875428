export default {
    metaTitle: 'परियोजना का प्रायोजक बनें',
    metaDesc: 'दूरस्थ नियंत्रण और कंप्यूटर मॉनिटरिंग परियोजना में भाग लेने का मौका',
    metaKeys: 'परियोजना का प्रायोजनक, परियोजना समर्थन',
    title: 'परियोजना समर्थन',
    text: `

 <p>
 बहुत महीनों से, मैं इस परियोजना का विकास, रखरखाव और वित्तीय सहायता कर रहा हूँ। हालांकि, मैं धीरे-धीरे कुछ समस्याओं का सामना कर रहा हूँ जिनके लिए वित्तीय सहायता की आवश्यकता है।
 </p>
 <p>
 यदि आपके पास यह संभावना हो तो, कृपया सेवा का समर्थन करें। मैं बहुत आभारी होगा! दान की राशि के बावजूद, यह मतलब होगा कि आप मेरे परियोजना को मूल्यवान मानते हैं और उसके विकास में विश्वास रखते हैं, जैसा कि मैं करता हूँ। यह समर्थन मेरे प्रेरणा को काफी मजबूत करेगा।
 </p>
 <p>
 <div class="{{subtitle1}}">
 पैसे भेजने का तरीका
 </div>
 क्रिप्टो-अनार्किज्म के मूल्यों और दृष्टियों का पालन करते हुए, मेरे पास केवल क्रिप्टोकरेंसी वॉलेट्स हैं। नीचे एक निर्देश है कि इसे बैंक कार्ड से कैसे तबादला किया जाए:
 <div class="{{code}}">
USDT <span class="{{green}}">bep20 (Binance)</span>: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
BTC: bc1qyz2gken4mxgfquqfmvrevyttycdxtp349ve7kz <br/><br/>
ETH: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
DOGE: D8aQgYPjkpN92AJiRokhHaSWw9LBG9g6xT
</div>
<div class="{{subtitle2}}">निर्देश</div>
<ul class="{{instruction}}">
<li> सीधे - यदि आपके पास USDT bep20 सिक्कों के साथ ई-वॉलेट है।</li>
<li> विनिमयकर्ता के माध्यम से, यहां से चुनें, उदाहरण के लिए, <a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html" target=_blank rel=noreferrer>bestchange.com</a>:
<ul>
<li> विनिमयकर्ता मॉनिटरिंग पेज पर जाएं</li>
<li> "देता है" स्तंभ में, "Visa/Mastercard" जैसी उपयुक्त भुगतान विधि का चयन करें। 
मैं सिफारिश करता हूँ कि अपने देश के लिए सबसे कम ब्याज दर वाली भुगतान विधि को इस स्तंभ में चुनें (लगभग 3-5%), आमतौर पर स्थानीय मुद्रा में लोकप्रिय बैंकों में से एक,
और अमरीकी डॉलर में सीधी हस्तांतरण नहीं।
<li> "प्राप्त करता है" स्तंभ में, इसे Tether bep20 लिखा होना चाहिए</li>
<li> सर्वोत्तम दर या शर्तों वाले एक्सचेंजर का चयन करें</li>
<li> एक्सचेंजर की वेबसाइट पर, आप चाहते हैं जितनी राशि को तोड़ने के लिए डॉलर में दर्ज करें, पुनर्जीविति पता 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 की प्रतिलिपि बनाएं, शेष क्षेत्रों को भरें, और दिए गए विवरणों के अनुसार राशि हस्तांतरित करें</li>
<li> लेनदेन एक्सचेंजर और नेटवर्क द्वारा 20 मिनट के भीतर प्रसंस्कृत किया जाता है</li>
</ul>
</li>
</ul>
यदि आपको कोई समस्या होती है, कृपया मुझसे <a href="{{tgMy}}" target=_blank rel=noreferrer>Telegram</a> पर संपर्क करें, और हम साथ में समाधान निकालेंगे।
</p>
<p>
<div class="{{subtitle1}}">
 निधि का उपयोग कैसे होगा
 </div>
 आवश्यक सुविधाओं की खरीद, सर्वर किराए पर देना, विकास सहायता सेवाओं के लिए भुगतान, परीक्षण, डिजाइन, कुकीज़.
 <br/>
 <br/>
 यदि राशि कुछ महीनों के वेतन से अधिक हो जाती है, तो मैं एक सहायक की भर्ती कर सकता हूँ और नई संस्करणों के विमोचन को काफी तेजी से बढ़ा सकता हूँ।
</p>
<div class="{{subtitle1}}">
 आपके समर्थन के लिए पहले से ही धन्यवाद!
 </div>
 यदि आप बस इस पाठ को पढ़ने के लिए समय निकालते हैं तो भी :)
`
};