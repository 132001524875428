export default {
    metaTitle: '项目路线图',
    metaDesc: '项目中已经实现的任务和等待排队的任务',
    metaKeys: '免费的流程管理程序，程序卸载，远程执行命令，站点清单，设备查看，电源管理',
    title: '路线图',
    titleD: '已实现和未来功能列表',

    showDone: '显示已完成',
    hideDone: '隐藏已完成',
    showMore: '显示更多详情',
    inQuery: '排队中',
    hide: '隐藏',
    more: '更多',
    needMoney: '需要资金',
    "task0": "站点列表，添加新站点",
    "task1": "站点概要信息",
    "task2": "进程管理和查看",
    "task3": "程序管理和查看",
    "task4": "设备查看",
    "task5": "远程执行命令",
    "task6": "电源管理",
    "task7": "命令历史记录",
    "task8": "Windows 可视化控制",
    "task9": "Mac 构建",
    "task9Desc":`
        <p>
        由于我已经在 Windows 上工作了很多年，最后一次卖掉 Mac 是在五年前 -
        我尝试在虚拟机上进行构建，虚拟机成功启动，但是无法构建项目，
        因为对操作的响应时间大约为 3-5 秒，无论是 vmware 还是 virtualbox，我尝试了几天的所有可能的设置，
        但结果都一样。但是，只有在该系统上才能开发，不能在 Windows 或 Linux 上进行开发。
        </p>
        <p>
        我得出结论，我需要购买一台在该系统上至少具有中等性能的设备，
        如果您可以，请支持该项目，我将非常感激。一旦达到所需金额，我将完成当前任务并开始处理这个任务。
        <a href="{{lang}}/donate">可以在这里提供支持</a>
        </p>`,
    "task10": "用户账户",
    "task11": "外语翻译",
    "task12": "双因素身份验证",
    "task13": "远程命令行",
    "task13Desc": `<p>类似于 SSH 客户端，在浏览器中执行。</p>`,
    "task14": "MSI 格式的安装程序",
    "task15": "图表中的进程跟踪",
    "task16": "API 文档",
    "task17": "客户端源代码开放",
    "task18": "磁盘空间管理",
    "task19": "查看日志",
    "task20": "临时访问权限",
    "task21": "移动应用程序",
    "task22": "端口转发",
    "task23": "用户生成程序",
    "task23Desc": `<p>将创建一个启动程序文件，当用户在其设备上打开该文件时，发送文件的用户将被添加到允许列表中。</p>`,
    "task24": "客户端管理功能",
    "task24Desc": `<p>能够访问此站点或其他站点的控制面板，无需使用浏览器。</p>`,
    "task25": "查看和控制权限分离"
};
