export default {
    idea: 'Soumettre une idée',
    ideaT: 'Le système est ouvert à vos idées et vos commentaires ! Écrivez à ce sujet dans notre groupe sur <a href="{{tg}}" target="_blank" rel="noreferrer">Telegram</a> ou envoyez-les-moi en privé. <br/>Votre participation sous quelque forme que ce soit aidera le projet à avancer. Merci d\'avance !',
    metaTitle: 'Retours',
    metaDesc: 'Signaler un problème avec le service de contrôle à distance sur ordinateur "Cusco Remote Control"',
    metaKeys: '',
    title: 'Retours',
    text: `
Groupe Telegram pour les discussions : <a href="{{tg}}" target='_blank' rel='noreferrer'>cusco_rc_group</a><br/>
Un sujet dédié a été créé pour chaque sujet :
<ul>
<li><a href="{{tgIdea}}" target='_blank' rel='noreferrer'>Idées</a> - les fonctionnalités que vous aimeriez voir dans le projet</li>
<br/><li><a href="{{tgBugs}}" target='_blank' rel='noreferrer'>Problèmes</a> - si vous rencontrez une erreur ou en anticipez une. Pour les problèmes liés à la sécurité, il est préférable de me les envoyer en privé.</li>
<br/><li><a href="{{tgMain}}" target='_blank' rel='noreferrer'>Général</a> - discussions sur des sujets généraux, sondages</li>
</ul>
`
};
