export default {
    metaTitle: 'Recursos de Acesso Remoto: Controle, Monitoramento, Estatísticas, Inventário, Gerenciamento de Servidores e Computadores',
    metaDesc: 'Um serviço profissional para administradores de sistema e usuários comuns para controle remoto de computadores. Uma alternativa superior aos programas estrangeiros de acesso remoto. Desenvolvimento ativo e implementação de recursos solicitados pelos usuários. Gerenciamento de processos, remoção de programas, execução remota de comandos, inventário de estações, visualização de dispositivos, gerenciamento de energia.',
    metaKeys: 'Programa gratuito para gerenciamento de processos, remoção de programas, execução remota de comandos, inventário de estações, visualização de dispositivos, gerenciamento de energia',
    title: 'Recursos do Sistema',
    startUse: 'Começar a Usar',
    remoteControl: 'Controle Remoto',
    remoteControlT: 'Controle remoto padrão de desktop. Similar ao TeamViewer, AnyDesk, Ammyy Admin.<br/><br/>Você pode se conectar a partir de um computador ou telefone celular através de um navegador.',
    invent: 'Inventário de Estações',
    inventT: 'Precisa fazer um inventário e não quer se conectar a cada máquina?<br/><br/>Basta verificar as características de todos os dispositivos no painel de controle.',
    process: 'Processos',
    processT: 'Você pode visualizar e filtrar todos os processos em execução na estação a qualquer momento e encerrá-los manualmente, se necessário.',
    programs: 'Programas e Dispositivos',
    programsT: 'Verifique todas as aplicações instaladas e remova-as do dispositivo, se desejar.<br/><br/>Quer saber exatamente quais equipamentos estão instalados? Sem problemas.',
    remoteCommand: 'Execução de Comandos Remotos',
    remoteCommandT: 'Há situações em que você só precisa executar um comando para resolver um problema ou descobrir algo, e não deseja se conectar. Você pode enviá-lo com apenas um clique e obter o resultado da execução.<br/><br/>O gerenciamento de energia funciona da mesma forma. Reinicie, bloqueie ou coloque a estação em suspensão com apenas alguns cliques.',
    feature: 'Lista de Recursos Futuros',
    idea: 'Enviar uma Ideia',
    ideaT: 'O sistema está aberto às suas ideias e feedback! Escreva sobre eles em nosso grupo do Telegram <a href="{{tg}}" target="_blank" rel="noreferrer">aqui</a> ou me envie uma mensagem direta.<br/>Sua participação, de qualquer forma, ajudará o projeto a avançar. Obrigado antecipadamente!',
};
