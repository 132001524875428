export default {
    idea: 'إرسال فكرة',
    ideaT: 'النظام مفتوح لأفكارك وملاحظاتك! اكتب عنها في مجموعتنا على <a href="{{tg}}" target="_blank" rel="noreferrer">Telegram</a> أو أرسلها لي بشكل خاص. <br/>مشاركتك بأي شكل من الأشكال ستساعد المشروع على المضي قدمًا. شكرًا لك مقدمًا!',

    metaTitle: 'ملاحظات',
    metaDesc: 'الإبلاغ عن مشكلة في خدمة التحكم عن بُعد "كوسكو ريموت كونترول" على سطح المكتب',
    metaKeys: '',
    title: 'ملاحظات',
    text: `
مجموعة Telegram للمناقشة: <a href="{{tg}}" target='_blank' rel='noreferrer'>cusco_rc_group</a><br/>
تم إنشاء موضوع مخصص لكل موضوع:
<ul>
<li><a href="{{tgIdea}}" target='_blank' rel='noreferrer'>الأفكار</a> - الميزات التي ترغب في رؤيتها في المشروع</li>
<br/><li><a href="{{tgBugs}}" target='_blank' rel='noreferrer'>الأخطاء</a> - إذا واجهت خطأً أو توقعت وجود خطأ. بالنسبة للأخطاء المتعلقة بالأمان، من الأفضل إرسالها لي بشكل خاص.</li>
<br/><li><a href="{{tgMain}}" target='_blank' rel='noreferrer'>عامة</a> - مناقشات حول مواضيع عامة، استطلاعات الرأي</li>
</ul>
`
};
