export default {
    idea: '아이디어 제출',
    ideaT: '시스템은 여러분의 아이디어와 피드백을 환영합니다! <a href="{{tg}}" target="_blank" rel="noreferrer">Telegram</a> 그룹이나 개인적으로 보내주세요. <br/>여러분의 참여는 프로젝트를 발전시키는 데 큰 도움이 될 것입니다. 미리 감사드립니다!',

    metaTitle: '피드백',
    metaDesc: '데스크탑 원격 제어 서비스 "Cusco Remote Control"의 문제 신고',
    metaKeys: '',
    title: '피드백',
    text: `
토론을 위한 Telegram 그룹: <a href="{{tg}}" target='_blank' rel='noreferrer'>cusco_rc_group</a><br/>
각 주제에는 전용 스레드가 생성되었습니다:
<ul>
<li><a href="{{tgIdea}}" target='_blank' rel='noreferrer'>아이디어</a> - 프로젝트에서 보고 싶은 기능들</li>
<br/><li><a href="{{tgBugs}}" target='_blank' rel='noreferrer'>버그</a> - 오류를 발견하거나 예상하는 경우. 보안 관련 버그의 경우 개인적으로 보내는 것이 좋습니다.</li>
<br/><li><a href="{{tgMain}}" target='_blank' rel='noreferrer'>일반</a> - 일반 주제에 대한 토론, 설문 조사</li>
</ul>
`
};
