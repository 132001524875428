export default {
    metaTitle:  'Удаленное управление компьютерами, серверами. Мониторинг. Удаленное выполнение команд. ',
    metaDesc:  'Лучшая программа для удаленного доступа к компьютерам. Remote pc or server control',
    metaKeys:  'Удаленный доступ бесплатно, аналог Teamviewer, аналог AnyDesk, программа удаленного доступа',
    title:  'Подключись к станциям сейчас',
    titleD:  'Получи полный контроль над своими устройствами',
    download:  'Скачать',
    possibilities:  'Возможности',
    possibilitiesF:  'Будущие возможности',
    inv:  'Инвентаризация станций',
    vis:  'Визуальное управление <br/>как Teamviewer/AnyDesk',
    manage:  'Управление и просмотр:',
    proc:  'Запущенные процессы',
    prog:  'Программы',
    dev:  'Устройства',
    commands: 'Команды',
    power:  'Управление питанием',
    comDo: 'Выполнение команд' ,
    launch:  'Запуск без установки',
    security:  'Безопасность',
    onlyYou:  'Только вы можете отправлять команды на свои станции',
    itIsAKey:  'Всё благодаря уникальному ключу, хранящимся у вас',
    nobodyCan:  'Ни сам сервис, ни кто либо другой не сможет управлять вашими станциями без вашего ведома',
    youTooF:  'Вы можете повлиять на будущее проекта',
    youIdeas:  'Предлагайте новые идеи, голосуйте, участвуйте в жизни проекта',
    helpYou:  'Система, реализует ваши пожелания и внедрит новые возможности',
    howToUse:  'Как пользоваться?',
    howToUseText:  '1. <a href="/{{lang}}/downloads">Скачайте</a> и установите программу на свои устройства<br/>' +
        '2. Зарегистрируйтесь в <a href="{{reg}}" target=\'_blank\' rel=\'noreferrer\'>панели управления</a><br/>' +
        '3. Добавьте станцию, указав ID и пароль заданный в установленной программе<br/>' +
        '4. Управляйте!',
    applyTelegram: 'Присоединяйтесь к проекту в Телеграмм',
    newsG: 'Новостная группа',
    chat: 'Общий чат',
};
