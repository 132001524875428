export default {
    metaTitle: 'Gerenciamento remoto de computadores e servidores. Monitoramento. Execução remota de comandos.',
    metaDesc: 'O melhor programa para acesso remoto a computadores. Controle remoto de PCs ou servidores.',
    metaKeys: 'Acesso remoto gratuito, alternativa ao Teamviewer, alternativa ao AnyDesk, programa de acesso remoto',
    title: 'Conecte-se às estações agora',
    titleD: 'Assuma o controle total dos seus dispositivos',
    download: 'Baixar',
    possibilities: 'Possibilidades',
    possibilitiesF: 'Possibilidades futuras',
    inv: 'Inventário da estação',
    vis: 'Controle visual <br/>como o Teamviewer/AnyDesk',
    manage: 'Gerenciamento e visualização:',
    proc: 'Processos em execução',
    prog: 'Programas',
    dev: 'Dispositivos',
    commands: 'Comandos',
    power: 'Gerenciamento de energia',
    comDo: 'Execução de comandos',
    launch: 'Iniciar sem instalação',
    security: 'Segurança',
    onlyYou: 'Somente você pode enviar comandos para suas estações',
    itIsAKey: 'Tudo isso graças a uma chave única armazenada com você',
    nobodyCan: 'Nem o serviço nem mais ninguém pode controlar suas estações sem o seu conhecimento',
    youTooF: 'Você pode influenciar o futuro do projeto',
    youIdeas: 'Sugira novas ideias, vote, participe da vida do projeto',
    helpYou: 'O sistema implementa seus desejos e introduz novos recursos',
    howToUse: 'Como usar?',
    howToUseText: '1. <a href="/{{lang}}/downloads">Baixe</a> e instale o programa em seus dispositivos<br/>' +
        '2. Registre-se no <a href="{{reg}}" target=\'_blank\' rel=\'noreferrer\'>painel de controle</a><br/>' +
        '3. Adicione uma estação inserindo o ID e a senha fornecidos no programa instalado<br/>' +
        '4. Assuma o controle!',
    applyTelegram: 'Participe do projeto no Telegram',
    newsG: 'Grupo de notícias',
    chat: 'Chat geral',
};
