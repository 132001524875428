export default {
    metaTitle: 'تكلفة الخدمات',
    metaDesc: 'كم تكلف نظام التحكم عن بعد والمراقبة؟',
    metaKeys: 'التحكم عن بعد في المؤسسة، سعر إدارة الحاسوب، مراقبة الموظفين، التحكم عن بعد مجانًا',
    title: 'التكلفة',
    titleD: 'ابتداءً من 20 سنتًا لكل محطة شهريًا',
    text: `
 <p>
 يتيح للمستخدمين الخدمة تحديد السعر الخاص بهم: من 20 سنتًا إلى 10 دولارات، مع توفير 5 محطات مجانًا.
 </p>
 <p>
 تعد هذه الخيارات أيضًا تقييمًا لفكرة هذا المشروع. كلما زادت القيمة المحددة، زاد هذا المشروع بجودة أفضل. سيكون لديه خوادم أكثر قوة وأقل عدد من الأخطاء وواجهة أكثر سهولة للاستخدام وتفاعلات أسرع. والأهم من ذلك، سيتم تقديم ميزات جديدة لتسهيل إدارة المحطات بالنسبة لك.
 </p>
`
};
