export default {
    metaTitle: 'रिमोट कंप्यूटर और सर्वर प्रबंधन। मॉनिटरिंग। रिमोट कमांड निष्पादन।',
    metaDesc: 'कंप्यूटरों के लिए रिमोट एक्सेस के लिए सर्वश्रेष्ठ कार्यक्रम। रिमोट पीसी या सर्वर नियंत्रण।',
    metaKeys: 'मुफ्त रिमोट एक्सेस, Teamviewer का वैकल्पिक, AnyDesk का वैकल्पिक, रिमोट एक्सेस प्रोग्राम',
    title: 'अब स्टेशनों से कनेक्ट करें',
    titleD: 'अपने उपकरणों पर पूर्ण नियंत्रण लें',
    download: 'डाउनलोड करें',
    possibilities: 'संभावनाएँ',
    possibilitiesF: 'भविष्य की संभावनाएं',
    inv: 'स्टेशन इन्वेंटरी',
    vis: 'टीमव्यूअर/एनीडेस्क की तरह दृश्यमान नियंत्रण<br/>',
    manage: 'प्रबंधन और दृश्यभेद:',
    proc: 'चल रहे प्रक्रियाएँ',
    prog: 'प्रोग्राम',
    dev: 'उपकरण',
    commands: 'कमांड',
    power: 'शक्ति प्रबंधन',
    comDo: 'कमांड निष्पादन',
    launch: 'इंस्टालेशन के बिना चालू करें',
    security: 'सुरक्षा',
    onlyYou: 'केवल आप ही अपने स्टेशनों को कमांड भेज सकते हैं',
    itIsAKey: 'आपके साथ संग्रहीत एक अद्वितीय कुंजी के कारण ही यह संभव होता है',
    nobodyCan: 'सेवा या कोई भी अन्य व्यक्ति आपके ज्ञान के बिना आपके स्टेशनों को नियंत्रित नहीं कर सकते',
    youTooF: 'आप परियोजना के भविष्य पर प्रभाव डाल सकते हैं',
    youIdeas: 'नए विचारों की सुझाव दें, मतदान करें, परियोजना के जीवन में भाग लें',
    helpYou: 'सिस्टम आपकी इच्छाओं को कार्यान्वित करता है और नए सुविधाओं को प्रस्तुत करता है',
    howToUse: 'कैसे उपयोग करें?',
    howToUseText: '1. अपने उपकरणों पर <a href="/{{lang}}/downloads">प्रोग्राम</a> डाउनलोड और स्थापित करें<br/>' +
        '2. <a href="{{reg}}" target=\'_blank\' rel=\'noreferrer\'>नियंत्रण पैनल</a> में पंजीकरण करें<br/>' +
        '3. स्थापित प्रोग्राम में प्रदान किए गए आईडी और पासवर्ड द्वारा एक स्टेशन जोड़ें<br/>' +
        '4. नियंत्रण लें!',
    applyTelegram: 'टेलीग्राम पर प्रोजेक्ट में शामिल हों',
    newsG: 'समाचार समूह',
    chat: 'सामान्य चैट',
};
