export default {
    metaTitle: 'Скачать программу удаленного доступа. Windows, Linux, MacOS.',
    metaDesc: 'Скачать последнюю версию для полного контроля пк и серверов',
    metaKeys: 'скачать cusco, cusco win, cusco linux, cusco macos',
    title: 'Выберите свою платформу',
    download: 'Скачать',
    version: 'Версия',
    versions: 'Версии',
    versionsT: `Существует две версии программы:
 <ul>
 <li>c графическим интерфейсом -- весит больше, но управлять удобней</li>
 <li>с терминальным интерфейсом -- весит меньше, работает в консоли, для управления
 используется клавиатура.
 Предпочтительная версия, если у вас старая версия системы или не работает графический
 интерфейс
 </li>
 </ul>`,

    install: 'Установка',
    installTWin: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>Скачайте</a> и запустите программу<br/>
 2. Зарегистрируйтесь в <a href="{{panel}}" target='_blank' rel='noreferrer'>панели
 управления</a><br/>
 3. Добавьте станцию, указав ID и пароль заданный в программе на устройстве<br/>
 4. Управляйте!
 <br/><br/>`,
    installTLinux: `
 1. Скачайте программу командой:<br/>
 <div class="{{sCode}}">
 cd ~<br/>
 wget {{installLink}}<br/>
 </div>
 2. Запустите:<br/>
 <div class="{{sCode}}">
 sudo chmod +x {{fileName}}<br/>
 sudo ./{{fileName}}<br/>
 </div>

 3. Зарегистрируйтесь в <a href="{{panel}}" target='_blank' rel='noreferrer'>панели
 управления</a><br/>
 4. Добавьте станцию, указав ID и пароль заданный в программе на устройстве<br/>
 5. Управляйте!

 <br/><br/>
 Примечание: При установке создается сервис запускающийся при включении компьютера.<br/>
 Открыть программу в консоли вы можете командой "cusco".<br/>
 Получить список всех команд: "cusco --help"`,
    inProgress: `В процессе реализации`,
    installTMac: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>Скачайте</a> и запустите программу<br/>
 2. Зарегистрируйтесь в <a href="{{panel}}" target='_blank' rel='noreferrer'>панели
 управления</a><br/>
 3. Добавьте станцию, указав ID и пароль заданный в программе на устройстве<br/>
 4. Управляйте!
 <br/><br/>`,
    mainCommands: 'Основные команды',
    mainCommandsT: `<ul>
 <li>-i или --install - быстрая установка</li>
 <li>-u или --uninstall - быстрое удаление</li> 
 <li>-h или --help - помощь</li> 
 <li>--update - обновить на новую версию</li> 
 </ul>
 В графической версии команда будет исполнена, но вывод в консоль будет отсутствовать (и за особенности сборки).
`,
    feedbackTitle: `Ваша обратная связь очень важна!`,
    feedbackBug: `
 Если вы заметили баг или у вас не работает программа - пишите в чат
 <a href="{{bugs}}" target='_blank' rel='noreferrer'>в телеграмме</a>
 в раздел 'Баги', я постараюсь решить проблему как можно быстрее
 `,
    feedbackIdea: `
 Если программа не удовлетворяет какие либо ваши потребности или у вас
 есть интересные мысли - дайте знать о них в
 <a href="{{ideas}}" target='_blank'
  rel='noreferrer'>группе</a> в разделе 'Идеи'
  `,
};
