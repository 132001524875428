export default {
    metaTitle: 'सेवाओं की लागत',
    metaDesc: 'दूरस्थ नियंत्रण और मॉनिटरिंग प्रणाली की कीमत क्या है?',
    metaKeys: 'उद्यम में दूरस्थ नियंत्रण, कंप्यूटर प्रबंधन की कीमत, कर्मचारी मॉनिटरिंग, नि: शुल्क दूरस्थ नियंत्रण',
    title: 'लागत',
    titleD: 'मासिक रूप में प्रति स्टेशन से 20 सेंट से शुरू होता है',
    text: `
 <p>
 सेवा उपयोगकर्ताओं को खुद की कीमत निर्धारित करने की अनुमति देती है: 20 सेंट से 10 डॉलर तक, मुफ्त में 5 स्टेशन प्रदान किए जाते हैं।
 </p>
 <p>
 यह विकल्प इस परियोजना के पीछे की विचारधारा का आपका मूल्यांकन भी है। निर्धारित राशि जितनी अधिक होगी, यह परियोजना उत्कृष्ट होगी। यह शक्तिशाली सर्वर, कम बग, उपयोगकर्ता के लिए अधिक सुविधाजनक अंतराल और तेज़ इंटरैक्शन के साथ आएगा। सबसे महत्वपूर्ण बात यह है कि स्टेशन प्रबंधन को आसान बनाने के लिए नई सुविधाएं पेश की जाएंगी।
 </p>
`
};
