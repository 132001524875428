export default {
    metaTitle:  'Возможности удаленного доступа: контроль, мониторинг, статистика, инвентаризация, управление серверами и компьютерами',
    metaDesc:  'Профессиональный сервис для системных администраторов и обычных пользователей для удаленного контроля за компьютерами. ' +
        'Российский аналог превосходящий зарубежные программы удаленного доступа.' +
        'Активная разработка и реализация потребностей по запросу от юзеров. ' +
        'Управление процессами, удаление программ, удаленное выполнение команд, инвентаризация станций, просмотр устройств, управление питанием',
    metaKeys:  'Бесплатная программа управление процессами, удаление программ, удаленное выполнение команд, инвентаризация станций, просмотр устройств, управление питанием',
    title:  'Возможности системы',
    startUse:  'Начать пользоваться',
    remoteControl:  'Удаленное управление',
    remoteControlT:  'Стандартное управление удаленным рабочим столом. Аналог Teamviewer, AnyDesk, Ammyy Admin.' +
        '<br/>' +
        '<br/>' +
        'Можно подключаться с компьютера или мобильного телефона через браузер',
    invent:  'Инвентаризация станций',
    inventT:  'Бухгалтерия требует провести инвентаризацию, а вам лень подключаться к каждой' +
        'машине?<br/><br/>Просто посмотрите характеристики всех устройств в панели управления.',
    process:  'Процессы',
    processT:  'Вы сможете в любой момент времени посмотреть, отфильтровать все процессы' +
        ' выполняемые на станции, a в случае необходимости завершить их вручную',
    programs:  'Программы и устройства',
    programsT:  ' Посмотрите все установленные приложения и если захотите - удалите их с устройства' +
        '<br/>' +
        '<br/>' +
        'Хотите точно знать, какое оборудование установлено? Без проблем.',
    remoteCommand:  'Удаленное выполнение команд',
    remoteCommandT:  'Бывают ситуации, когда вам нужно выполнить всего одну команду, что бы решить' +
        'проблему или что-то узнать, а подключаться не хочется. Отправить её можно нажав' +
        'всего одну кнопку и получить результат выполнения' +
        '<br/>' +
        '<br/>' +
        'Так же просто работает и управление питанием. В пару кликов перезагрузите,' +
        ' заблокируйте или отправьте в сон станцию',
    feature:  'Список будущих возможностей',
    idea:  'Предложить идею',
    ideaT:  'Система открыта к вашим идеям и замечаниям!' +
        ' Напишите о них в нашей группе в <a href="{{tg}}"' +
        ' target="_blank" rel="noreferrer">телеграмме</a> или мне в личку. <br/>' +
        'Ваше участие в любом виде поможет проекту двигаться дальше. Заранее спасибо!',
   

};
