export default {
    metaTitle: 'Soutenez le projet',
    metaDesc: 'Opportunité de participer au développement du projet de contrôle à distance et de surveillance informatique',
    metaKeys: 'Soutenez le projet, soutien au projet',
    title: 'Soutien au projet',
    text: `
 <p>
 Depuis de nombreux mois, je développe, entretiens et finance ce projet seul. Cependant, je rencontre progressivement certains problèmes qui nécessitent un soutien financier.
 </p>
 <p>
 Si vous en avez la possibilité, veuillez soutenir le service. Je vous en serais très reconnaissant ! Peu importe le montant donné, cela signifiera que vous considérez mon projet précieux et que vous croyez en son développement, tout comme moi. Ce soutien renforcera considérablement ma motivation.
 </p>
 <p>
 <div class="{{subtitle1}}">
 Comment envoyer de l'argent
 </div>
 Étant donné que j'adhère aux valeurs et aux points de vue du crypto-anarchisme, je n'ai que des portefeuilles de crypto-monnaie. Voici une instruction sur la façon de les financer à partir d'une carte bancaire :
 <div class="{{code}}">
USDT <span class="{{green}}">bep20 (Binance)</span> : 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
BTC : bc1qyz2gken4mxgfquqfmvrevyttycdxtp349ve7kz <br/><br/>
ETH : 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
DOGE : D8aQgYPjkpN92AJiRokhHaSWw9LBG9g6xT
</div>
<div class="{{subtitle2}}">Instruction</div>
<ul class="{{instruction}}">
<li> Directement - si vous disposez d'un portefeuille électronique avec des pièces USDT bep20.</li>
<li> Par le biais d'un échangeur, choisissez-en un ici, par exemple, <a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html" target=_blank rel=noreferrer>bestchange.com</a> :
<ul>
<li> Accédez à la page de surveillance de l'échangeur</li>
<li> Dans la colonne "Donne", sélectionnez un moyen de paiement adapté, tel que "Visa/Mastercard". Je vous recommande de choisir dans cette colonne le moyen de paiement avec le taux d'intérêt le plus bas pour votre pays (environ 3 à 5 %), généralement l'une des banques populaires dans la devise locale, et non un transfert direct en USD.
<li> Dans la colonne "Reçoit", il doit être indiqué Tether bep20</li>
<li> Choisissez un échangeur avec le meilleur taux ou les meilleures conditions</li>
<li> Sur le site de l'échangeur, saisissez le montant que vous souhaitez financer en dollars, copiez l'adresse de recharge 0x1716a9bAaE728780D98fb26faAe17D79FB62F090, remplissez les champs restants et effectuez le transfert selon les détails fournis</li>
<li> La transaction est traitée par l'échangeur et le réseau dans un délai de 20 minutes</li>
</ul>
</li>
</ul>
Si vous rencontrez des problèmes, veuillez m'écrire sur <a href="{{tgMy}}" target=_blank rel=noreferrer>Telegram</a>, et nous résoudrons cela ensemble.
</p>
<p>
<div class="{{subtitle1}}">
 Comment les fonds seront utilisés
 </div>
 Achat d'équipements nécessaires, paiement de la location de serveurs, paiement de services d'assistance au développement, tests, conception, cookies.
 <br/>
 <br/>
 Si le montant dépasse le salaire de quelques mois, je pourrai engager un assistant et accélérer considérablement la sortie de nouvelles versions.
</p>
<div class="{{subtitle1}}">
 Merci d'avance pour votre soutien !
 </div>
 Même si vous avez simplement pris le temps de lire ce texte :)
`
};
