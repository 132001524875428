export default {
    metaTitle: 'Remote-Zugriffsprogramm herunterladen. Windows, Linux, MacOS.',
    metaDesc: 'Laden Sie die neueste Version herunter, um die vollständige Kontrolle über PCs und Server zu erhalten.',
    metaKeys: 'Download Cusco, Cusco Win, Cusco Linux, Cusco MacOS',
    title: 'Wählen Sie Ihre Plattform',
    download: 'Herunterladen',
    version: 'Version',
    versions: 'Versionen',
    versionsT: `Es gibt zwei Versionen des Programms:
 <ul>
 <li>Mit grafischer Benutzeroberfläche - größer im Speicher, aber bequemer zu bedienen</li>
 <li>Mit terminalbasierter Benutzeroberfläche - kleiner im Speicher, arbeitet in der Konsole und verwendet die Tastatur zur Steuerung.
 Bevorzugte Version, wenn Sie eine ältere Version des Systems haben oder die grafische Benutzeroberfläche nicht funktioniert.
 </li>
 </ul>`,

    install: 'Installation',
    installTWin: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>Laden Sie</a> das Programm herunter und führen Sie es aus.<br/>
 2. Registrieren Sie sich in der <a href="{{panel}}" target='_blank' rel='noreferrer'>Steuerungszentrale</a>.<br/>
 3. Fügen Sie eine Station hinzu, indem Sie die ID und das Passwort angeben, die im Programm auf dem Gerät bereitgestellt wurden.<br/>
 4. Starten Sie die Steuerung!
 <br/><br/>`,
    installTLinux: `
 1. Laden Sie das Programm mit dem Befehl herunter:<br/>
 <div class="{{sCode}}">
 cd ~<br/>
 wget {{installLink}}<br/>
 </div>
 2. Führen Sie das Programm aus:<br/>
 <div class="{{sCode}}">
 sudo chmod +x {{fileName}}<br/>
 sudo ./{{fileName}}<br/>
 </div>

 3. Registrieren Sie sich in der <a href="{{panel}}" target='_blank' rel='noreferrer'>Steuerungszentrale</a>.<br/>
 4. Fügen Sie eine Station hinzu, indem Sie die ID und das Passwort angeben, die im Programm auf dem Gerät bereitgestellt wurden.<br/>
 5. Starten Sie die Steuerung!

 <br/><br/>
 Hinweis: Während der Installation wird ein Dienst erstellt, der beim Einschalten des Computers gestartet wird.<br/>
 Sie können das Programm in der Konsole öffnen, indem Sie den Befehl "cusco" verwenden.<br/>
 Um eine Liste aller Befehle zu erhalten, verwenden Sie "cusco --help".`,
    inProgress: `In Bearbeitung`,
    installTMac: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>Laden Sie</a> das Programm herunter und führen Sie es aus.<br/>
 2. Registrieren Sie sich in der <a href="{{panel}}" target='_blank' rel='noreferrer'>Steuerungszentrale</a>.<br/>
 3. Fügen Sie eine Station hinzu, indem Sie die ID und das Passwort angeben, die im Programm auf dem Gerät bereitgestellt wurden.<br/>
 4. Starten Sie die Steuerung!
 <br/><br/>`,
    mainCommands: 'Hauptbefehle',
    mainCommandsT: `<ul>
 <li>-i oder --install - schnelle Installation</li>
 <li>-u oder --uninstall - schnelle Deinstallation</li> 
 <li>-h oder --help - Hilfe</li> 
 <li>--update - auf die neueste Version aktualisieren</li> 
 </ul>
 In der grafischen Version wird der Befehl ausgeführt, es wird jedoch keine Ausgabe in der Konsole angezeigt (aufgrund der Besonderheiten des Builds).
`,
    feedbackTitle: `Ihr Feedback ist sehr wichtig!`,
    feedbackBug: `
 Wenn Sie einen Fehler bemerkt haben oder das Programm bei Ihnen nicht funktioniert, schreiben Sie bitte in den Chat
 <a href="{{bugs}}" target='_blank' rel='noreferrer'>auf Telegram</a>
 im Abschnitt "Fehler". Ich werde versuchen, das Problem so schnell wie möglich zu lösen.
 `,
    feedbackIdea: `
 Wenn das Programm Ihren Anforderungen nicht entspricht oder wenn Sie interessante Gedanken haben,
 lassen Sie es mich bitte wissen in der
 <a href="{{ideas}}" target='_blank'
  rel='noreferrer'>Gruppe</a> im Abschnitt "Ideen".
  `,
};
