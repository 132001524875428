export default {
    metaTitle: '원격 컴퓨터 및 서버 관리. 모니터링. 원격 명령 실행.',
    metaDesc: '컴퓨터에 대한 원격 액세스를 위한 최고의 프로그램. 원격 PC 또는 서버 제어.',
    metaKeys: '무료 원격 액세스, TeamViewer 대안, AnyDesk 대안, 원격 액세스 프로그램',
    title: '지금 바로 스테이션에 연결',
    titleD: '장치를 완전히 제어하세요',
    download: '다운로드',
    possibilities: '기능',
    possibilitiesF: '미래의 가능성',
    inv: '스테이션 인벤토리',
    vis: 'TeamViewer/AnyDesk와 유사한 시각적 제어<br/>',
    manage: '관리 및 보기:',
    proc: '실행 중인 프로세스',
    prog: '프로그램',
    dev: '장치',
    commands: '명령어',
    power: '전원 관리',
    comDo: '명령 실행',
    launch: '설치 없이 실행',
    security: '보안',
    onlyYou: '스테이션에 명령을 보낼 수 있는 것은 오직 당신뿐입니다',
    itIsAKey: '고유한 키가 당신과 함께 저장되기 때문입니다',
    nobodyCan: '서비스나 다른 사람은 당신의 동의 없이 당신의 스테이션을 제어할 수 없습니다',
    youTooF: '프로젝트의 미래에 영향을 줄 수 있습니다',
    youIdeas: '새로운 아이디어를 제안하고 투표하며 프로젝트에 참여하세요',
    helpYou: '시스템은 당신의 바람을 구현하고 새로운 기능을 도입합니다',
    howToUse: '사용 방법',
    howToUseText: '1. 당신의 장치에 <a href="/{{lang}}/downloads">프로그램을</a> 다운로드하고 설치하세요<br/>' +
        '2. <a href="{{reg}}" target=\'_blank\' rel=\'noreferrer\'>컨트롤 패널에</a> 등록하세요<br/>' +
        '3. 설치된 프로그램에 제공된 ID와 패스워드를 입력하여 스테이션을 추가하세요<br/>' +
        '4. 제어하세요!',
    applyTelegram: '텔레그램에서 프로젝트 가입',
    newsG: '뉴스 그룹',
    chat: '일반 채팅',
};
