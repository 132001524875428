export default {
    metaTitle: 'रिमोट एक्सेस क्षमताएँ: नियंत्रण, मॉनिटरिंग, आँकड़े, इन्वेंटरी, सर्वर और कंप्यूटर प्रबंधन',
    metaDesc: 'कंप्यूटरों का दूरस्थ नियंत्रण करने के लिए प्रोफेशनल सेवा। विदेशी दूरस्थ पहुंच कार्यक्रमों को आदान-प्रदान करने का एक विकल्प। उपयोगकर्ता की मांग पर गतिविधि के विकास और कार्यान्वयन। प्रक्रिया प्रबंधन, प्रोग्राम हटाना, दूरस्थ आदेश निष्पादन, स्थानिक जाँच, उपकरण देखना, शक्ति प्रबंधन।',
    metaKeys: 'प्रक्रिया प्रबंधन के लिए मुफ्त कार्यक्रम, प्रोग्राम हटाना, दूरस्थ आदेश निष्पादन, स्थानिक जाँच, उपकरण देखना, शक्ति प्रबंधन',
    title: 'सिस्टम क्षमताएँ',
    startUse: 'उपयोग शुरू करें',
    remoteControl: 'रिमोट नियंत्रण',
    remoteControlT: 'मानक रिमोट डेस्कटॉप नियंत्रण। TeamViewer, AnyDesk, Ammyy Admin जैसा।<br/><br/>आप एक कंप्यूटर या मोबाइल फ़ोन से एक ब्राउज़र के माध्यम से कनेक्ट कर सकते हैं।',
    invent: 'स्टेशन इन्वेंटरी',
    inventT: 'हर उपकरण की गणना की जरूरत होती है, और आप हर मशीन से जुड़ना नहीं चाहते हैं?<br/><br/>केवल नियंत्रण पैनल में सभी उपकरणों की विशेषताओं की जांच करें।',
    process: 'प्रक्रियाएँ',
    processT: 'आप किसी भी समय स्टेशन पर चल रही सभी प्रक्रियाओं को देख सकते हैं और आवश्यकता होने पर उन्हें मैन्युअल रूप से समाप्त कर सकते हैं।',
    programs: 'प्रोग्राम और उपकरण',
    programsT: 'सभी स्थापित ऐप्स की जांच करें और उन्हें उपकरण से हटा दें यदि आप चाहें।<br/><br/>क्या आप जानना चाहते हैं कि वास्तव में कौन से उपकरण स्थापित हैं? कोई समस्या नहीं।',
    remoteCommand: 'रिमोट आदेश निष्पादन',
    remoteCommandT: 'कुछ स्थितियाँ ऐसी होती हैं जब आपको केवल एक आदेश निष्पादित करने के लिए समस्या को हल करने या कुछ जानने की जरूरत होती है, और आपको कनेक्ट होना नहीं चाहिए। आप सिर्फ एक बटन दबाकर उसे भेज सकते हैं और निष्पादन के परिणाम को प्राप्त कर सकते हैं।<br/><br/>ऊर्जा प्रबंधन भी इसी तरह सरलता से काम करता है। रीस्टार्ट, लॉक करें या स्टेशन को सोने पर डालें, कुछ क्लिक्स में।',
    feature: 'भविष्य की सुविधाओं की सूची',
    idea: 'एक विचार सबमिट करें',
    ideaT: 'सिस्टम आपके विचारों और प्रतिक्रिया के लिए खुला है! हमारे Telegram समूह में उनके बारे में लिखें <a href="{{tg}}" target="_blank" rel="noreferrer">यहाँ</a> या मुझे सीधा संदेश भेजें।<br/>किसी भी रूप में आपकी सहभागिता परियोजना को आगे बढ़ाने में मदद करेगी। आग्रहपूर्वक धन्यवाद!',
};
