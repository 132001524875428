export default {
    metaTitle: 'Baixe o Programa de Acesso Remoto. Windows, Linux, MacOS.',
    metaDesc: 'Baixe a versão mais recente para ter controle total de PCs e servidores.',
    metaKeys: 'download cusco, cusco win, cusco linux, cusco macos',
    title: 'Escolha sua Plataforma',
    download: 'Baixar',
    version: 'Versão',
    versions: 'Versões',
    versionsT: `Existem duas versões do programa:
 <ul>
 <li>Com uma interface gráfica - maior em tamanho, mas mais conveniente de usar</li>
 <li>Com uma interface de terminal - menor em tamanho, funciona no console e usa o teclado para controle.
 Versão preferida se você tiver uma versão antiga do sistema ou se a interface gráfica não estiver funcionando.
 </li>
 </ul>`,

    install: 'Instalação',
    installTWin: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>Baixe</a> e execute o programa.<br/>
 2. Registre-se no <a href="{{panel}}" target='_blank' rel='noreferrer'>painel de controle</a>.<br/>
 3. Adicione uma estação especificando o ID e a senha fornecidos no programa no dispositivo.<br/>
 4. Comece a controlar!
 <br/><br/>`,
    installTLinux: `
 1. Baixe o programa usando o comando:<br/>
 <div class="{{sCode}}">
 cd ~<br/>
 wget {{installLink}}<br/>
 </div>
 2. Execute o programa:<br/>
 <div class="{{sCode}}">
 sudo chmod +x {{fileName}}<br/>
 sudo ./{{fileName}}<br/>
 </div>

 3. Registre-se no <a href="{{panel}}" target='_blank' rel='noreferrer'>painel de controle</a>.<br/>
 4. Adicione uma estação especificando o ID e a senha fornecidos no programa no dispositivo.<br/>
 5. Comece a controlar!

 <br/><br/>
 Nota: Durante a instalação, um serviço é criado que inicia quando o computador é ligado.<br/>
 Você pode abrir o programa no console usando o comando "cusco".<br/>
 Para obter uma lista de todos os comandos, use "cusco --help".`,
    inProgress: `Em andamento`,
    installTMac: `

 1. <a href="{{href}}" target='_blank' rel='noreferrer'>Baixe</a> e execute o programa.<br/>
 2. Registre-se no <a href="{{panel}}" target='_blank' rel='noreferrer'>painel de controle</a>.<br/>
 3. Adicione uma estação especificando o ID e a senha fornecidos no programa no dispositivo.<br/>
 4. Comece a controlar!
 <br/><br/>`,
    mainCommands: 'Comandos Principais',
    mainCommandsT: `<ul>
 <li>-i ou --install - instalação rápida</li>
 <li>-u ou --uninstall - desinstalação rápida</li> 
 <li>-h ou --help - ajuda</li> 
 <li>--update - atualizar para a versão mais recente</li> 
 </ul>
 Na versão gráfica, o comando será executado, mas não haverá saída no console (devido a peculiaridades da compilação).
`,
    feedbackTitle: `Seu feedback é muito importante!`,
    feedbackBug: `
 Se você encontrou um bug ou o programa não está funcionando para você, por favor escreva no chat
 <a href="{{bugs}}" target='_blank' rel='noreferrer'>no Telegram</a>
 na seção 'Bugs'. Tentarei resolver o problema o mais rápido possível.
 `,
    feedbackIdea: `
 Se o programa não atende a alguma de suas necessidades ou se você tiver alguma ideia interessante,
 por favor me avise no
 <a href="{{ideas}}" target='_blank'
  rel='noreferrer'>grupo</a> na seção 'Ideias'.
  `,
};
