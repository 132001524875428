export default {
    metaTitle: 'Remote computer and server management. Monitoring. Remote command execution.',
    metaDesc: 'The best program for remote access to computers. Remote PC or server control.',
    metaKeys: 'Remote access for free, Teamviewer alternative, AnyDesk alternative, remote access program',
    title: 'Connect to stations now',
    titleD: 'Take full control of your devices',
    download: 'Download',
    possibilities: 'Possibilities',
    possibilitiesF: 'Future possibilities',
    inv: 'Station inventory',
    vis: 'Visual control <br/>like Teamviewer/AnyDesk',
    manage: 'Management and viewing:',
    proc: 'Running processes',
    prog: 'Programs',
    dev: 'Devices',
    commands: 'Commands',
    power: 'Power management',
    comDo: 'Command execution',
    launch: 'Launch without installation',
    security: 'Security',
    onlyYou: 'Only you can send commands to your stations',
    itIsAKey: 'All thanks to a unique key stored with you',
    nobodyCan: 'Neither the service nor anyone else can control your stations without your knowledge',
    youTooF: 'You can influence the project\'s future',
    youIdeas: 'Suggest new ideas, vote, participate in the project\'s life',
    helpYou: 'The system implements your wishes and introduces new features',
    howToUse: 'How to use?',
    howToUseText: '1. <a href="{{lang}}/downloads">Download</a> and install the program on your devices<br/>' +
        '2. Register in the <a href="{{reg}}" target=\'_blank\' rel=\'noreferrer\'>control panel</a><br/>' +
        '3. Add a station by entering the ID and password provided in the installed program<br/>' +
        '4. Take control!',
    applyTelegram: 'Join the project on Telegram',
    newsG: 'News group',
    chat: 'General chat',
};