export default {
    metaTitle: 'Remote Access Capabilities: Control, Monitoring, Statistics, Inventory, Server and Computer Management',
    metaDesc: 'A professional service for system administrators and regular users for remote computer control. A alternative surpassing foreign remote access programs. Active development and implementation of user-requested features. Process management, program removal, remote command execution, station inventory, device viewing, power management.',
    metaKeys: 'Free program for process management, program removal, remote command execution, station inventory, device viewing, power management',
    title: 'System Capabilities',
    startUse: 'Start Using',
    remoteControl: 'Remote Control',
    remoteControlT: 'Standard remote desktop control. Similar to TeamViewer, AnyDesk, Ammyy Admin.<br/><br/>You can connect from a computer or mobile phone via a browser.',
    invent: 'Station Inventory',
    inventT: 'Accounting requires an inventory, and you don\'t feel like connecting to each machine?<br/><br/>Just check the characteristics of all devices in the control panel.',
    process: 'Processes',
    processT: 'You can view and filter all processes running on the station at any time and manually terminate them if necessary.',
    programs: 'Programs and Devices',
    programsT: 'Check all installed applications and remove them from the device if desired.<br/><br/>Want to know exactly what equipment is installed? No problem.',
    remoteCommand: 'Remote Command Execution',
    remoteCommandT: 'There are situations where you need to execute just one command to solve a problem or find out something, and you don\'t want to connect. You can send it by pressing just one button and get the execution result.<br/><br/>Power management works just as easily. Restart, lock, or put the station to sleep in a couple of clicks.',
    feature: 'List of Future Features',
    idea: 'Submit an Idea',
    ideaT: 'The system is open to your ideas and feedback! Write about them in our Telegram group <a href="{{tg}}" target="_blank" rel="noreferrer">here</a> or send me a direct message.<br/>Your involvement in any form will help the project move forward. Thank you in advance!',
};
