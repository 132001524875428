import React from "react";
import styles from "./myIcon.module.scss";
import cn from 'classnames';

interface Props extends Object {
    iconM: string;
    onClick?: (any) => void;
    className?;

    [key: string]: any;
}

function MyIcon({iconM, color, onClick, className, key, forceButton, ...props}: Props) {
    if (!key)
        key = iconM
    let style: any = {};

    if (color)
        style.color = color;

    return <span key={key} className={cn(styles.icon, 'material-icons', className, {
        'click': onClick
    })} style={style} onClick={onClick} {...props}>{iconM}</span>;
}

export default MyIcon;
