export default {
    metaTitle: 'Patrocine o Projeto',
    metaDesc: 'Oportunidade de participar do desenvolvimento do projeto de controle remoto e monitoramento de computadores',
    metaKeys: 'Patrocinar o projeto, suporte ao projeto',
    title: 'Apoio ao Projeto',
    text: `
 <p>
 Há vários meses, tenho desenvolvido, mantido e financiado este projeto sozinho. No entanto, estou gradualmente enfrentando certos problemas que exigem apoio financeiro.
 </p>
 <p>
 Se você tiver a oportunidade, por favor, apoie o serviço. Ficarei muito grato! Independentemente do valor doado, isso significará que você considera meu projeto valioso e acredita em seu desenvolvimento, assim como eu. Esse apoio fortalecerá significativamente minha motivação.
 </p>
 <p>
 <div class="{{subtitle1}}">
 Como enviar dinheiro
 </div>
 Como sigo os valores e pontos de vista do criptoanarquismo, tenho apenas carteiras de criptomoedas. Abaixo está uma instrução de como financiá-las com um cartão bancário:
 <div class="{{code}}">
USDT <span class="{{green}}">bep20 (Binance)</span>: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
BTC: bc1qyz2gken4mxgfquqfmvrevyttycdxtp349ve7kz <br/><br/>
ETH: 0x1716a9bAaE728780D98fb26faAe17D79FB62F090 <br/><br/>
DOGE: D8aQgYPjkpN92AJiRokhHaSWw9LBG9g6xT
</div>
<div class="{{subtitle2}}">Instrução</div>
<ul class="{{instruction}}">
<li> Diretamente - se você tiver uma carteira eletrônica com moedas USDT bep20.</li>
<li> Através de uma plataforma de câmbio. Escolha uma aqui, por exemplo, <a href="https://www.bestchange.com/visa-mastercard-usd-to-tether-bep20.html" target=_blank rel=noreferrer>bestchange.com</a>:
<ul>
<li> Acesse a página de monitoramento da plataforma de câmbio</li>
<li> Na coluna "Gives", selecione um método de pagamento adequado, como "Visa/Mastercard". Recomendo escolher nesta coluna o método de pagamento com a menor taxa de juros para o seu país (cerca de 3-5%), geralmente um dos bancos populares na moeda local, e não uma transferência direta em USD.
<li> Na coluna "Receives", deve constar Tether bep20</li>
<li> Escolha uma plataforma de câmbio com a melhor taxa ou condições</li>
<li> No site da plataforma de câmbio, insira o valor que você deseja financiar em dólares, copie o endereço de recarga 0x1716a9bAaE728780D98fb26faAe17D79FB62F090, preencha os campos restantes e faça a transferência de acordo com os detalhes fornecidos</li>
<li> A transação é processada pela plataforma de câmbio e pela rede em até 20 minutos</li>
</ul>
</li>
</ul>
Se encontrar algum problema, por favor, escreva para mim no <a href="{{tgMy}}" target=_blank rel=noreferrer>Telegram</a> e resolveremos juntos.
</p>
<p>
<div class="{{subtitle1}}">
 Como os fundos serão utilizados
 </div>
 Compra de equipamentos necessários, pagamento do aluguel do servidor, pagamento por serviços de assistência no desenvolvimento, testes, design, biscoitos.
 <br/>
 <br/>
 Se o valor exceder alguns meses de salário, poderei contratar um assistente e acelerar significativamente o lançamento de novas versões.
</p>
<div class="{{subtitle1}}">
 Desde já, agradeço seu apoio!
 </div>
 Mesmo que você apenas tenha tirado um tempo para ler este texto :)
`
};
