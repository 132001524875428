export default {
    metaTitle: 'サービスの費用',
    metaDesc: 'リモート制御および監視システムの費用はいくらですか？',
    metaKeys: '企業でのリモート制御、コンピュータ管理の価格、従業員の監視、無料のリモート制御',
    title: '費用',
    titleD: '1ヶ月あたりの1ステーションから始まる、最低でも20セント',
    text: `
 <p>
 サービスでは、ユーザーは自分自身の価格を設定できます。無料で5つのステーションが提供される範囲で、価格は20セントから10ドルまで設定できます。
 </p>
 <p>
 このオプションは、このプロジェクトのアイデアに対する評価ともなります。指定された金額が高いほど、このプロジェクトは良くなります。より強力なサーバー、バグの少ない、より使いやすいインターフェース、高速な操作が可能になります。最も重要なことは、ステーションの管理を容易にするために新機能が導入されることです。
 </p>
`
};
