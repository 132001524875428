import React, {useMemo} from "react";
import styles from './header.module.scss';
import cn from 'classnames';
import {observer} from "mobx-react";
import RootStore from "../../stores/rootStore";
import {NavLink} from "react-router-dom";
import NavigationStore from "../../stores/navigationStore";
import MyImg from "../myImg/myImg";
import MyButton from "../myButton/myButton";
import Variables from "../../utils/variables";
import {getLanguageURL, reachGoal} from "../../utils/helper";
import MySelect from "../mySelect/mySelect";
import LANG, {LANGUAGES} from "../../lang/language";

function Header(props) {
    let rootStore = RootStore.getInstance();
    const navigationStore = useMemo(() => new NavigationStore(), []);

    let menu: any = useMemo(() => navigationStore.getMenu(), []);

    return <div className={cn('grid anim', styles.main, {
        [styles.scrolled]: props.scrolled
    })}>
        <NavLink className={cn('col-fixed grid custom', styles.logo)} to={'/'}>
            <div className={cn('col-fixed grid f-c', styles.img)}>
                <MyImg src='logo_60.png'/>
            </div>
            <div className={cn('col-fixed grid f-c-l', styles.text)}>
                Cusco<br/>
                Remote Control
            </div>
        </NavLink>
        <div className={cn('col grid f-c')}>
            {
                menu.map(el => <div key={el.name} className={cn('col-fixed grid', styles.menuItem)}>
                    <NavLink id={el.name} to={el.link} exact={el.exact} activeClassName={styles.selected}
                             className={cn('col-fixed grid custom', styles.link)}
                             isActive={(match, location) => {
                                 return navigationStore.isActiveLink(el, location);
                             }}>
                        <div className={cn('col-fixed f-c', styles.text)}>
                            {el.name}
                        </div>

                    </NavLink>
                </div>)
            }
        </div>
        <div className={cn('col-fixed f-c')}>
            <MySelect options={LANGUAGES} selected={LANG.getInstance().lang} className={styles.lang} icon={'translate'}
                      getName={(el) => `${el.name} - ${el.nameEn}`}
                      onSelect={value => {
                          LANG.getInstance().selectLang(value)
                      }}
                      getHref={(el) => {
                          let url = new URL(window.location.href);
                          let languageURL = getLanguageURL();
                          let newLanguage  = el.value;
                          if (newLanguage === 'en') {
                              url.pathname = url.pathname.replace('/' + languageURL, '');
                          } else {
                              if(languageURL == '')
                                  newLanguage = newLanguage + '/'
                              url.pathname = url.pathname.replace('/' + languageURL, '/' + newLanguage);
                          }
                          return `${url}`
                      }}/>
        </div>
        <div className={cn('col-fixed f-c-r', styles.userWrap)}>
            <MyButton text={LANG.get(['menu', 'panel'])} hrefFull={Variables.urlPanel}
                      onClick={() => reachGoal('control_panel')}/>
        </div>
    </div>
}

export default observer(Header);
