export default {
    metaTitle: 'Funzionalità di Accesso Remoto: Controllo, Monitoraggio, Statistiche, Inventari, Gestione di Server e Computer',
    metaDesc: 'Un servizio professionale per amministratori di sistema e utenti comuni per il controllo remoto dei computer. Un\'alternativa superiore ai programmi di accesso remoto esteri. Sviluppo attivo e implementazione di funzionalità richieste dagli utenti. Gestione dei processi, rimozione dei programmi, esecuzione di comandi remoti, inventario delle stazioni, visualizzazione dei dispositivi, gestione dell\'alimentazione.',
    metaKeys: 'Programma gratuito per la gestione dei processi, rimozione dei programmi, esecuzione di comandi remoti, inventario delle stazioni, visualizzazione dei dispositivi, gestione dell\'alimentazione',
    title: 'Funzionalità di Sistema',
    startUse: 'Inizia a Usare',
    remoteControl: 'Controllo Remoto',
    remoteControlT: 'Controllo remoto standard del desktop. Simile a TeamViewer, AnyDesk, Ammyy Admin.<br/><br/>Puoi connetterti da un computer o telefono cellulare tramite un browser.',
    invent: 'Inventario delle Stazioni',
    inventT: 'È necessario un inventario e non hai voglia di connetterti a ogni macchina?<br/><br/>Basta controllare le caratteristiche di tutti i dispositivi nel pannello di controllo.',
    process: 'Processi',
    processT: 'Puoi visualizzare e filtrare in qualsiasi momento tutti i processi in esecuzione sulla stazione e terminarli manualmente se necessario.',
    programs: 'Programmi e Dispositivi',
    programsT: 'Controlla tutte le applicazioni installate e rimuovile dal dispositivo se desideri.<br/><br/>Vuoi sapere esattamente quali dispositivi sono installati? Nessun problema.',
    remoteCommand: 'Esecuzione di Comandi Remoti',
    remoteCommandT: 'Ci sono situazioni in cui è sufficiente eseguire un singolo comando per risolvere un problema o scoprire qualcosa, e non vuoi connetterti. Puoi inviarlo premendo un solo pulsante e ottenere il risultato dell\'esecuzione.<br/><br/>Anche la gestione dell\'alimentazione funziona allo stesso modo. Riavvia, blocca o metti la stazione in modalità di sospensione con un paio di clic.',
    feature: 'Elenco delle Funzionalità Future',
    idea: 'Invia un\'Idea',
    ideaT: 'Il sistema è aperto alle tue idee e ai tuoi feedback! Scrivili nel nostro gruppo Telegram <a href="{{tg}}" target="_blank" rel="noreferrer">qui</a> o inviami un messaggio diretto.<br/>Il tuo coinvolgimento in qualsiasi forma aiuterà il progetto a progredire. Grazie in anticipo!',
};
