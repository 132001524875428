export default {
    idea: 'アイデアを提出する',
    ideaT: 'システムはあなたのアイデアとフィードバックを歓迎します！それらについて私たちの<a href="{{tg}}" target="_blank" rel="noreferrer">Telegram</a>グループで書いたり、個別に私に送信したりしてください。<br/>いかなる形でもあなたの参加がプロジェクトを前進させる助けになります。お先にお礼申し上げます！',

    metaTitle: 'フィードバック',
    metaDesc: 'デスクトップリモートコントロールサービス「Cusco Remote Control」の問題を報告する',
    metaKeys: '',
    title: 'フィードバック',
    text: `
ディスカッション用Telegramグループ：<a href="{{tg}}" target='_blank' rel='noreferrer'>cusco_rc_group</a><br/>
それぞれのトピックには、専用のスレッドが作成されています：
<ul>
<li><a href="{{tgIdea}}" target='_blank' rel='noreferrer'>アイデア</a> - プロジェクトで見たい機能</li>
<br/><li><a href="{{tgBugs}}" target='_blank' rel='noreferrer'>バグ</a> - エラーが発生した場合や予想される場合。セキュリティに関連するバグの場合は、個別に送信する方が良いです。</li>
<br/><li><a href="{{tgMain}}" target='_blank' rel='noreferrer'>一般</a> - 一般的なトピックのディスカッション、アンケート</li>
</ul>
`
};
