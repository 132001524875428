export default {
    metaTitle: 'Costo de los Servicios',
    metaDesc: '¿Cuánto cuesta el sistema de control remoto y monitoreo?',
    metaKeys: 'Control remoto en la empresa, precio de gestión de computadoras, monitoreo de empleados, control remoto gratuito',
    title: 'Costo',
    titleD: 'a partir de 20 centavos por estación por mes',
    text: `
 <p>
 El servicio permite a los usuarios establecer su propio precio: desde 20 centavos hasta 10 dólares, con 5 estaciones proporcionadas de forma gratuita.
 </p>
 <p>
 Esta opción también sirve como evaluación de la idea detrás de este proyecto. Cuanto mayor sea la cantidad especificada, mejor será este proyecto. Tendrá servidores más potentes, menos errores, una interfaz más amigable para el usuario e interacciones más rápidas. Lo más importante es que se introducirán nuevas funciones para facilitar la gestión de las estaciones.
 </p>
`
};
