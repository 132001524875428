export default {
    idea: '提交一个想法',
    ideaT: '系统欢迎您的想法和反馈！请在我们的<a href="{{tg}}" target="_blank" rel="noreferrer">Telegram</a>群组中写下您的想法或私下向我发送。 <br/>您以任何形式的参与都将帮助项目前进。非常感谢！',

    metaTitle: '反馈',
    metaDesc: '报告" Cusco 远程控制"桌面远程控制服务的问题',
    metaKeys: '',
    title: '反馈',
    text: `
Telegram讨论组： <a href="{{tg}}" target='_blank' rel='noreferrer'>cusco_rc_group</a><br/>
为每个主题创建了专门的主题：
<ul>
<li><a href="{{tgIdea}}" target='_blank' rel='noreferrer'>想法</a> - 您希望在项目中看到的功能</li>
<br/><li><a href="{{tgBugs}}" target='_blank' rel='noreferrer'>错误</a> - 如果您遇到错误或预料到错误。对于与安全相关的错误，最好私下向我发送。</li>
<br/><li><a href="{{tgMain}}" target='_blank' rel='noreferrer'>常规</a> - 一般主题的讨论，调查</li>
</ul>
`
};
